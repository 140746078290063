import React from "react";
import {Affix, Button, Col, message, Row} from 'antd';


export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            album:[],
            news:[],
            category:[],//类别
            category_news:[],// 三个热门新闻
            articlesDetail:[],
            commentsList:[],
            commentsNum:0,
            id:null,
        }
    }
    componentDidMount() {
        document.title = '数聚房投-专注数据量化的地产咨询平台'
        // this.props.match.params.type
        this.setState({id:this.props.match.params.id})
        this.getArticles()
        this.getArticlesDetail()
        this.getcommentsList({id:this.props.match.params.id})
        this.getDetail({id:this.props.match.params.id})
    }
    getDetail(params){
        global.Ajax.hhtc_request('Articles/details',params).then(res => {
            this.setState({
                detail:res.data,
            })
        })
    }

    //推荐文章
    getArticlesDetail(){
        global.Ajax.hhtc_request('Articles/recommend').then(res => {
            console.log(res,'recommend')
            this.setState({
                articlesDetail:res.data,
            })
        })
    }
    //文章评论
    getcommentsList(params){
        global.Ajax.hhtc_request('Articles/comments',params).then(res => {
            this.setState({
                commentsList:res.data.data,
                commentsNum:res.data.total
            })
        })
    }
    getArticles(){
        global.Ajax.hhtc_request('Articles/index').then(res => {
            console.log(res,'res')
            this.setState({
                album:res.data.album,
                news: res.data.new,
                category:res.data.category,
                category_news:res.data.category_news,
            })
        })

    }

    //评论作品
    WorkContent() {
        var req = {
            id: this.state.id,
            content: this.state.content,
        }
        global.Ajax.hhtc_request('Articlescomment/comment', req).then(res => {
            if (res.code == 1) {
                message.success(res.msg, 2, () => {
                    window.location.reload();
                })
            } else {
                message.error(res.msg, 2)
            }
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='padd_top66 w1400'>
                    {/*导航*/}
                    <div className='menubox'>
                        <div className='w1400'>
                            <p>首页</p>
                            <p>项目</p>
                            <p>{this.state.detail && this.state.detail.title}</p>
                        </div>
                    </div>
                    <div className='articleBox'>
                        <div offsetTop={100} className='rightBox'>
                            <div className='articleTitle'>专辑推荐</div>
                            <div className='albumBox'>
                                {this.state.album.map(item=>{
                                    return <div className='albumItem' onClick={() => {
                                        //type 是搜索分类  1 是关键字， 2 是类别  3 是专辑ID
                                        this.props.history.push(`/rout/newsSearch/3/${item.album_id}` )
                                    }}>
                                        <img className='img' src={item.album_icon} alt=""/>
                                        <p className='name'>{item.name}</p>
                                    </div>
                                })}
                            </div>
                            <div className='albumBox'>
                                <div className='articleTitle'>文章推荐</div>
                                {this.state.articlesDetail.map((item, index) => (
                                   <div key={'l' + index} className='albumItem' style={{height:230}}  onClick={()=>{
                                        window.open('#/rout/newsDetail/'+ item.id)
                                    }}>
                                       <div style={{overflow:'hidden'}}>
                                           <img className='img' style={{height:230}} src={item.main_image} alt=""/>
                                       </div>
                                        <p className='name'>{item.title}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='leftContentBox'>
                            <h3 className='titleBox'>{ this.state.detail && this.state.detail.title}</h3>
                            <div className='titleBoxh4'>
                                <span>{ this.state.detail && this.state.detail.author}</span>
                                <span>浏览量：{ this.state.detail && this.state.detail.pv}</span>
                                <span>{ this.state.detail && this.state.detail.atime}</span>
                            </div>
                            {this.state.detail && <div className='leftContentBoxContent' dangerouslySetInnerHTML={{ __html: this.state.detail.content }}></div>}
                            <div className='detailOtherInfo'>转载： {this.state.detail && this.state.detail.change_url}</div>
                            <div className='liuyBox'>
                                <h1>我要留言</h1>
                                <textarea placeholder='请输入留言内容' className='texta' onChange={(e) => {
                                    this.setState({
                                        content: e.target.value
                                    })
                                }}></textarea>
                                <p className='fbtn' onClick={this.WorkContent.bind(this)}>发表</p>
                                <h1 style={{ marginBottom: 24 }}>评论({this.state.commentsNum})</h1>
                                { this.state.commentsList &&  this.state.commentsList.map((item, index) => (
                                    <div key={'l' + index} className='plitem'>
                                        <img className='avatar' alt='' src={item.headImg} />
                                        <div className='prbox'>
                                            <div className='flex'>
                                                <h2>{item.nickName}</h2>
                                                <p>{item.atime}</p>
                                            </div>
                                            <p className='desc' dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
