import React from 'react';
import { Carousel, Progress, message } from 'antd';



import Xqshow from './Xqshow';  //详情展示
import Parames from './Parames';  //参数
import Score from './Score';  //评分
import Down from './Down';  //下载

import banner from '../../imgs/banner.png'
import time1 from '../../imgs/time1.png'
import bh from '../../imgs/bh.png'
import wd from '../../imgs/wd.png'
import time from '../../imgs/time.png'
import num from '../../imgs/num.png'
import xz from '../../imgs/xz.png'
import dy from '../../imgs/dy.png'
import dys from '../../imgs/dys.png'
import search from '../../imgs/search1.png'
import add from '../../imgs/add.png'
import { Fragment } from 'react';



export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.match.params.id,
            width: '10000px',
            transition: '500ms ease-out',
            transform: 'translatex(0px)',
            countnum: 8,     //图片数量
            curPageIndex: 0, //点击右箭头+1，左箭头减1
            viewcount: 5,    //初始展示图片数量（和div宽度相关） 
            data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            menuIndex: 0,
            page: 1,
            total: 0,
            work: [],//作品信息
            is_subscribe: 0,//是否订阅
            commentsNum: 0,
            comments: [],//留言
            label: [],//标签
            subscribe: [],//订阅热度
            recommend: [],//推荐作品
            labels: [],//推荐作品标签
            content: "",//评论内容
            is_award: null,  //0--评奖中  1--已评奖
            otime: 0,
            day: 0,
            hour: 0,
            minute: 0,
            is_contrast: null,  //0--未加入  1--已加入
            display: 0,  //0--评奖完  1--评奖中
            collectionNum: "",
            parameter1: "",
            parameter2: "",
            parameter3: "",
            parameter4: "",
            parameter5: "",
            parameter6: "",
            parameter7: "",
            parameter8: "",
            parameter9: "",
            parameter10: "",
            parameter11: "",
            parameter12: "",
            hxs: [],
            awards: [],
        }
        this.timer = null
    }
    componentDidMount() {
		document.title =  '户型展示'
		this.getData();
        // document.title = this.state.work.title + '-作品详情'
        
    }
    //获取作品信息
    getData() {
        var req = {
            id: this.state.id,
            page: this.state.page,
            size: 10,
        }
        global.Ajax.hhtc_request('home/details', req).then(res => {
            if (res.code == 1) {
                if (res.data.is_award == 0) {
                    this.djs(res.data.otime)
                }
                this.setState({
                    work: res.data,
                    comments: res.data.comments.data,
                    label: res.data.label,
                    subscribe: res.data.subscribe,
                    recommend: res.data.recommend,
                    labels: res.data.recommend.label,
                    is_subscribe: res.data.is_subscribe,
                    commentsNum: res.data.comments.total,
                    is_award: res.data.is_award,
                    otime: res.data.otime,
                    is_contrast: res.data.is_contrast,
                    display: res.data.display * 1,
                    collectionNum: res.data.collectionNum,
                    parameter1: res.data.parameter1,
                    parameter2: res.data.parameter2,
                    parameter3: res.data.parameter3,
                    parameter4: res.data.parameter4,
                    parameter5: res.data.parameter5,
                    parameter6: res.data.parameter6,
                    parameter7: res.data.parameter7,
                    parameter8: res.data.parameter8,
                    parameter9: res.data.parameter9,
                    parameter10: res.data.parameter10,
                    parameter11: res.data.parameter11,
                    parameter12: res.data.parameter12,
                    awards: res.data.awards,
                    hxs: res.data.hxs,
                })
                document.title = this.state.work.title + '-户型展示'
                window.scrollTo(0,0)
                
            } else {
                message.error(res.msg, 2)
            }
        })
    }
    djs(sys_second) {
        this.timer = setInterval(() => {
            //防止倒计时出现负数
            if (sys_second > 0) {
                sys_second--;
                let day = Math.floor((sys_second / 3600) / 24);
                let hour = Math.floor((sys_second / 3600) % 24);
                let minute = Math.floor((sys_second / 60) % 60);
                this.setState({
                    day,
                    hour,
                    minute
                })
                // return <h1>剩余评奖时间：<p>{day}<span>DAYS</span> {hour}<span>HOURS</span> {minute}<span>MIN</span></p></h1>
            } else {
                console.log('清除')
                clearInterval(this.timer);
            }
        }, 1000);
    }
    //加入对比
    contrast(id) {
        var req = {
            id: id
        }
        global.Ajax.hhtc_request('home/contrast', req).then(res => {
            if (res.code == 1) {
                message.success(res.msg, 2)
                this.props.refresh()
                this.getData()
            } else {
                message.error(res.msg, 2)
            }
        })
    }
    //订阅/取消订阅
    subscribe(id) {
        var req = {
            id: id
        }
        global.Ajax.hhtc_request('home/subscribe', req).then(res => {
            if (res.code == 1) {
                this.setState({
                    is_subscribe: res.data,
                })
                message.success(res.msg, 2, () => {
                    // window.location.reload();
                    this.getData()
                })
            } else {
                message.error(res.msg, 2)
            }
        })
    }
    //评论作品
    WorkContent() {
        var req = {
            id: this.state.id,
            content: this.state.content,
        }
        global.Ajax.hhtc_request('home/comment', req).then(res => {
            if (res.code == 1) {
                message.success(res.msg, 2, () => {
                    window.location.reload();
                })
            } else {
                message.error(res.msg, 2)
            }
        })
    }
    render() {
        return (
            <div className='padd_top66' ref={dom=>this.page=dom}>
                {/* menu */}
                <div className='menubox'>
                    <div className='w1400'>
                        <p>首页</p>
                        <p>产品</p>
                        <p>{this.state.work.title}</p>
                    </div>
                </div>
                {this.state.display == 1 && <div className='sypjtimebox' >
                    <img alt='' src={time1} className='ticon' />
                    <h1>剩余评奖时间：<p>{this.state.day}<span>DAYS</span> {this.state.hour}<span>HOURS</span> {this.state.minute}<span>MIN</span></p></h1>
                    <p className='qpfbtn' onClick={() => {
                        this.setState({
                            menuIndex: 2
                        })
                    }}>前往评分</p>
                </div>}
                <div className='w1400 detailbox'>
                    <div className='lbox'>
                        <div className='zpinfo'>
                            <div className='left'>
                                <h1 className='title'>{this.state.work.title}</h1>
                                <div className='flex'>
                                    <p className='ze'>由<span>{this.state.work.nickname}</span>编辑</p>
                                    <p className='time'>{this.state.work.atime}</p>
                                </div>
                            </div>
                            <img alt='' src={this.state.work.headImg} className='avatar' />
                        </div>
                        <div className='wdinfo csjhjh'>
                            <p className='tit'>户型摘要</p>
                            <div className='flex '>
                                <p>产品业态：{this.state.work.parameter1}</p>
                                <p>容积率值：{this.state.work.parameter2}</p>

                                <p>楼型户数：{this.state.work.parameter7} 户</p>
                                <p>楼型面积：{this.state.work.parameter4}㎡</p>
                                <p>楼型面宽：{this.state.work.parameter5}m</p>

                                <p>楼型进深：{this.state.work.parameter6}m</p>
                                <p>楼梯数量：{this.state.work.parameter9} 个</p>

                                <p>电梯数量：{this.state.work.parameter8} 个</p>


                                <p>拼接支持：{this.state.work.parameter3}</p>
                                <p>楼型形状：{this.state.work.parameter10}</p>

                                {
                                    this.state.hxs.map((item77, index77) => {
                                        return (
                                            <React.Fragment>

                                                <p style={{ color: '#66B9BF' }}>
                                                    <span style={{ color: '#66B9BF' }}>户型{index77 + 1}：</span>
                                                    {item77.jbtz_hxjps[1]}客{item77.jbtz_hxjps[3]}餐{item77.jbtz_hxjps[5]}卧{item77.jbtz_hxjps[7]}卫{item77.jbtz_hxjps[9]}厨
                                                </p>

                                                <p style={{ color: '#66B9BF' }}>
                                                    <span style={{ color: '#66B9BF' }}>户型面积段：</span>
                                                    {item77.jbtz_hxmj}㎡
                                                </p>

                                            </React.Fragment>

                                        )
                                    })

                                }


                            </div>
                        </div>
                        <div className='wdinfo'>
                            <p className='tit' style={{ marginBottom: 36 }}>关键词</p>
                            <div className='flwabox'>
                                {/* flwabox */}
                                {
                                    this.state.label.map((item, index) => {
                                        return (
                                            <p key={'l' + index}>{item}</p>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        <div className='wdinfo'>
                            <p className='tit'>户型概述</p>
                            <div className='flex'>
                                {/* <p>{this.state.work.content}</p> */}
                                <p dangerouslySetInnerHTML={{ __html: this.state.work.documentOverview }}></p>
                            </div>
                        </div>
                        <div className='wdinfo'>
                            <p className='tit'>文档信息</p>
                            <div className='flex'>
                                <img alt='' src={bh} />
                                <p>文档编号：{this.state.work.docNo}</p>
                            </div>
                            <div className='flex'>
                                <img alt='' src={wd} />
                                <p>文档大小：{this.state.work.size}MB</p>
                            </div>
                            <div className='flex'>
                                <img alt='' src={time} />
                                <p>上传时间：{this.state.work.atime}</p>
                            </div>
                            <div className='flex'>
                                <img alt='' src={num} />
                                <p>浏览次数：{this.state.work.pvNum}次</p>
                            </div>
                            <div className='flex'>
                                <img alt='' src={xz} />
                                <p>下载次数：{this.state.work.downNum}次</p>
                            </div>
                            <div className='flex'>
                                <img alt='' src={xz} />
                                <p>收藏次数：{this.state.work.collectionNum}次</p>
                            </div>
                        </div>
                        <div className='wdinfo'>
                            <p className='tit'>奖项荣誉</p>
                            {
                                this.state.awards.map((item11, index11) => (
                                    <div className='flex'>
										<img alt='' src={item11.icon} />
                                        <p key={'index11' + index11}>《{item11.name}》{item11.number}—第{item11.ranking}名</p>
                                    </div>
                                ))
                            }

                        </div>

                        <div className='wdinfo'>
                            <p className='tit'>订阅热度</p>
                            <div className='imgbox'>
                                {this.state.subscribe.map((item, index) => (
                                    <img key={'sub' + index} alt='' src={item.headImg} className='avatar' />
                                ))}
                                {/* <p style={{width: 36,height: 36,borderRadius: 18,background: '#ECEFEE'}}></p> */}
                                <img alt='' src={add} className='avatar' />
                            </div>
                        </div>

                        <div className='wdinfo' style={{ paddingBottom: 0 }}>
                            <p className='tit'>推荐作品</p>
                            {this.state.recommend.map((item, index) => (
                                <div className='tjitem' key={'rec' + index} onClick={() => {
                                    this.setState({
                                        id: item.id,
                                    }, () => {
                                        // this.props.history.push('/rout/ProductDetail/' + item.id)
                                        window.open('#/rout/ProductDetail/'+ item.id);
                                        this.getData();
                                        window.location.reload()
                                    })
                                }} >
                                    <div className='picbox'>
                                        <img alt='' src={item.thumb} className='pic'/>
                                        <div className='massk'>
                                            <img alt='' src={search} />
                                        </div>
                                    </div>

                                    <h1>{item.title}</h1>
                                    <div className='bq'>
                                        {item.label.map((row, i) => {
                                            return (
                                                <p key={'' + i}>{row}</p>
                                            )
                                        })}
                                        {/* <p className='slh'>
                                            <span></span>
                                        </p> */}
                                    </div>
                                    <div className='zzbox'>
                                        <img alt='' src={item.headImg} className='avatar' />
                                        <div className='flex'>
                                            <p className='ze'>由<span>{item.nickname}</span>编辑</p>
                                            <p className='time'>{item.atime}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
					<div className='rbox'>
                        <div className='xqbox'>
                            <div className='menuBar'>
                                <div className='lmenu'>
                                    {['户型展示', '户型参数', '户型评分', '户型下载'].map((item, index) => (
                                        <p key={'' + index} className={this.state.menuIndex == index ? 'active' : ''} onClick={() => {
                                            this.setState({
                                                menuIndex: index
                                            })
                                        }}>{item}</p>
                                    ))}
                                </div>
                                <div className='btnbox'>
                                    <p onClick={
                                        this.contrast.bind(this, this.state.id)
                                    }>{this.state.is_contrast == 1 && '已'}加入{this.state.is_contrast == 0 && '对比'}</p>

                                    {
                                        this.state.is_subscribe == 0 ? <p className='dybtn' onClick={
                                            this.subscribe.bind(this, this.state.id)
                                        }><img alt='' src={dy} />订阅</p> : <p className='dybtn' onClick={
                                            this.subscribe.bind(this, this.state.id)
                                        }><img alt='' src={dys} />已订阅</p>
                                    }
                                </div>
                            </div>
                            {/* 详情展示 */}
                            {this.state.menuIndex == 0 && <Xqshow id={this.state.id} title={this.state.work.title}/>}
                            {/* 参数 */}
                            {this.state.menuIndex == 1 && <Parames id={this.state.id} title={this.state.work.title}/>}
                            {/* 评分 */}
                            {this.state.menuIndex == 2 && <Score id={this.state.id} title={this.state.work.title}/>}
                            {/* 下载 */}
                            {this.state.menuIndex == 3 && <Down id={this.state.id} title={this.state.work.title}/>}
                        </div>
                        
						{/* 留言 */}
                        <div className='liuyBox'>
                            <h1>我要留言</h1>
                            <textarea placeholder='请输入留言内容' className='texta' onChange={(e) => {
                                this.setState({
                                    content: e.target.value
                                })
                            }}></textarea>
                            <p className='fbtn' onClick={this.WorkContent.bind(this)}>发表</p>
                            <h1 style={{ marginBottom: 24 }}>评论({this.state.commentsNum})</h1>
                            {this.state.comments.map((item, index) => (
                                <div key={'l' + index} className='plitem'>
                                    <img className='avatar' alt='' src={item.headImg} />
                                    <div className='prbox'>
                                        <div className='flex'>
                                            <h2>{item.nickname}</h2>
                                            <p>{item.atime}</p>
                                        </div>
                                        <p className='desc' dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                    </div>
                                </div>
                            ))}
                        </div>


                    </div>
                </div>


            </div>
        )
    }
}