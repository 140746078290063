import React from 'react';
import { Table,Modal,message,Pagination, InputNumber } from 'antd';

import tuoy from '../../imgs/tuoy.png'
import bi from '../../imgs/bi1.png'
import close from '../../imgs/close.png'
import icon1 from '../../imgs/icon1.png'
import alipay from '../../imgs/alipay.png'
import wechat from '../../imgs/wechat.png'
import gou from '../../imgs/gou.png'

const QRCode = require('qrcode.react');
export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            data: [],
            type: 0,  //0--消耗明细  1--获取明细
            showIndex: 0,  //0--积分明细  1--积分充值
            cztype: 0,  //0--支付宝 1--微信
            money:0,//充值金额
            orderSn:"00000",//订单号
            cznum: [{
                id: 1,
                num: 55,
                price: 50
            },{
                id: 2,
                num: 120,
                price: 100
            },{
                id: 3,
                num: 250,
                price: 200
            }],
            czid: 0,
            visible: false,
            user:[],
            type:0,
            list:[],
            url:"",//支付url
            showMoney:0,//返回金额
            page:1,
            size:10,
            total:0,
        }
        this.timer=null;
        // 消耗明细
        this.columns=[{
            title: '作品名',
            dataIndex: 'title',
            key: 'name',
            align: 'center'
        },{
            title: '作者昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            align: 'center'
        },{
            title: '积分消耗',
            dataIndex: 'num',
            key: 'cost',
            align: 'center'
        },{
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: '250px',
            align: 'center',
            render:(row,item)=>{
                return(
                    <div className='btnbox'>
                        <p onClick={
                            this.work.bind(this,item.workId)
                        }>作品详情</p>
                        <p className='b2' onClick={
                            this.down.bind(this,item.downId)
                        }>重新下载</p>
                    </div>
                )
            }
        }]
        // 获取明细
        this.columns2=[{
            title: '获取途径',
            dataIndex: 'info',
            key: 'name',
            width: '220px',
            align: 'center'
        },{
            title: '获取时间',
            dataIndex: 'atime',
            key: 'nickname',
            align: 'center'
        },{
            title: '获取数量',
            dataIndex: 'num',
            key: 'nickname',
            align: 'center'
        },{
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '240px',
            render: (row)=>{
                return '-'
            }
        }]
    }
	componentDidMount(){
        document.title='我的积分'
        this.getData()
        //获取用户信息
        global.Ajax.hhtc_request('user/home').then(res=>{
			if(res.code==1){
                this.setState({
					user:res.data
				})
			}else{
				message.error(res.msg)
			}
		})
        
    }
    componentWillUnmount(){
        if(this.timer){
            clearInterval(this.timer)
        }
    }
    // 充值
    sub(){
        if(this.state.money<=0){
            message.error("充值金额必须大于0");
            return false;
        }
        //订单查询接口
        clearInterval(this.timer)
        this.timer=setInterval(()=>{
            this.func()
        },2000)
        this.setState({
            visible: true
        })
        var type = this.state.cztype;
        var czid = this.state.czid;
        if(this.state.money==50){
            czid = 1;
        }else if(this.state.money==100){
            czid = 2;
        }else if(this.state.money==200){
            czid = 3;
        }else{
            czid = 0;
        }
        if(type==0){
            //支付宝充值
            var req = {
                type:1,
                money:this.state.money,
                czid:czid,
            }
            global.Ajax.hhtc_request('user/order',req).then(res=>{
                if(res.code==1){
                    this.setState({
                        url:res.data.qrCode,
                        showMoney:res.data.moeny,
                        orderSn:res.data.orderSn,
                    });
					message.success(res.msg,2)
                }else{
                    message.error(res.msg,3)
                }
            })
        }else{
            //微信充值
            var req = {
                type:2,
                money:this.state.money,
                czid:czid,
            }
            global.Ajax.hhtc_request('user/order',req).then(res=>{
                if(res.code==1){
                    this.setState({
                        url:res.data.qrCode,
                        showMoney:res.data.moeny,
                        orderSn:res.data.orderSn,
                    })
                    message.success(res.msg,2)
                }else{
                    message.error(res.msg,3)
                }
            })
        }

    }

    func(){
        var req = {
            orderSn:this.state.orderSn
        }
        global.Ajax.hhtc_request('user/queryOrder',req).then(res=>{
            if(res.code==1){
                clearInterval(this.timer)
                message.success(res.msg,2,()=>{
                    window.location.reload();
                })
            }
        })
    }
    
    //积分消耗明细/获取明细
    getData(){
        var req={
            page: this.state.page,
            size:10,
            type: this.state.type+1
        }
        //消耗明细
        global.Ajax.hhtc_request('user/consume',req).then(res=>{
			if(res.code==1){
                this.setState({
					data:res.data.data,
                    total:res.data.total
				})
			}else{
				message.error(res.msg)
			}
		})
    }
    //作品详情
    work(id){
        this.props.history.push('/rout/ProductDetail/'+ id)
    }
    //重新下载
    down(downId){
        var req = {
            id:downId
        }
        global.Ajax.hhtc_request('download/downAgain',req).then(res=>{
            if(res.code==1){
                message.success(res.msg,2)
                window.location.href=res.data
            }else{
                message.error(res.msg,2)
            }
        })
    }

    render(){
        return(
            <div className='padd_top66'>
                {/* menu */}
                <div className='menubox' style={{marginBottom: 36}}>
                    <div className='w1400'>
                        <p onClick={()=>{
                            this.props.history.push("/rout/home")
                        }}>首页</p>
						<p onClick={()=>{
						    this.props.history.push("/rout/userinfo")
						}}>我的主页</p>
                        <p>我的积分</p>
                    </div>
                </div>
                <div className='integflexbox w1400'>
                    <div className='lbox'>
                        <div className='flex'>
                            <img alt='' src={tuoy} className='bg' />
                            <img alt='' src={bi} className='bi' />
                            <div className='nbox'>
                                <h1>{this.state.user.integral}</h1>
                                <h2>我的积分</h2>
                            </div>
                        </div>
                        {['积分明细','积分充值'].map((item,index)=>(
                            <p key={''+index} className={`m1 ${this.state.showIndex==index?'active':''}`} onClick={()=>{
                                this.setState({
                                    showIndex: index
                                })
                            }}>{item}</p>
                        ))}
                    </div>
                    <div className='rbox'>
                        {/* 积分明细 */}
                        {this.state.showIndex==0&&<React.Fragment>
                            <p className='h1'>积分明细</p>
                            <div className='flex'>
                                {['消耗明细','获取明细'].map((item,index)=>(
                                    <p key={''+index} className={this.state.type==index?'active':''} onClick={()=>{
                                        this.setState({
                                            type: index
                                        },()=>{
                                            this.getData()
                                        })
                                    }}>{item}</p>
                                ))}
                                
                            </div>
                            
                            <Table dataSource={this.state.data} columns={this.state.type==0?this.columns:this.columns2} pagination={false} />

                            {this.state.data.length>0&&<Pagination current={this.state.page} pageSize={this.state.size} total={this.state.total} onChange={(index,i)=>{
                                    console.log(index,i)
                                    this.setState({
                                        page: index
                                    },()=>{
                                        this.getData()
                                    })
                            }} />}

                        </React.Fragment>}
                        {/* 积分充值 */}
                        {this.state.showIndex==1&&<React.Fragment>
                            <p className='h1'>积分充值</p>
                            <p className='cz1'>充值账号：{this.state.user.mobile}</p>
                            <p>充值数量</p>
                            <div className='czlist'>
                                {this.state.cznum.map((item,index)=>(
                                    <div key={''+index} className={`czitem ${this.state.czid==item.id?'active':''}`} onClick={()=>{
                                        this.setState({
                                            czid: item.id,
                                            money:item.price,
                                        })
                                    }}>
                                        <h2>{item.num}积分</h2>
                                        <p>{item.price}元</p>
                                    </div>
                                ))}
                            </div>
                            <p className='cz2'>其他充值数量</p>
                            <InputNumber
                            formatter={value => `${value}`.replace(/[^1-9]/g,'')}
                            className='inptt'  value={this.state.money}
                            onChange={(value)=>{
                                this.setState({
                                    money:value,
									showMoney:value,
                                })            
                             }}
                            ></InputNumber>
                            <p className='cz3'>充值说明：1元=1积分</p>
                            <p className='cz2'>充值方式</p>
                            <div className='typebox'>
                                {['支付宝','微信'].map((item,index)=>(
                                    <div key={''+index} onClick={()=>{
                                        this.setState({
                                            cztype: index
                                        })
                                    }} className={this.state.cztype==index?'active':''}>
                                        {index==0&&<img alt='' src={alipay} />}
                                        {index==1&&<img alt='' src={wechat} />}
                                        <p>{item}</p>
                                    </div>
                                ))}
                            </div>
                            <p className='czbtn' onClick={this.sub.bind(this)}>确认充值</p>
                        </React.Fragment>}
                    </div>
                </div>
                {/* 付款弹窗 */}
                <Modal 
                    title=''
                    visible={this.state.visible}
                    width={600}
                    footer={false}
                    closable={false}
                    centered={true}
                    className='paymask'
                >
                    <div className='modal-title'>
                        <p>等待支付</p>
                        <img alt='' src={close} onClick={()=>{
                            this.setState({
                                visible: false
                            })
                            clearInterval(this.timer)
                        }} />
                    </div>
                    <React.Fragment>
                        <p className='pri'>应付金额：<span>{this.state.showMoney}元</span></p>
                        <p className='ewm'>
                            <QRCode value={this.state.url} size={120} />
                        </p>
                        <div className='flex1'>
                            {this.state.cztype==0&&<React.Fragment>
                                <img alt='' src={alipay} />
                                <p>支付宝扫码支付</p>
                            </React.Fragment>}
                            {this.state.cztype==1&&<React.Fragment>
                                <img alt='' src={wechat} />
                                <p>微信扫码支付</p>
                            </React.Fragment>}
                        </div>
                        <p className='btn'  onClick={()=>{
                            this.setState({
                                visible: false
                            })
                            clearInterval(this.timer)
                        }}>取消</p>
                    </React.Fragment>
                    <React.Fragment>
                        {/* <img alt='' src={gou} className='gou' />
                        <p className='btn' onClick={()=>{
                            this.setState({
                                visible: false
                            })
                        }}>关闭</p> */}
                    </React.Fragment>
                </Modal>
            </div>
        )
    }
}