import React from 'react';
import { Modal,message,Upload,Select } from 'antd';

import banner from '../../imgs/banner.png'
import close from '../../imgs/close2.png'
import gou from '../../imgs/gou.png'
import add from '../../imgs/add1.png'
import logo from '../../imgs/logo.png'

export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            img: add,
            company:"",
            companyImg:"",
            show:0,
            // 七牛配置
            upload_token: '',
            domain: '',
			font_img:"",
        }
    }
	componentDidMount(){
        document.title='公司认证'
        //获取七牛云配置
        global.Ajax.hhtc_request("agreement/get_config",{}).then(data=>{
			if(data.code===1){
				this.setState({
					upload_token: data.data.token,
					domain: data.data.domain,
				})
			}
        })
	}
    companyAuth(){
        var req={
            company:this.state.company,
            companyImg:this.state.companyImg
        }
        global.Ajax.hhtc_request('user/companyAuth',req).then(res=>{
			if(res.code==1){
                message.success(res.msg,2)
                this.setState({
                    show:1,
                })
                this.props.history.goBack();
			}else{
				message.error(res.msg,2)
			}
		})
    }
    render(){
        var that = this;
        const props = {
            name: 'file',//https://up-z0.qiniup.com
            action: 'https://upload-z2.qiniup.com',//https://upload-z2.qiniup.com/
            showUploadList:false,
            headers: {
                authorization: 'authorization-text',
            },
            data:(file)=>{
                console.log(file)
                var houzui = '.'+file.name.split('.')[1];
                return {
                    token:that.state.upload_token,
                    key:Date.now()+Math.floor(Math.random()*(999999-100000)+100000)+'1'+houzui,
                }
            },
        };
        return(
            <div style={{overflow: 'hidden'}}>
                <div className='telbox'>
                    <div className='flex'>
                        {/* <p style={{width: 185,lineHeight: '66px',backgroundColor: '#000',fontSize: 28,color: '#fff',textAlign: 'center',fontWeight: 'bold'}}>LOGO</p> */}
                        <img alt='' src={logo} className='icon' />
                        <p className='p1' onClick={()=>{
                            this.props.history.goBack()
                        }}>返回上一页</p>
                    </div>
                    <h1 className='tit'>公司认证</h1>
                    <div className='bgbai'>

                        {

                            this.state.show==0?<React.Fragment>
                            <input placeholder='请输入公司名称' className='inpt' onChange={(e)=>{
                                            this.setState({
                                                company:e.target.value
                                            })            
                                    }}/>
                            <div className='imgbox'>
                                <p>请上传员工名片/工牌(选填）</p>
                                <div>
                                <Upload {...props} onChange={(info)=>{
                                        if (info.file.status === 'done') {
                                            var src=this.state.domain+'/'+info.file.response.key
                                            this.setState({
                                                companyImg: src
                                            })
											console.log(src)
                                        }else if(info.file.status === 'error') {
                                            message.error(info.file.response.error)
                                        }
                                    }}>
										{
											this.state.companyImg!=""
											?<img className='pic' alt='' src={this.state.companyImg} />:<img alt='' src={add} className='pic' /> 
											
										}
                                </Upload>
                                    <img alt='' src={close} className='close' onClick={()=>{
										this.setState({
											companyImg:""
										})
									}}/>
                                </div>
                            </div>
                            <p className='qbtn' onClick={this.companyAuth.bind(this)}>确认</p>
                        </React.Fragment>:<p></p>


                        }
                        

                        <React.Fragment>
                            {
                                this.state.show==1
                                ?
                                <React.Fragment>
                                    <img alt='' src={gou} className='gou' />
                                    <p className='p11'>提交成功，等待审核~</p>
                                </React.Fragment>
                                :<p></p>
                            }
                            
                        </React.Fragment>
                    </div>
                </div>
            </div>
        )
    }
}