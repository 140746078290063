import React from 'react';
import { Carousel, message, Progress, Pagination } from 'antd';

import banner from '../../imgs/banner.png'
import prev from '../../imgs/prev.png'
import next from '../../imgs/next.png'
import add from '../../imgs/add.png'
import pic from '../../imgs/pic.png'
import top1 from '../../imgs/top1.png'
import search from '../../imgs/search1.png'

var timeArr = []
export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            size: 12,
            total: 0,
            prizes: [],//获奖作品
            isWorks: [],//正在评奖作品
            banner: [],//页面轮播
            data: [],//作品展示
            day: 0,//天
            hour: 0,//时
            minute: 0,//分
            timeArr: [],
            awardBanner: "",
            awardId: "",
        }
        this.timer = null
    }
    componentDidMount() {
        document.title = '数聚房投-专注数据量化的地产咨询平台'
        this.home()
    }
    //组件卸载取消倒计时
    componentWillUnmount() {
        clearInterval(this.timer);
    }
    changeSlide(type) {
        if (type === 'prev') {
            this.slide.prev()
        } else {
            this.slide.next()
        }
    }
    home() {
        var req = {
            page: this.state.page,
            size: this.state.size,
        }
        global.Ajax.hhtc_request('home/home', req).then(res => {
            if (res.code == 1) {
                timeArr = []
                for (var i = 0; i < res.data.isWorks.length; i++) {
                    timeArr.push(res.data.isWorks[i].otime)
                }
                clearInterval(this.timer);
                this.countFun()
                this.setState({
                    prizes: res.data.prizes,
                    isWorks: res.data.isWorks,
                    banner: res.data.banners,
                    data: res.data.works.data,
                    total: res.data.works.total,
                    awardBanner: res.data.awardBanner,
                    awardId: res.data.awardId,
                }, () => {
                    // this.timer=setInterval(()=>{
                    // this.countFun(this.state.timeArr[0])
                    // for(var i=0;i<this.state.timeArr.length;i++){
                    //     this.countFun(this.state.timeArr[i])
                    // }
                    // },1000)
                })
            } else {
                message.error(res.msg, 2)
            }
        })
    }
    countFun() {
        this.timer = setInterval(() => {
            for (var i = 0; i < timeArr.length; i++) {
                var sys_second = timeArr[i]
                // let end_time = new Date(time).getTime()
                // var sys_second = end_time - (new Date().getTime())
                //防止倒计时出现负数
                if (sys_second > 0) {
                    sys_second--;
                    timeArr[i] = sys_second
                    let day = Math.floor((sys_second / 3600) / 24);
                    let hour = Math.floor((sys_second / 3600) % 24);
                    let minute = Math.floor((sys_second / 60) % 60);
                    let sec = Math.floor((sys_second) % 60);
                    // if(hour < 10) hour="0" + hour 
                    // else hour=hour
                    // if(minute < 10) minute="0" + minute 
                    // else minute=minute
                    this.state.timeArr[i] = <h1> {day}<span>DAYS</span>{hour}<span>HOURS</span>{minute}<span>MIN</span></h1>
                    this.setState({
                        timeArr: this.state.timeArr
                    })
                } else {
                    for (var j = 0; j < timeArr.length; j++) {
                        if (timeArr[j] > 0) {
                            return
                        }
                        clearInterval(this.timer);
                    }
                }
            }
        }, 1000);
    };

    render() {
        return (
            <div className='padd_top66'>
                <div className='indexBanner'>
                    {/* 上一张 */}
                    <img alt='' src={prev} className='slideIcon prevIcon' onClick={this.changeSlide.bind(this, 'prev')} />
                    <Carousel ref={dom => { this.slide = dom; }}>
                        {
                            this.state.prizes.map((item, index) => {
                                return (
                                    <div className='item' key={'index' + index}>
                                        <img alt='' src={item.thumb} className='bg' />
                                        <div className='maskk'>
                                            <div className='w1400'>
                                                <p className='ckbtn' onClick={() => {
													window.open('#/rout/award')
                                                }}>往期获奖作品</p>
                                                <h1 onClick={() => {
													window.open('#/rout/ProductDetail/'+ item.id)
                                                }} style={{ cursor: 'pointer' }}>{item.title}</h1>
                                                <p className='p1'>由<span>{item.nickname}</span>编辑</p>
                                                <div className='flex'>
                                                    <div className='row'>
                                                        <Progress width='84px' type="circle" percent={item.designConcept * 1 / 10 * 100} trailColor='rgba(255,100,64,.2)' strokeColor='#ff6440' format={() => {
                                                            return (
                                                                <React.Fragment>
                                                                    <span style={{ fontSize: 24, fontWeight: 'bold' }}>{item.designConcept.split('.')[0]}</span>
                                                                    <span style={{ fontSize: 16 }}>.{item.designConcept.split('.')[1]}</span>
                                                                </React.Fragment>
                                                            )
                                                        }} />
                                                        <p>功能级配</p>
                                                    </div>
                                                    <div className='row'>
                                                        <Progress width='84px' type="circle" percent={item.layout * 1 / 10 * 100} trailColor='rgba(250,220,117,.2)' strokeColor='#fadc75' format={() => {
                                                            return (
                                                                <React.Fragment>
                                                                    <span style={{ fontSize: 24, fontWeight: 'bold' }}>{item.layout.split('.')[0]}</span>
                                                                    <span style={{ fontSize: 16 }}>.{item.layout.split('.')[1]}</span>
                                                                </React.Fragment>
                                                            )
                                                        }} />
                                                        <p>动静流线</p>
                                                    </div>
                                                    <div className='row'>
                                                        <Progress width='84px' type="circle" percent={item.practicalExpression * 1 / 10 * 100} trailColor='rgba(52,129,122,.2)' strokeColor='#409c93' format={() => {
                                                            return (
                                                                <React.Fragment>
                                                                    <span style={{ fontSize: 24, fontWeight: 'bold' }}>{item.practicalExpression.split('.')[0]}</span>
                                                                    <span style={{ fontSize: 16 }}>.{item.practicalExpression.split('.')[1]}</span>
                                                                </React.Fragment>
                                                            )
                                                        }} />
                                                        <p>节能环保</p>
                                                    </div>
                                                    <div className='row'>
                                                        <Progress width='84px' type="circle" percent={item.energySaving * 1 / 10 * 100} trailColor='rgba(3,164,244,.2)' strokeColor='#03a4f4' format={() => {
                                                            return (
                                                                <React.Fragment>
                                                                    <span style={{ fontSize: 24, fontWeight: 'bold' }}>{item.energySaving.split('.')[0]}</span>
                                                                    <span style={{ fontSize: 16 }}>.{item.energySaving.split('.')[1]}</span>
                                                                </React.Fragment>
                                                            )
                                                        }} />
                                                        <p>节能环保</p>
                                                    </div>
                                                    <div className='row'>
                                                        <Progress width='84px' type="circle" percent={item.score * 1 / 10 * 100} trailColor='rgba(1,245,160,.2)' strokeColor='#00F5A0' format={() => {
                                                            return (
                                                                <React.Fragment>
                                                                    <span style={{ fontSize: 24, fontWeight: 'bold' }}>{item.score.split('.')[0]}</span>
                                                                    <span style={{ fontSize: 16 }}>.{item.score.split('.')[1]}</span>
                                                                </React.Fragment>
                                                            )
                                                        }} />
                                                        <p>综合评分</p>
                                                    </div>
                                                </div>
                                                <div className='flex' style={{ marginTop: 30 }}>
                                                    {item.userImgsAll.map((item, index) => (
                                                        <img key={'u' + index} alt='' src={item.headImg} className='avatar' />
                                                    ))}
                                                    <img alt='' src={add} className='avatar' style={{ border: 0 }} />
                                                </div>
                                                <div className='flexx'>
                                                    {item.userImgs.map((item, index) => (
                                                        <div key={'f' + index} className='roww'>
                                                            <img alt='' src={item.headImg} className='avatar' />
                                                            {/* <p>{item.content}</p> */}
                                                            <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                                        </div>
                                                    ))}
                                                </div>
                                                {/* 第一名牌子 */}
                                                <div className='paibox'>
                                                    <img alt='' src={item.rankImg} />
                                                    <p>“第{item.ranking}名”</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })

                        }


                    </Carousel>
                    {/* 下一张 */}
                    <img alt='' src={next} className='slideIcon nextIcon' onClick={this.changeSlide.bind(this, 'next')} />
                </div>
                {/* 正在评奖中的作品 */}

                <div className='pjList w1400'>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 50 }}>
                        <p className='tit' style={{ marginTop: 0, marginRight: 20 }}>正在评奖中的作品</p>
                        <img alt='' src={this.state.awardBanner}
                            className='pic' style={{ display: 'block', width: 1220, height: 52 }} onClick={() => {
								window.open('#/rout/Product2/'+ this.state.awardId)
                            }} />
                    </div>

                    <div className='box' style={{ marginBottom: "40px" }}>

                        {
                            this.state.isWorks.map((item, index) => {
                                return (

                                    <div className='item' key={'' + index} onClick={() => {
										window.open('#/rout/ProductDetail/'+ item.id)
                                    }}>
                                        <div className='picbox'>
                                            <img alt='' src={item.thumb} className='pic' />
                                            <div className='massk'>
                                                <img alt='' src={search} />
                                            </div>
                                        </div>
                                        <div className='flexw'>

                                            {item.label.map((row, k) => (
                                                <p key={'' + k}>{row}</p>
                                            ))}

                                        </div>
                                        <div className='uinfo'>
                                            <div className='lbox'>
                                                <h1 className='title'>{item.title}</h1>
                                                <p>By {item.nickname}</p>
                                            </div>
                                            <img alt='' src={item.headImg} className='avatar' />
                                        </div>
                                        <div className='flex'>
                                            <div className='time' style={{ flex: 1 }}>

                                                {
                                                    item.otime > 0 ? <p className='p1'>剩余时间</p> : <p className='p1'><h1>0<span>DAYS</span>0<span>HOURS</span>0<span>MIN</span></h1></p>
                                                }
                                                {this.state.timeArr[index]}
                                                
                                            </div>
                                            <div className='time'>
                                                <p className='p1'>当前评分</p>
                                                <h1>{item.score}</h1>
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className='imgbox'>
                                                {item.userImgs.map((pic, i) => (
                                                    <img key={'' + i} alt='' src={pic.headImg} className='avatar' />
                                                ))}
                                                <img alt='' src={add} className='avatar' />
                                            </div>
                                            <p className='pfbtn'>评分</p>
                                        </div>
                                    </div>

                                )
                            })
                        }

                    </div>
                </div>



                {/* 广告图 */}

                {/* <img alt='' src={pic} className='adImg' /> */}
				
				<Carousel autoplay>
					{this.state.banner.map((bannerItem,bannerIndex) => (
						<a target='_blank' href={bannerItem.link} rel="noreferrer" ><img alt='' src={bannerItem.img} className='adImg' /></a>
					))}
				</Carousel>
				

                {/* 搜索框 */}
                <div className='searchBox w1400' onClick={() => {
                    this.props.history.push('/rout/product/' + 2)
                }}>
                    <span></span>
                    <p>搜索作品</p>
                </div>

                {/* 作品展示 */}
                <div className='pjList w1400'>
                    <p className='tit'>作品展示</p>
                    <div className='box'>
                        {this.state.data.map((item, index) => (
                            <div className='item' key={'z' + index} onClick={() => {
								window.open('#/rout/ProductDetail/'+ item.id)
                            }}>
                                <div className='picbox'>
                                    <img alt='' src={item.thumb} className='pic' />
                                    <div className='massk'>
                                        <img alt='' src={search} />
                                    </div>
                                </div>
                                <div className='flexw'>
                                    {item.label.map((row, k) => (
                                        <p key={'' + k}>{row}</p>
                                    ))}
                                </div>
                                <div className='uinfo'>
                                    <div className='lbox'>
                                        <h1 className='title'>{item.title}</h1>
                                        <p>By {item.nickname}</p>
                                    </div>
                                    <img alt='' src={item.headImg} className='avatar' />
                                </div>
                                <div className='llscbox'>
                                    <p>浏览：{item.pvNum}</p>
                                    <p>收藏：{item.collectionNum}</p>
                                    <p>下载：{item.downNum}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='sdxcv'>
                    {/* <Pagination current={this.state.page} pageSize={this.state.size} total={this.state.total} onChange={(index,i)=>{
                        this.setState({
                            page:index,
                        },()=>{
                            this.home()
                        })
                    }} /> */}
                    <p className='more' style={{ marginBottom: 20 }} onClick={() => {
                        this.props.history.push('/rout/product/1')
                    }}>查看所有</p>
                </div>
            </div>
        )
    }
}