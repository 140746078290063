import React from 'react';
import { message, Modal,Pagination  } from 'antd';

import banner from '../../imgs/banner.png'
import add from '../../imgs/add.png'
import close from '../../imgs/close.png'
import icon1 from '../../imgs/icon1.png'
import dy from '../../imgs/dy.png'
import logo from '../../imgs/logo.png'

export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            email:"",
            name:"",
            code:"",
            card:"",
            smstext:"获取验证码",
            smsstatus:true,
        }
    }
	componentDidMount(){
        document.title='绑定邮箱'
	}
    sendEmailCode(){
        var req={
            email:this.state.email,
            name:this.state.name,
        }
        
        if(this.state.smsstatus!=true){
            return '';
        }
        global.Ajax.hhtc_request('user/sendEmailCode',req).then(res=>{
			if(res.code==1){
                message.success(res.msg)
                this.djs()
			}else{
				message.error(res.msg)
			}
		})
    }
    bindEmail(){
        var req={
            email:this.state.email,
            code:this.state.code,
        }
        global.Ajax.hhtc_request('user/bindEmail',req).then(res=>{
			if(res.code==1){
                message.success(res.msg,2,()=>{
                    // window.location.reload();
                    this.props.history.goBack();
                })
			}else{
				message.error(res.msg)
			}
		})
    }
    //倒计时
    djs(){
        var time=60;
         this.timer= setInterval(() => {
            time--;
            if(time<=0){
                this.setState({
                    smstext:"重发",
                    smsstatus:true
                },()=>{
                    time=60;
                    clearInterval(this.timer);
                })
            }else{
                this.setState({
                    smstext:time+"s",
                    smsstatus:false
                })
            }
        }, 1000);
     }

    render(){
        return(
            <div style={{overflow: 'hidden'}}>
                <div className='telbox'>
                    <div className='flex'>
                        {/* <p style={{width: 185,lineHeight: '66px',backgroundColor: '#000',fontSize: 28,color: '#fff',textAlign: 'center',fontWeight: 'bold'}}>LOGO</p> */}
                        <img alt='' src={logo} className='icon' />
                        <p className='p1' onClick={()=>{
                            this.props.history.goBack()
                        }}>返回上一页</p>
                    </div>
                    <h1 className='tit'>绑定邮箱</h1>
                    <div className='bgbai'>
                        <input placeholder='请输入邮箱' className='inpt' 
                        onChange={(e)=>{
                                this.setState({
                                        email:e.target.value
                                })            
                        }}/>
                        <div className='flex1'>
                            <input placeholder='请输入邮箱验证码'onChange={(e)=>{
                                this.setState({
                                        code:e.target.value
                                })            
                        }}/>
                            <p className='btn' onClick={this.sendEmailCode.bind(this)}>{this.state.smstext}</p>
                        </div>
                        <p className='qbtn' style={{marginTop: 50}} onClick={this.bindEmail.bind(this)}>确认</p>
                    </div>
                </div>
            </div>
        )
    }
}