import React from 'react';
import { message, Modal,Pagination  } from 'antd';

import banner from '../../imgs/banner.png'
import add from '../../imgs/add.png'
import close from '../../imgs/close.png'
import icon1 from '../../imgs/icon1.png'
import dy from '../../imgs/dy.png'
import logo from '../../imgs/logo.png'

export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            email:"",
            name:"",
            code:"",
            card:"",
        }
    }
	componentDidMount(){
        document.title='实名认证'
	}
    sendEmailCode(){
        var req={
            email:this.state.email,
            name:this.state.name,
        }
        global.Ajax.hhtc_request('user/sendEmailCode',req).then(res=>{
			if(res.code==1){
                message.success(res.msg)
			}else{
				message.error(res.msg)
			}
		})
    }
    bindUser(){
        var req={
            name:this.state.name,
            card:this.state.card,
        }
        global.Ajax.hhtc_request('user/bindUser',req).then(res=>{
			if(res.code==1){
                message.success(res.msg,2,()=>{
                    // window.location.reload();
                    this.props.history.goBack();
                })
			}else{
				message.error(res.msg)
			}
		})
    }
    render(){
        return(
            <div style={{overflow: 'hidden'}}>
                <div className='telbox'>
                    <div className='flex'>
                        {/* <p style={{width: 185,lineHeight: '66px',backgroundColor: '#000',fontSize: 28,color: '#fff',textAlign: 'center',fontWeight: 'bold'}}>LOGO</p> */}
                        <img alt='' src={logo} className='icon' />
                        <p className='p1' onClick={()=>{
                            this.props.history.goBack()
                        }}>返回上一页</p>
                    </div>
                    <h1 className='tit'>实名认证</h1>
                    <div className='bgbai'>
                        <input placeholder='请输入真实姓名' className='inpt' onChange={(e)=>{
                                this.setState({
                                        name:e.target.value
                                })            
                        }}/>
                        <input placeholder='请输入身份证号（选填）' className='inpt' 
                        onChange={(e)=>{
                                this.setState({
                                    card:e.target.value
                                })            
                        }}/>
                        <p className='qbtn' style={{marginTop: 50}} onClick={this.bindUser.bind(this)}>确认</p>
                    </div>
                </div>
            </div>
        )
    }
}