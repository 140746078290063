import React from 'react';
import { message, Modal,Pagination,Upload,Select  } from 'antd';

import banner from '../../imgs/banner.png'
import add from '../../imgs/add.png'
import close from '../../imgs/close.png'
import icon1 from '../../imgs/icon1.png'
import dy from '../../imgs/dy.png'

export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            data: [1,1,1,1,1],
            visible: false,
            user:[],
            is_email:0,
            is_company:0,
            headImg:"",
            // 七牛配置
            upload_token: '',
            domain: '',
			font_img:"",
        }
    }
	componentDidMount(){
        document.title='个人资料'
        this.getUser()
        //获取七牛云配置
        global.Ajax.hhtc_request("agreement/get_config",{}).then(data=>{
			if(data.code===1){
				this.setState({
					upload_token: data.data.token,
					domain: data.data.domain,
				})
			}
        })
	}
    getUser(){
        global.Ajax.hhtc_request('user/personal').then(res=>{
			if(res.code==1){
                localStorage.setItem('headImg',res.data.headImg)//用户头像
				localStorage.setItem('level',res.data.level)//用户会员等级
				localStorage.setItem('nickname',res.data.nickname)//用户昵称
                this.setState({
					user:res.data,
                    is_email:res.data.is_email,
                    is_company:res.data.is_company,
                    headImg:res.data.headImg,
				})
			}else{
				message.error(res.msg)
			}
		})
    }
    save(){
        var req={
           nickname:this.state.user.nickname,
           sign:this.state.user.sign,
           headImg:this.state.headImg,
        }
        global.Ajax.hhtc_request('user/updateInfo',req).then(res=>{
			if(res.code==1){
                localStorage.setItem('headImg',this.state.headImg)//用户头像
                localStorage.setItem('nickname',this.state.user.nickname)//用户昵称
                message.success(res.msg,2,()=>{
                    window.location.reload();
                })
			}else{
				message.error(res.msg)
			}
		})
    }
    render(){
        var that = this;
        const props = {
            name: 'file',
            action: 'https://up-z0.qiniup.com',
            showUploadList:false,
            headers: {
                authorization: 'authorization-text',
            },
            data:(file)=>{
                console.log(file)
                var houzui = '.'+file.name.split('.')[1];
                return {
                    token:that.state.upload_token,
                    key:Date.now()+Math.floor(Math.random()*(999999-100000)+100000)+'1'+houzui,
                }
            },
        };
        return(
            <div className='padd_top66'>
                {/* menu */}
                <div className='menubox' style={{marginBottom: 36}}>
                    <div className='w1400'>
                        <p onClick={()=>{
                            this.props.history.push("/rout/home")
                        }}>首页</p>
						<p onClick={()=>{
                            this.props.history.push("/rout/userinfo")
                        }}>我的主页</p>
                        <p>个人资料</p>
                    </div>
                </div>
                <div className='personalData'>
                    <p className='tit'>个人资料</p>
                    
                    {
                        this.state.user.headImg!=null?
                        <Upload {...props} onChange={(info)=>{
                            if (info.file.status === 'done') {
                                var src=this.state.domain+'/'+info.file.response.key
                                this.setState({
                                    headImg: src
                                })
                                console.log(src)
                            }else if(info.file.status === 'error') {
                                message.error("失败原因："+info.file.response.error)
                            }
                        }}>
                            {
                                this.state.headImg!=null
                                ?<img className='avatar' alt='' src={this.state.headImg} />:<img alt='' src={this.state.user.headImg} className='avatar' />
                                
                            }
                        </Upload>
                        :
                        <Upload {...props} onChange={(info)=>{
                            if (info.file.status === 'done') {
                                var src=this.state.domain+'/'+info.file.response.key
                                this.setState({
                                    headImg: src
                                })
                                console.log(src)
                            }else if(info.file.status === 'error') {
                                message.error("失败原因："+info.file.response.error)
                            }
                        }}>
                            {
                                this.state.headImg!=null
                                ?<img className='avatar' alt='' src={this.state.headImg} />:<img alt='' src={add} className='avatar' /> 
                                
                            }
                        </Upload>
                    }
                    



                    <p className='jb1'>基本信息</p>
                    <div className='flex'>
                        <p className='p1'>昵称</p>
                        <input placeholder='昵称' className='inpt' disabled value={this.state.user.nickname} 
                        onChange={(e)=>{
                            var user = this.state.user;
                            user.nickname = e.target.value;
                                this.setState({
                                    user
                                })            
                        }}/>
                    </div>
                    <div className='flex'>
                        <p className='p1'>手机号</p>
                        <p className='p2'>{this.state.user.mobile}</p>
                        <p className='p3' onClick={()=>{
                            this.props.history.push('/pages/rout/teledit')
                        }}>修改</p>
                    </div>
                    <div className='flex'>

                        <p className='p1'>邮箱</p>
                        {
                            this.state.is_email==null?<p className='p3' onClick={()=>{
                                this.props.history.push('/pages/rout/bindemail')
                            }}>去认证</p>:""
                        }

                        {
                            this.state.is_email==-1?<p className='p3' onClick={()=>{
                                this.props.history.push('/pages/rout/bindemail')
                            }}>去认证</p>:""
                        }

                        {
                            this.state.is_email==0?<p className='p2' onClick={()=>{
                                this.props.history.push('/pages/rout/bindemail')
                            }}>审核中</p>:""
                        }
                        {
                            this.state.is_email==1?<p className='p2'>{this.state.user.email}</p>:""
                        }

                        {
                            this.state.is_email==2?<p className='p2' style={{color:'red'}}>{"失败原因："+this.state.user.email_info}</p>:""
                        }

                        {
                            this.state.is_email==2?<p className='p3' onClick={()=>{
                                this.props.history.push('/pages/rout/bindemail')
                            }}>重新绑定</p>:""
                        }
                        
                    </div>
                    <div className='flex'>
                        <p className='p1'>实名认证</p>
                            {
                                this.state.user.is_auth==null?<p className='p3' onClick={()=>{
                                    this.props.history.push('/pages/rout/binduser')
                                }}>去认证</p>:""
                            }

                            {
                                this.state.user.is_auth==-1?<p className='p3' onClick={()=>{
                                    this.props.history.push('/pages/rout/binduser')
                                }}>去认证</p>:""
                            }

                            {
                                this.state.user.is_auth==0?<p className='p2'>审核中</p>:""
                            }
                            {
                                this.state.user.is_auth==1?<p className='p2'>{this.state.user.name}</p>:""
                            }
                            {
                                this.state.user.is_auth==2?<p className='p2' style={{color:'red'}}>{"失败原因："+this.state.user.auth_info}</p>:""
                            }
                            {
                                this.state.user.is_auth==2?<p className='p3' onClick={()=>{
                                    this.props.history.push('/pages/rout/binduser')
                                }}>重新认证</p>:""
                            }
                    </div>

                    <div className='flex'>
                        <p className='p1'>个人简介</p>
                        <textarea placeholder='自我介绍~' value={this.state.user.sign} 
                        onChange={(e)=>{
                            var user = this.state.user;
                            user.sign = e.target.value;
                                this.setState({
                                    user
                                })     
                        }}></textarea>
                    </div>
                    <div className='flex'>
                        <p className='p1'>公司认证</p>
                        {
                            this.state.is_company==-1?<p className='p3' onClick={()=>{
                                this.props.history.push('/pages/rout/company')
                            }}>去认证</p>:""
                        }

                        {
                            this.state.is_company==0?<p className='p3' onClick={()=>{
                                this.props.history.push('/pages/rout/company')
                            }}>去认证</p>:""
                        }

                        
                        {
                            this.state.is_company==1?<p className='p2'>{this.state.user.company}</p>:""
                        }

                        {
                            this.state.is_company==2?<p className='p2' style={{color:"red"}}>{"失败原因："+this.state.user.company_info}</p>:""
                        }
                        {
                            this.state.is_company==2?<p className='p3' onClick={()=>{
                                this.props.history.push('/pages/rout/company')
                            }}>重新认证</p>:""
                        }

                        {
                            this.state.is_company==3?<p className='p2'>认证已提交，待审核</p>:""
                        }

                        
                        
                        
                    </div>
                    <p className='save' onClick={this.save.bind(this)}>保存</p>
                </div>
            </div>
        )
    }
}