import React from 'react';
import { Modal,message } from 'antd';
// import { CustomErrorComponent } from 'custom-error';

import FileViewer from 'react-file-viewer';//引入在线预览pdf excel cad组件
import ReactPdf from 'react-pdf-js';
import { Document, Page } from 'react-pdf';


import ReactDOM from 'react-dom';
import banner from '../../imgs/banner.png'
import search from '../../imgs/search1.png'
import prev from '../../imgs/prev1.png'
import next from '../../imgs/next1.png'


export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            width: '10000px',
            transition: '500ms ease-out',
            transform: 'translatex(0px)',
            countnum: 8,     //图片数量
            curPageIndex: 0, //点击右箭头+1，左箭头减1
            viewcount: 5,    //初始展示图片数量（和div宽度相关） 
            data: [1,1,1,1,1,1,1,1,1,1,1],
            work:[],
            imgs:[],
            cad:"",
            excel:"",
            pdf:"",
            pdfhzm:"",
            excelhzm:"",
            numPages: null,
            pageNumber: 1,
            thumb:"",
			free_excel:"",
        }
    }



    componentDidMount(){
        this.setState({
            // width: this.state.data.length*170+'px',
            countnum: this.state.data.length
        })
        document.title=this.props.title+'-户型展示'
        this.getData();

    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
      }
    getData(){
        console.log(this.props.id)
        var req={
            id:this.props.id,
        }
         global.Ajax.hhtc_request('home/details',req).then(res=>{
             if(res.code==1){
                var pdfhzm = res.data.pdf.substring(res.data.pdf.lastIndexOf('.') + 1);
                var excelhzm = res.data.excel.substring(res.data.excel.lastIndexOf('.') + 1);
                 this.setState({
                    work:res.data,
                    imgs:res.data.imgs,
                    thumb:res.data.thumb,
                    excel:res.data.excel,
                    pdf:res.data.pdf,
                    cad:res.data.ocfUrl,
					free_excel:res.data.free_excel,
                    pdfhzm,
                    excelhzm,
                 })
                 console.log(res.data.ocfUrl)
             }else{
                 message.error(res.msg,2)
             }
         })
    }
    //函数
    handleclick(type){
        let { curPageIndex } = this.state
        if(type==='prev'){
            if(curPageIndex-1<0){
                return
            }
            this.getPage(curPageIndex-1)
        }else{
            if(curPageIndex+this.state.viewcount>=this.state.data.length){
                return
            }
            this.getPage(curPageIndex + 1)
        }
    }
    getPage(value) {
        let { countnum,viewcount} = this.state
        let n = countnum - viewcount
        //一项的宽度
        let itemwidth = 170
        let transform = 'translatex(-' + value * itemwidth + 'px)'
        value > n || 0 > value || this.setState({transform:transform})
        this.setState({
            curPageIndex: value,
        })
    }

    
    render(){
        let { width,transition,transform } = this.state;
        const { pageNumber, numPages } = this.state;
        return(
            <React.Fragment>
                <p className='tit1 ' >
                   <span className="active111111">图片预览</span> 
                </p>
                <img alt='' src={this.state.thumb} className='bigImg' />

                {/* 图集 */}
                <div className='sltBanner'>
                    <img alt='' src={prev} className='arrow_l' onClick={() => this.handleclick('prev')} />
                    <div className="bd basefix ">
                        <div className="pro_list_wrap"  style={{ width: width, transition: transition, transform: transform }} id="hot_scenics_list">
                            
                            {this.state.imgs.map((item,index)=>(
                                <div className="pro_list_recomm" key={'i'+index} onClick={()=>{
                                    this.setState({
                                        // cad:item,
                                        thumb: item
                                    })
                                }}>
                                    <img alt='' src={item} />
                                    <div className='massk'>
                                        <img alt='' src={search} />
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <img alt='' src={next} className='arrow_r' onClick={() => this.handleclick('next')} />
                </div>
                
                {/* 预览cad */}
                {/* <p className='tit1 active' style={{ color: '#66B9BF' }}>
                    CAD预览（点击 "S/W" 按钮切换背景颜色，阅读效果更佳）
                </p> */}
                <p className='tit1 active' >
                   <span className="active111111" >CAD预览</span> <span style={{ color: '#66B9BF' }}>（点击 "S/W" 按钮切换背景颜色，阅读效果更佳）</span>
                </p>
                {
                    this.state.cad !=null && this.state.cad!='' ?
                    //http://www.cadsofttools.com/dwgviewer/floorplan.dwg
                    // src={" //sharecad.org/cadframe/load?url=" + this.state.cad}
                    <div style={{overflow:"scroll",width:'100%',height:'500px',border: "2px solid #389589"}}>
                        <iframe
                            style={{width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
                            ref="iframe"
                            src={"https://www.realtydt.com/webcad/demo.html?ocfUrl=" + this.state.cad}
                            width="100%"
                            height="500px"
                            scrolling="no"
                            frameBorder="0"
                        />
                    </div>
                    :<p >暂不支持在线预览</p>
                }
                

                {/* {
                    this.state.work.cad2!=""?<img alt='' src={this.state.work.cad2} className='bigImg' />:<p></p>
                } */}
                
                <p className='tit1 ' >
                   <span className="active111111">指标预览</span> 
                

                    {/* 指标预览 */}
                {
					
					
                    // this.state.excelhzm=="xlsx"?
                    // <a href={"https://view.officeapps.live.com/op/view.aspx?src=" + this.state.excel} target='_blank' rel="noreferrer" >（点击全屏预览）</a>
                    // :<p ></p>

                }
                
                </p>
				
                {
					//判断表格是否可以展示
					
					//-------------旧版展示
					// <iframe
					//     style={{width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
					//         style={{width:'5000px',height:'2000px',overflow:'revert',minWidth:'100%' }}
					//         src={"https://view.officeapps.live.com/op/view.aspx?src=" + this.state.excel}
					//         width="50" 
					//         height="500" 
					//         frameBorder="1"
					// >
					
                    this.state.excelhzm=="xlsx"?
                    <div style={{overflow:"scroll",width:'100%',height:'500px',border: "2px solid #389589"}}>
						
					
						<div  dangerouslySetInnerHTML={{ __html: this.state.free_excel }}></div>
						
                    </div>
                    :<p >暂不支持在线预览</p>

                }

                <p className='tit1' style={{ color: '#66B9BF' }}>
                    
                    <span className='active111111'>PDF预览</span> 
                </p>

                {
                    
                    this.state.pdfhzm=="pdf"?

                    // <iframe 
                    //     style={{width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
                    //     ref="iframe" 
                    //     src={this.state.pdf}
                    //     width="100%" 
                    //     height={'500px'} 
                    //     scrolling="no" 
                    //     frameBorder="0"
                    // />

                    <div  style={{height:500,width:"100%",border:"2px solid #389589"}}>
                            <FileViewer
                                fileType={'pdf'}
                                filePath={this.state.pdf}
                            />
                    </div>
                    
                    
                    // <iframe 
                    // style={{width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
                    // ref="iframe" 
                    // src={this.state.pdf}
                    // width="100%" 
                    // height={'500px'} 
                    // scrolling="no" 
                    // frameBorder="0"
                    // />
                    
                    // <div style={{overflow:'scroll',height:600,width:1004}}>
                    //     <ReactPdf
                    //     　　file={this.state.pdf} // 这个地方是你的pdf文件或者是一个地址（推荐使用文件）
                    //     　　onDocumentComplete={this.onDocumentComplete} // 这个是当pdf文件解析完成之后的回调函数，会返回给你一个pdf的总页数
                    //     　　page={3} // 在state中保存的page，这个值可以手动写：1就代表第一页 2就代表第二页...，
                    //     />
                    // </div>

                    // <div    style={{overflow:'scroll',height:600,width:1004}}>
                    // <Document
                    // file={ 'https://market.schhtc.cn/o_1f98b2j8v19um178op8e1e3d1tuoa.pdf' }
                    // onLoadSuccess={this.onDocumentLoadSuccess}
                    // >
                    // <Page pageNumber={pageNumber} />
                    // </Document>
                    // <p>Page {pageNumber} of {numPages}</p>
                    // </div>
                    
                    // <div>
                    //     <Document
                    //     file="https://market.schhtc.cn/o_1f98b2j8v19um178op8e1e3d1tuoa.pdf"
                    //     onLoadSuccess={this.onDocumentLoadSuccess}
                    //     >
                    //     <Page pageNumber={pageNumber} />
                    //     </Document>
                    //     <p>Page {pageNumber} of {numPages}</p>
                    // </div>


                    :<p >暂不支持在线预览</p>

                }

            </React.Fragment>
        )
    }
}


