import React from 'react';
import { message, Modal  } from 'antd';

import banner from '../../imgs/banner.png'
import add from '../../imgs/add.png'
import close from '../../imgs/close.png'
import icon1 from '../../imgs/icon1.png'
import dy from '../../imgs/dy.png'
import logo from '../../imgs/logo.png'

export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            old_mobile:"",
            old_code:"",
            new_mobile:"",
            new_code:"",
        }
    }
	componentDidMount(){
        document.title='修改手机号'
	}
    //获取旧手机号验证码
	getOldCode(){
        var req={
            mobile: this.state.old_mobile,
            type:4
        }
        global.Ajax.hhtc_request('login/SendCode',req).then(res=>{
            if(res.code==1){
                message.success(res.msg,2)
            }else{
                message.error(res.msg,2)
            }
        })
        
    }
    //获取新手机号验证码
    getNewCode(){
        var req={
            mobile: this.state.new_mobile,
            type:4
        }
        global.Ajax.hhtc_request('login/SendCode',req).then(res=>{
            if(res.code==1){
                message.success(res.msg,2)
            }else{
                message.error(res.msg,2)
            }
        })
    }
    modifyMobile(){
        var req={
            old_mobile: this.state.old_mobile,
            old_code: this.state.old_code,
            new_mobile: this.state.new_mobile,
            new_code: this.state.new_code,
        }
        global.Ajax.hhtc_request('user/modifyMobile',req).then(res=>{
            if(res.code==1){
                message.success(res.msg,2)
                this.props.history.push('/rout/personal')
            }else{
                message.error(res.msg,2)
            }
        })
    }
    render(){
        return(
            <div style={{overflow: 'hidden'}}>
                <div className='telbox'>
                    <div className='flex'>
                        {/* <p style={{width: 185,lineHeight: '66px',backgroundColor: '#000',fontSize: 28,color: '#fff',textAlign: 'center',fontWeight: 'bold'}}>LOGO</p> */}
                        <img alt='' src={logo} className='icon' />
                        <p className='p1' onClick={()=>{
                            this.props.history.goBack()
                        }}>返回上一页</p>
                    </div>
                    <h1 className='tit'>修改手机号</h1>
                    <div className='bgbai'>
                        <input placeholder='请输入旧手机号码' className='inpt' onChange={(e)=>{
                                this.setState({
                                    old_mobile:e.target.value
                                })            
                        }}/>
                        <div className='flex1'>
                            <input placeholder='请输入验证码' onChange={(e)=>{
                                this.setState({
                                    old_code:e.target.value
                                })            
                        }}/>
                            <p className='btn' onClick={this.getOldCode.bind(this)}>获取验证码</p>
                        </div>
                        <input placeholder='请输入新手机号码' className='inpt' onChange={(e)=>{
                                this.setState({
                                    new_mobile:e.target.value
                                })            
                        }}/>
                        <div className='flex1'>
                            <input placeholder='请输入验证码' onChange={(e)=>{
                                this.setState({
                                    new_code:e.target.value
                                })            
                        }}/>
                            <p className='btn' onClick={this.getNewCode.bind(this)}>获取验证码</p>
                        </div>
                        <p className='qbtn' onClick={this.modifyMobile.bind(this)}>确认</p>
                    </div>
                </div>
            </div>
        )
    }
}