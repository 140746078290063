import React from 'react';
import { Carousel, Progress, message } from 'antd';
export default class Index extends React.Component {
    constructor(props) {
            super(props)
            var workId = props.match.params.id;
            this.state = {
                show: 2,//this.props.match.params.id
                workId: workId,
                comprehensive_dl: [],//地理特征
                comprehensive_gj: [],//关键特征
    
                building_jb: [],//楼型参数-基本特征
                building_hxt: [],//楼型参数-核心筒特征
                building_tz: [],//楼型参数-拓展特征
                building_jn: [],//楼型参数-节能特征
    
                module_jb: [],//户型1模块-基本特征
                module_kt: [],//户型1模块-客厅特征
                module_ws: [],//户型1模块-卧室1特征
                module_zs: [],//户型1模块-赠送特征
                module_cf: [],//户型1模块-厨房特征
                module_ct: [],//户型1模块-餐厅特征
                module_wc: [],//户型1模块-公卫特征
                module_mg: [],//户型1模块-敏感特征
    
                standard_gf: [],//标准参数-规范特征
                standard_cg: [],//标准参数-规范特征
    
                hxCs: [],
            }
        }
        componentDidMount() {
            document.title = '作品参数'
            this.getData();
        }
    getData() {
        var workId = this.state.workId;
        console.log('作品ID:' + workId)
        global.Ajax.hhtc_request('web/workParameter?workId=' + workId).then(res => {
            if (res.code == 1) {
                this.setState({
                    comprehensive_dl: res.data.comprehensive_dl,//地理特征
                    comprehensive_gj: res.data.comprehensive_gj,//关键特征

                    building_jb: res.data.building_jb,//楼型参数-基本特征
                    building_hxt: res.data.building_hxt,//楼型参数-核心筒特征
                    building_tz: res.data.building_tz,//楼型参数-拓展特征
                    building_jn: res.data.building_jn,//楼型参数-节能特征

                    // module_jb:res.data.module_jb,//户型1模块-基本特征
                    // module_kt:res.data.module_kt,//户型1模块-客厅特征
                    // module_ws:res.data.module_ws,//户型1模块-卧室1特征
                    // module_zs:res.data.module_zs,//户型1模块-赠送特征
                    // module_cf:res.data.module_cf,//户型1模块-厨房特征
                    // module_ct:res.data.module_ct,//户型1模块-餐厅特征
                    // module_wc:res.data.module_wc,//户型1模块-公卫特征
                    // module_mg:res.data.module_mg,//户型1模块-敏感特征

                    hxCs: res.data.hxCs,//户型1模块-基本特征

                    standard_gf: res.data.standard_gf,//标准参数-规范特征
                    standard_cg: res.data.standard_cg,//标准参数-规范特征
                })
            } else {
                message.error(res.msg, 2)
            }
        })
    }
    
	render() {
	    return (
	        <React.Fragment>
	
	            <div className='tabble tabble1' style={{ "margin-top": "60px" }}>
	                <h2>综合参数</h2>
	                <div className='col2'>
	                    <div className='row1'>
	                        <p className='titt'>地理特征</p>
	                        <div className='tablist'>
	                            <div className='itee item50'>
	                                <p className='t1'>目标省份：</p>
	                                <p className='t2'>{this.state.comprehensive_dl.province}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>目标城市：</p>
	                                <p className='t2'>{this.state.comprehensive_dl.city}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>对标企业：</p>
	                                <p className='t2'>{this.state.comprehensive_dl.developmentName}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>日照要求：</p>
	                                <p className='t2'>{this.state.comprehensive_dl.sunshine}</p>
	                            </div>
	                            <div className='itee item100'>
	                                <p className='t1'>适用地形：</p>
	                                <p className='t2'>{this.state.comprehensive_dl.terrain}</p>
	                            </div>
	                        </div>
	                    </div>
	                    <div className='row1'>
	                        <p className='titt'>关键特征</p>
	                        <div className='tablist'>
	                            <div className='itee item50'>
	                                <p className='t1'>产品业态：</p>
	                                <p className='t2'>{this.state.comprehensive_gj.format}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>适用层数：</p>
	                                <p className='t2'>{this.state.comprehensive_gj.layers}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>交付支持：</p>
	                                <p className='t2'>{this.state.comprehensive_gj.support}</p>
	                            </div>
								<div className='itee item50'>
								    <p className='t1'>产品定位：</p>
								    <p className='t2'>{this.state.comprehensive_gj.plotRatio}</p>
								</div>
	                            <div className='itee item100'>
	                                <p className='t1'>容积率值：</p>
	                                <p className='t2'>{this.state.comprehensive_gj.rating}</p>
	                            </div>
	                            
	                        </div>
	                    </div>
	                </div>
	            </div>
				
				{/* 标准参数 */}
				<div className='tabble tabble1'>
				    <h2 style={{ background: '#4dbdb3' }}>限定参数</h2>
				    <div className='col2'>
				
				        <div className='row1'>
				            <p className='titt' style={{ background: '#4dbdb3' }}>规范特征</p>
				            <div className='tablist'>
				                <div className='itee item100'>
				                    <p className='t1'>消防设计规范：</p>
				                    <p className='t2'>{this.state.standard_gf.codeFireProtectionDesign}</p>
				                </div>
				                <div className='itee item100'>
				                    <p className='t1'>面积计算规范：</p>
				                    <p className='t2'>{this.state.standard_gf.codeAreaCalculation}</p>
				                </div>
				            </div>
				        </div>
				
				        <div className='row1'>
				            <p className='titt' style={{ background: '#4dbdb3' }}>市场特征</p>
				            <div className='tablist'>
				                <div className='itee item100'>
				                    <p className='t1'>面世年度：</p>
				                    <p className='t2'>{this.state.standard_cg.drawingDepth}</p>
				                </div>
				                <div className='itee item100'>
				                    <p className='t1'>市场响应：</p>
				                    <p className='t2'>{this.state.standard_cg.drawingOptions}</p>
				                </div>
				            </div>
				        </div>
						
				    </div>
				</div>
	
	            <div className='tabble tabble1'>
	                <h2 className='lxmk' style={{ background: '#55CFCC' }}>楼型参数</h2>
	                <div className='col2'>
	                    <div className='row1'>
	                        <p className='titt' style={{ background: '#84D3D1' }}>基本特征</p>
	                        <div className='tablist'>
	                            <div className='itee item50'>
	                                <p className='t1'>楼型面积：</p>
	                                <p className='t2'>{this.state.building_jb.standardArea}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>得房率值：</p>
	                                <p className='t2'>{this.state.building_jb.houseRate}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>楼型面宽：</p>
	                                <p className='t2'>{this.state.building_jb.floorWidth}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>楼型进深：</p>
	                                <p className='t2'>{this.state.building_jb.buildingDepth}</p>
	                            </div>
	                            <div className='itee item100'>
	                                <p className='t1'>楼型户数：</p>
	                                <p className='t2'>{this.state.building_jb.buildingNum}</p>
	                            </div>
	                           
	                            
	                        </div>
	                    </div>
	                    <div className='row1'>
	                        <p className='titt' style={{ background: '#84D3D1' }}>核心筒特征</p>
	                        <div className='tablist'>
	                            <div className='itee item50'>
	                                <p className='t1'>核心筒位置：</p>
	                                <p className='t2'>{this.state.building_hxt.type}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>楼电梯关系：</p>
	                                <p className='t2'>{this.state.building_hxt.relation}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>核心筒明窗：</p>
	                                <p className='t2'>{this.state.building_hxt.window}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>电梯数量：</p>
	                                <p className='t2'>{this.state.building_hxt.elevatorsNum}</p>
	                            </div>
	                            <div className='itee item100'>
	                                <p className='t1'>楼梯数量：</p>
	                                <p className='t2'>{this.state.building_hxt.stairsNum}</p>
	                            </div>
	                            
	                            
	                        </div>
	                    </div>
	
	                    <div className='row1'>
	                        <p className='titt' style={{ background: '#84D3D1' }}>拓展特征</p>
	                        <div className='tablist'>
	                            <div className='itee item50'>
	                                <p className='t1'>楼型形状：</p>
	                                <p className='t2'>{this.state.building_tz.shape}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>板塔关系：</p>
	                                <p className='t2'>{this.state.building_tz.combination}</p>
	                            </div>
	                            <div className='itee item100'>
	                                <p className='t1'>拼接支持：</p>
	                                <p className='t2'>{this.state.building_tz.splicing}</p>
	                            </div>
	                            
	                        </div>
	                    </div>
	                    <div className='row1'>
	                        <p className='titt' style={{ background: '#84D3D1' }}>节能特征</p>
	                        <div className='tablist'>
	                            <div className='itee item50'>
	                                <p className='t1'>体形系数：</p>
	                                <p className='t2'>{this.state.building_jn.shapeCoefficient}</p>
	                            </div>
	                            <div className='itee item50'>
	                                <p className='t1'>窗地比值：</p>
	                                <p className='t2'>{this.state.building_jn.windowFloorRatio}</p>
	                            </div>
	                            <div className='itee item100'>
	                                <p className='t1'>窗墙比值：</p>
	                                <p className='t2'>{this.state.building_jn.windowWallRatio}</p>
	                            </div>
	                            
	                        </div>
	                    </div>
	
	                </div>
	            </div>
	
	            {
	
	                this.state.hxCs.map((item, index) => {
	                    return (
	                        <div className='tabble tabble1'>
	                            <h2 style={{ background: '#55CFBC' }} key={'index' + index}>户型{index + 1}参数</h2>
	                            <div className='col2'>
	                                <div className='row1'>
	                                    <p className='titt' style={{ background: '#7DD3C6' }}>基本特征</p>
	                                    <div className='tablist'>
	                                        <div className='itee item100'>
	                                            <p className='t1'>房型配置：</p>
	                                            <p className='t2'>
	                                                <input placeholder='-' value={item.hxJbtz.jbtz_hxjps[0]} />
	                                            </p>
	                                            <p className='t1 mar_3' style={{ color: '#000000' }}>改</p>
	                                            <p className='t2'>
	                                                <input placeholder='-' value={item.hxJbtz.jbtz_hxjps[1]} />
	                                            </p>
	                                            <p className='t1 mar_3'>客</p>
	                                            <p className='t2'>
	                                                <input placeholder='-' value={item.hxJbtz.jbtz_hxjps[2]} />
	                                            </p>
	                                            <p className='t1 mar_3'>改</p>
	                                            <p className='t2'>
	                                                <input placeholder='-' value={item.hxJbtz.jbtz_hxjps[3]} />
	                                            </p>
	                                            <p className='t1 mar_3'>餐</p>
	                                            <p className='t2'>
	                                                <input placeholder='-' value={item.hxJbtz.jbtz_hxjps[4]} />
	                                            </p>
	                                            <p className='t1 mar_3'>改</p>
	                                            <p className='t2'>
	                                                <input placeholder='-' value={item.hxJbtz.jbtz_hxjps[5]} />
	                                            </p>
	                                            <p className='t1 mar_3'>卧</p>
	                                            <p className='t2'>
	                                                <input placeholder='-' value={item.hxJbtz.jbtz_hxjps[6]} />
	                                            </p>
	                                            <p className='t1 mar_3'>改</p>
	                                            <p className='t2'>
	                                                <input placeholder='-' value={item.hxJbtz.jbtz_hxjps[7]} />
	                                            </p>
	                                            <p className='t1 mar_3'>卫</p>
	                                            <p className='t2'>
	                                                <input placeholder='-' value={item.hxJbtz.jbtz_hxjps[8]} />
	                                            </p>
	                                            <p className='t1 mar_3'>改</p>
	                                            <p className='t2'>
	                                                <input placeholder='-' value={item.hxJbtz.jbtz_hxjps[9]} />
	                                            </p>
	                                            <p className='t1 mar_3'>厨</p>
	                                        </div>
	                                        <div className='itee item50'>
	                                            <p className='t1'>户型建面：</p>
	                                            <p className='t2'>{item.hxJbtz.jbtz_hxmj}</p>
	                                        </div>
	                                        <div className='itee item100'>
	                                            <p className='t1'>相同户数：</p>
	                                            <p className='t2'>{item.hxJbtz.jbtz_tlxhxs}</p>
	                                        </div>
	                                    </div>
	                                </div>
	
	                                {/* 厨房特征 */}
	                                {
	                                    item.hxCftz.map((item1, index1) => {
	                                        return (
	                                            <div className='row1' key={'index1' + index1}>
	                                                <p className='titt' style={{ background: '#7DD3C6' }}>厨房{index1 + 1}特征</p>
	                                                <div className='tablist'>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>厨房布局：</p>
	                                                        <p className='t2'>{item1.cftz_cfbj}</p>
	                                                    </div>
														<div className='itee item50'>
														    <p className='t1'>阳台关联：</p>
														    <p className='t2'>{item1.cftz_ytgl}</p>
														</div>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>厨房面宽：</p>
	                                                        <p className='t2'>{item1.cftz_cfmk}</p>
	                                                    </div>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>厨房进深：</p>
	                                                        <p className='t2'>{item1.cftz_cfjs}</p>
	                                                    </div>
	                                                    <div className='itee item100'>
	                                                        <p className='t1'>中西分厨：</p>
	                                                        <p className='t2'>{item1.cftz_dlzxcf}</p>
	                                                    </div>
	                                                    
	                                                    
	                                                </div>
	                                            </div>
	                                        )
	                                    })
	                                }
	
	
	
	                                {/* 客厅特征 */}
	                                {
	                                    item.hxKttz.map((item2, index2) => {
	                                        return (
	                                            <div className='row1' key={'index2' + index2}>
	                                                <p className='titt' style={{ background: '#7DD3C6' }}>客厅{index2 + 1}特征</p>
	                                                <div className='tablist'>
	                                                <div className='itee item50'>
	                                                        <p className='t1'>客厅面宽：</p>
	                                                        <p className='t2'>{item2.kttz_ktmk}</p>
	                                                    </div>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>客厅进深：</p>
	                                                        <p className='t2'>{item2.kttz_ktjs}</p>
	                                                    </div>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>客厅轴向：</p>
	                                                        <p className='t2'>{item2.kttz_ktzx}</p>
	                                                    </div>
	                                                    
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>端厅配置：</p>
	                                                        <p className='t2'>{item2.kttz_dt}</p>
	                                                    </div>
	                                                </div>
	                                            </div>
	                                        )
	                                    })
	                                }
	
	
	                                {/* 餐厅特征 */}
	                                {
	                                    item.hxCttz.map((item3, index3) => {
	                                        return (
	                                            <div className='row1' key={'index3' + index3}>
	                                                <p className='titt' style={{ background: '#7DD3C6' }}>餐厅{index3 + 1}特征</p>
	                                                <div className='tablist'>
	                                                <div className='itee item50'>
	                                                        <p className='t1'>餐厅面宽：</p>
	                                                        <p className='t2'>{item3.cttz_ctmk}</p>
	                                                    </div>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>餐厅进深：</p>
	                                                        <p className='t2'>{item3.cttz_ctjs}</p>
	                                                    </div>
	                                                    <div className='itee item100'>
	                                                        <p className='t1'>中西分餐：</p>
	                                                        <p className='t2'>{item3.cttz_dlzxct}</p>
	                                                    </div>
	                                                    
	                                                </div>
	                                            </div>
	                                        )
	                                    })
	                                }
	
	
	                                {/* 卧室特征 */}
	                                {
	                                    item.hxWstz.map((item4, index4) => {
	                                        return (
	                                            <div className='row1' key={'index4' + index4}>
	                                                <p className='titt' style={{ background: '#7DD3C6' }}>卧室{index4 + 1}特征</p>
	                                                <div className='tablist'>
	                                                <div className='itee item50'>
	                                                        <p className='t1'>卧室面宽：</p>
	                                                        <p className='t2'>{item4.wstz_wsmk}</p>
	                                                    </div>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>卧室进深：</p>
	                                                        <p className='t2'>{item4.wstz_wsjs}</p>
	                                                    </div>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>衣帽间类型：</p>
	                                                        <p className='t2'>{item4.wstz_dlymj}</p>
	                                                    </div>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>房间串套：</p>
	                                                        <p className='t2'>{item4.wstz_gnfjct}</p>
	                                                    </div>
	                                                    
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>独卫配置：</p>
	                                                        <p className='t2'>{item4.wstz_hwsj}</p>
	                                                    </div>
	
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>飘窗赠送：</p>
	                                                        <p className='t2'>{item4.wstz_pc}</p>
	                                                    </div>
	                                                </div>
	                                            </div>
	                                        )
	                                    })
	                                }
	
	
	                                {/* 卫生间特征 */}
	                                {
	                                    item.hxWsjtz.map((item5, index5) => {
	                                        return (
	                                            <div className='row1' key={'index5' + index5}>
	                                                <p className='titt' style={{ background: '#7DD3C6' }}>卫{index5 + 1}特征</p>
	                                                <div className='tablist'>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>卫生间面宽：</p>
	                                                        <p className='t2'>{item5.gwtz_wsjmk}</p>
	                                                    </div>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>卫生间进深：</p>
	                                                        <p className='t2'>{item5.gwtz_wsjjs}</p>
	                                                    </div>
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>卫生间布局：</p>
	                                                        <p className='t2'>{item5.gwtz_wsjbj}</p>
	                                                    </div>
	                                                    
	                                                    <div className='itee item50'>
	                                                        <p className='t1'>干湿分离：</p>
	                                                        <p className='t2'>{item5.gwtz_wsjgsfl}</p>
	                                                    </div>
														
														<div className='itee item100'>
														    <p className='t1'>干区合用：</p>
														    <p className='t2'>{item5.gwtz_cjgnfrkhy}</p>
														</div>
														
	                                                </div>
	                                            </div>
	                                        )
	                                    })
	                                }
	
	
	                                {/* 赠送特征 */}
	                                <div className='row1'>
	                                    <p className='titt' style={{ background: '#7DD3C6' }}>赠送特征</p>
	                                    <div className='tablist'>
	                                        <div className='itee item50'>
	                                            <p className='t1'>户型竖向：</p>
	                                            <p className='t2'>{item.hxZstz.zstz_hxsx}</p>
	                                        </div>
	                                        <div className='itee item50'>
	                                            <p className='t1'>赠送比例：</p>
	                                            <p className='t2'>{item.hxZstz.zstz_zsl}</p>
	                                        </div>
	                                        <div className='itee item100'>
	                                            <p className='t1'>阳台占比：</p>
	                                            <p className='t2'>{item.hxZstz.zstz_ytzbl}</p>
	                                        </div>
	                                    </div>
	                                </div>
	
	                                {/* 敏感特征 */}
	                                <div className='row1'>
	                                    <p className='titt' style={{ background: '#7DD3C6' }}>敏感特征</p>
	                                    <div className='tablist'>
	                                        <div className='itee item50'>
	                                            <p className='t1'>面宽数量：</p>
	                                            <p className='t2'>{item.hxMgtz.mgtz_mks}</p>
	                                        </div>
	                                        <div className='itee item50'>
	                                            <p className='t1'>玄关类型：</p>
	                                            <p className='t2'>{item.hxMgtz.mgtz_xg}</p>
	                                        </div>
	                                        <div className='itee item50'>
	                                            <p className='t1'>空调机位：</p>
	                                            <p className='t2'>{item.hxMgtz.mgtz_ktjw}</p>
	                                        </div>
	                                        <div className='itee item50'>
	                                            <p className='t1'>主卧朝向：</p>
	                                            <p className='t2'>{item.hxMgtz.mgtz_zwcx}</p>
	                                        </div>
	                                    </div>
	                                </div>
	
	                            </div>
	                        </div>
	                    )
	                })
	
	            }
	
				
	
	        </React.Fragment>
	    )
	}
	
}