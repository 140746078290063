import React from 'react';
import { Modal, Input, Select, message, Progress, Pagination, Button } from 'antd';
import { ArrowUpOutlined ,
    CaretUpOutlined,
    CaretDownOutlined,
    ArrowDownOutlined} from '@ant-design/icons';

import banner from '../../imgs/banner.png'
import add from '../../imgs/add.png'
import close from '../../imgs/close.png'
import sx from '../../imgs/sx.png'
import search from '../../imgs/search1.png'

import List from './List';

const { TextArea } = Input;
const { Option } = Select;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            size: 12,
            total: 0,
            data: [],//作品展示
            works: [],//精选作品
            visible: false,
            lxhsNum: '',
            keyword: [],//关键词参数
            searchKeyword: "",
            names: [],//关键词
            keywords: "",//筛选时时关键词
            province: [],//目标省份
            city: [],//目标城市
            kfdw: [],//开发单位
            rzyq: [],//日照要求
            dx: [],//适用地形
			
            cpyt: [],//产品业态
            cssx: [],//层数上线
            jfzc: [],//交付支持
			cpdw:[],//产品定位
            hxtlx: [],//核心筒位置
            ldtgx: [],//楼电梯关系
            mc: [],//核心筒明窗

            bzcxz: [],//楼型形状
            btzh: [],//板塔关系
            pjgx: [],//拼接支持

            cfbj: [],//厨房布局
            ytgl: [],//阳台关联
            dlzxcf: [],//中西分厨

            //客厅特征
            ktzx: [],//客厅轴向
            dt: [],//端厅配置
            dlzxct: [],//中西分餐

            //卧室1特征
            dlymj: [],//衣帽间类型
            hwsj: [],//独卫配置
            wsjbj: [],//卫生间布局
            wsjgsfl: [],//干湿分离
            cjgnfjct: [],//房间串套
            pc: [],//飘窗赠送

            //公卫特征
            gwwsjbj: [],//公卫特征-卫生间布局
            gwwsjgsfl: [],//公卫特征-干湿分离
            gwcjgnfrk: [],//公卫特征-干区合用

            //赠送特征
            hxsx: [],//赠送特征-户型竖向

            //敏感特征
            mgktjw: [],//敏感特征-空调机位
            mgxg: [],//敏感特征-玄关类型
            mgzwcx: [],//敏感特征-主卧朝向

            //标准参数
            gfxfsj: [],//规范特征-消防设计规范
            gfmjjs: [],//规范特征-面积计算规范

            tzsd: [],//成果特征-面世年度
            tzxx: [],//成果特征-市场响应
            docNo: "", //编号搜素参数
            title: "", //名称搜索参数
            titleYT:'',
            titleTS:'',
            titleHS:'',
            titleXTHS:'',
            titleBH:'',
            titleCS:'',
            show111:true,
            defaultName:"作品展示",
            active: 1,
            orderBy: 0,
            yt:[],
            cs:[],
            hxmjArr:['A','B','C','D','E','F','G','H','I','J'],
            xhhs_1:'',
            hxmjd_1:'',
            xhhs_2:'',
            hxmjd_2:'',
            xhhs_3:'',
            hxmjd_3:'',
            xhhs_4:'',
            hxmjd_4:'',
            xhhs_5:'',
            hxmjd_5:'',
            xhhs_6:'',
            hxmjd_6:'',
            orderSort : 1,
            numRank: 1,
            commentRank: 1,
            compreRank:1,
            profuctRank: 1,
            priceRank: 1,
            activeTimeRank: 2,
            recommend:[],
            retrieval:'',
            sreachAll: {},
        }
    }
    checkXhhs(value){
        if (value) {
            if (Number(this.state.xhhs_1)+Number(this.state.xhhs_2)+Number(this.state.xhhs_3)+Number(this.state.xhhs_4)+Number(this.state.xhhs_5)+Number(this.state.xhhs_6)>Number(this.state.titleHS)) {
                message.error('户数之和与总户数必须一致')
            }
            
        }
    }
    componentDidMount() {
        document.title = '产品'
        this.getData();
        this.works();
        //筛选参数
        this.parameters();
        this.keyword();
        this.recommendedSearch();
    }
    change(key, value) {
        if (!value) {
            this.setState({
                [key]: '',
            }, () => {
                // console.log(this.state)
            })
        } else {
            if (key == 'proviceId') {
                value--;
                var city = this.state.province[value].city;
                this.setState({
                    city: city,
                })
                value++;
            }
            this.setState({
                [key]: value,
            }, () => {
                // console.log(this.state)
            })
        }


    }

    change1(key,index, value){
        if(!value){
            this.setState({
                [key]:'',
            }, () => {
            })
        }else{
            if(!this.state[key]){
                this.state[key]=new Array()
            }
            this.state[key][index]=value
            this.setState({
                [key]: this.state[key]
            }, () => {
            })
        }

    }

    inputOnchange(key, e) {
        console.log(e.target.value.match(/^\d*(\.?\d{0,2})/g)[0])
        this.setState({
            // [key]: e.target.value
			[key]: e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]
        })
    }
    getData() {
        var req = {
            page: this.state.page,
            size: 12,
        }
        global.Ajax.hhtc_request('home/works', req).then(res => {
            if (res.code == 1) {
                this.setState({
                    data: res.data.data,
                    total: res.data.total
                })
            } else {
                message.error(res.msg)
            }
        })
    }
    recommendedSearch(){
        global.Ajax.hhtc_request('home/recommendedSearch').then(res => {
            if (res.code == 1) {
                this.setState({
                    recommend: res.data.list,
                    retrieval: res.data.retrieval,
                })
                console.log(res.data);
            } else {
                message.error(res.msg)
            }
        })
    }
    //筛选参数
    parameters() {
        global.Ajax.hhtc_request('home/screen').then(res => {
            if (res.code == 1) {
                this.setState({

                    province: res.data.comprehensive_dl.province,//目标省份
                    kfdw: res.data.comprehensive_dl.comprehensive_dl_kfdw,//目标城市
                    rzyq: res.data.comprehensive_dl.comprehensive_dl_rzyq,//日照要求
                    dx: res.data.comprehensive_dl.comprehensive_dl_dx,//适用地形
                    cpyt: res.data.comprehensive_gj.comprehensive_gj_cpyt,//产品业态
                    cssx: res.data.comprehensive_gj.comprehensive_gj_cssx,//适用层数
                    jfzc: res.data.comprehensive_gj.comprehensive_gj_jfzc,//交付支持
					cpdw: res.data.comprehensive_gj.comprehensive_gj_cpdw,//产品定位

                    hxtlx: res.data.building_hxt.building_hxt_lx,//核心筒位置
                    ldtgx: res.data.building_hxt.building_hxt_ldt,//楼电梯关系
                    mc: res.data.building_hxt.building_hxt_mc,//核心筒明窗

                    // 拓展特性
                    bzcxz: res.data.building_tz.building_tz_bzc,//楼型形状
                    btzh: res.data.building_tz.building_tz_bt,//板塔关系
                    pjgx: res.data.building_tz.building_tz_pj,//拼接支持

                    //building_cf 厨房布局
                    cfbj: res.data.building_cf.building_cf_cf,//厨房布局  building_cf_cf
                    ytgl: res.data.building_cf.building_cf_yt,//阳台关联  building_cf_yt
                    dlzxcf: res.data.building_cf.building_cf_dl,//中西分厨  building_cf_dl

                    //building_kt
                    ktzx: res.data.building_kt.building_kt_kt,//ktzx
                    dt: res.data.building_kt.building_kt_dt,//端厅配置
                    dlzxct: res.data.building_kt.building_kt_dl,//

                    //building_ws
                    dlymj: res.data.building_ws.building_ws_dlj,//衣帽间类型
                    hwsj: res.data.building_ws.building_ws_hwsj,//独卫配置
                    wsjbj: res.data.building_ws.building_ws_bj,//卫生间布局
                    wsjgsfl: res.data.building_ws.building_ws_gsfl,//干湿分离
                    cjgnfjct: res.data.building_ws.building_ws_fjct,//房间串套
                    pc: res.data.building_ws.building_ws_pc,//飘窗赠送

                    //公卫特征
                    gwwsjbj: res.data.building_ws.building_ws_wsjbj,//卫生间布局
                    gwwsjgsfl: res.data.building_ws.building_ws_wsjfsfl,//干湿分离
                    gwcjgnfrk: res.data.building_ws.building_ws_rkhy,//干区合用

                    //赠送特征
                    hxsx: res.data.building_zs.building_zs_hxsx,//赠送特征-户型竖向

                    //敏感特征
                    mgktjw: res.data.building_zs.building_zs_ktjw,//敏感特征-空调机位
                    mgxg: res.data.building_zs.building_zs_xg,//敏感特征-玄关类型
                    mgzwcx: res.data.building_zs.building_zs_zwcx,//敏感特征-主卧朝向

                    //标准参数
                    gfxfsj: res.data.standard_gf.standard_gf_xfsj,//规范特征-消防设计规范
                    gfmjjs: res.data.standard_gf.standard_gf_mjjs,//规范特征-面积计算规范

                    tzsd: res.data.standard_gf.standard_gf_tzsd,//成果特征-面世年度
                    tzxx: res.data.standard_gf.standard_gf_tzxx,//成果特征-市场响应

                })
			} else {
                message.error(res.msg)
            }
        })
        global.Ajax.hhtc_request('Product/TitleKeyword').then(res=>{
            if (res.code==1) {
                this.setState({
                    yt:res.data.params,
                    cs:res.data.type,
                })
            }
        })
    }
    //关键词
    keyword() {
        if (this.props.match.params.id == 2) {
            this.setState({
                visible: true
            })
        }

        global.Ajax.hhtc_request('home/keyword').then(res => {
            if (res.code == 1) {
                this.setState({
                    keyword: res.data,
                })
            } else {
                message.error(res.msg, 2)
            }
        })
    }
    getIndex(i){
        const index={
            1:'①',
            2:'②',
            3:'③',
            4:'④',
            5:'⑤',
            6:'⑥',
            7:'⑦',
            8:'⑧',
            9:'⑨',
        }
        return index[i]
    }
    inputOnchange1(key,index,e) {
        if(!this.state[key]){
            this.state[key]=new Array()
        }
        this.state[key][index]= e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]
        this.setState({
            [key]: this.state[key]
        })
    }
    //评论关键字处理
    clickKeyWord(index, name) {
        var names = this.state.names;
        var keyWord = this.state.keyword
        if (keyWord[index].status == 0) {
            keyWord[index].status = 1;
            names.push(keyWord[index].name);
        } else {
            keyWord[index].status = 0;
            var indexs = names.findIndex(key => key == keyWord[index].name)
            names.splice(indexs, 1)
        }
        this.setState({
            keyWord: keyWord,
            names: names,
            keywords: names.join(','),
        })
    }
    works() {
        global.Ajax.hhtc_request('home/selectedWorks').then(res => {
            if (res.code == 1) {
                this.setState({
                    works: res.data,
                })
            } else {
                message.error(res.msg)
            }
        })
    }

    render() {
        const icon = (
            <span className='xsj'></span>
        )
        var listM = (res) => {
            var data = []
            for (var i = 1; i < res; i++) {
                data.push(<List index={i} ref={"list" + i} key={'' + i} wsNum='' value={this.state.sreachAll}/>)
            }
            return data
        }

        //卧室特征
        var list = (res) => {
            var data = []
            for (var i = 0; i < res; i++) {
                data.push(
                    <div className='row1' key={'' + i}>
                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>卧室{this.getIndex(i + 1)}特征</p>
                        <div className='tablist'>
                            <div className='itee item50'>
                                <p className='t1'>卧室进深：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_wsjs_min ===undefined ? null : this.state.cs_wsjs_min[i]} onChange={this.inputOnchange1.bind(this,'cs_wsjs_min',i)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_wsjs_max ===undefined ? null : this.state.cs_wsjs_max[i]} onChange={this.inputOnchange1.bind(this,'cs_wsjs_max',i)} />
                                </p>
                            </div>
                            <div className='itee item50'>
                                <p className='t1'>卧室面宽：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_wsmk_min ===undefined ? null : this.state.cs_wsmk_min[i]} onChange={this.inputOnchange1.bind(this, 'cs_wsmk_min',i)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_wsmk_max ===undefined ? null : this.state.cs_wsmk_max[i]} onChange={this.inputOnchange1.bind(this, 'cs_wsmk_max',i)} />
                                </p>
                            </div>
                            <div className='itee item50' id='dlymj'>
                                <p className='t1'>衣帽间类型：</p>
                                <Select
                                    value={this.state.cs_ws_dlymj ===undefined ? null : this.state.cs_ws_dlymj[i]}
                                    getPopupContainer={()=>document.getElementById('dlymj')}
                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_ws_dlymj',i)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.dlymj.map((item, index) => (
                                        <Option key={'dlymj' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item50 mar_bot' id='cjgnfjct'>
                                <p className='t1'>房间串套：</p>
                                <Select
                                    value={this.state.cs_ws_cjgnfjct ===undefined ? null : this.state.cs_ws_cjgnfjct[i]}
                                    getPopupContainer={()=>document.getElementById('cjgnfjct')}
                                    placeholder='全部' className='t2' suffixIcon={icon}
                                    onChange={this.change1.bind(this, 'cs_ws_cjgnfjct',i)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.cjgnfjct.map((item, index) => (
                                        <Option key={'cjgnfjct' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item50' id='hwsj'>
                                <p className='t1'>独卫配置：</p>
                                <Select
                                    value={this.state.cs_ws_hwsj ===undefined ? null : this.state.cs_ws_hwsj[i]}
                                    getPopupContainer={()=>document.getElementById('hwsj')}
                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_ws_hwsj',i)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.hwsj.map((item, index) => (
                                        <Option key={'hwsj' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item50 mar_bot' id='pc'>
                                <p className='t1'>飘窗赠送：</p>
                                <Select
                                    value={this.state.cs_ws_pc ===undefined ? null : this.state.cs_ws_pc[i]}
                                    getPopupContainer={()=>document.getElementById('pc')}
                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_ws_pc',i)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.pc.map((item, index) => (
                                        <Option key={'pc' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )
            }
            return data
        }
        //厨房特征
        var list1 = (res) => {
            var data = []
            for (var a = 0; a < res; a++) {
                data.push(
                    <div className='row1' key={'' + a}>
                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>厨房{this.getIndex(a + 1)}特征</p>
                        <div className='tablist'>
                            <div className='itee item50' id='cfbj'>
                                <p className='t1'>厨房布局：</p>
                                <Select
                                    value={this.state.cs_cf_cfbj ===undefined ? null : this.state.cs_cf_cfbj[a]}
                                    getPopupContainer={()=>document.getElementById('cfbj')}
                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_cf_cfbj',a)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.cfbj.map((item, index) => (
                                        <Option key={'cfbj' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item50' id='ytgl'>
                                <p className='t1'>阳台关联：</p>
                                <Select
                                        value={this.state.cs_cf_ytgl ===undefined ? null : this.state.cs_cf_ytgl[a]}
                                    getPopupContainer={()=>document.getElementById('ytgl')}
                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_cf_ytgl',a)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.ytgl.map((item, index) => (
                                        <Option key={'ytgl' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item50'>
                                <p className='t1'>厨房面宽：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_cf_cfmk_min ===undefined ? null : this.state.cs_cf_cfmk_min[a]}  onChange={this.inputOnchange1.bind(this, 'cs_cf_cfmk_min',a)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-'  value={this.state.cs_cf_cfmk_max ===undefined ? null : this.state.cs_cf_cfmk_max[a]}  onChange={this.inputOnchange1.bind(this, 'cs_cf_cfmk_max',a)} />
                                </p>
                            </div>
                            <div className='itee item50'>
                                <p className='t1'>厨房进深：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_cf_cfjs_min ===undefined ? null : this.state.cs_cf_cfjs_min[a]}  onChange={this.inputOnchange1.bind(this, 'cs_cf_cfjs_min',a)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_cf_cfjs_max ===undefined ? null : this.state.cs_cf_cfjs_max[a]}  onChange={this.inputOnchange1.bind(this, 'cs_cf_cfjs_max',a)} />
                                </p>
                            </div>
                            <div className='itee item100' id='dlzxcf'>
                                <p className='t1'>中西分厨：</p>
                                <Select
                                    value={this.state.cs_cf_dlzxcf ===undefined ? null : this.state.cs_cf_dlzxcf[a]}
                                    getPopupContainer={()=>document.getElementById('dlzxcf')}
                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_cf_dlzxcf',a)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.dlzxcf.map((item, index) => (
                                        <Option key={'dlzxcf' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )
            }
            return data
        }

        //客厅特征
        var list2 = (res) => {
            var data = []
            for (var b = 0; b < res; b++) {
                data.push(
                    <div className='row1' key={'' + b}>
                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>客厅{this.getIndex(b + 1)}特征</p>
                        <div className='tablist'>
                            <div className='itee item50'>
                                <p className='t1'>客厅进深：</p>
                                <p className='t2'>
                                    <input placeholder='-'  value={this.state.cs_kt_ktjs_min ===undefined ? null : this.state.cs_kt_ktjs_min[b]}  onChange={this.inputOnchange1.bind(this, 'cs_kt_ktjs_min',b)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-'  value={this.state.cs_kt_ktjs_max ===undefined ? null : this.state.cs_kt_ktjs_max[b]}  onChange={this.inputOnchange1.bind(this, 'cs_kt_ktjs_max',b)} />
                                </p>
                            </div>
                            <div className='itee item50'>
                                <p className='t1'>客厅面宽：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_kt_ktmk_min ===undefined ? null : this.state.cs_kt_ktmk_min[b]} onChange={this.inputOnchange1.bind(this, 'cs_kt_ktmk_min',b)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_kt_ktmk_max ===undefined ? null : this.state.cs_kt_ktmk_max[b]} onChange={this.inputOnchange1.bind(this, 'cs_kt_ktmk_max',b)} />
                                </p>
                            </div>
                            <div className='itee item100 mar_bot10' id='ktzx'>
                                <p className='t1'>客厅轴向：</p>
                                <Select
                                    value={this.state.cs_kt_ktzx ===undefined ? null : this.state.cs_kt_ktzx[b]}
                                    getPopupContainer={()=>document.getElementById('ktzx')}
                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_kt_ktzx',b)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.ktzx.map((item, index) => (
                                        <Option key={'ktzx' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item100 mar_bot' id='dt11'>
                                <p className='t1'>端厅配置：</p>
                                <Select
                                    value={this.state.cs_kt_dt ===undefined ? null : this.state.cs_kt_dt[b]}
                                    getPopupContainer={()=>document.getElementById('dt11')}
                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_kt_dt',b)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.dt.map((item, index) => (
                                        <Option key={'dt' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )

            }
            return data
        }

        //餐厅特征
        var list3 = (res) => {
            var data = []
            for (var c = 0; c < res; c++) {
                data.push(
                    <div className='row1' key={'' + c}>
                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>餐厅{this.getIndex(c + 1)}特征</p>
                        <div className='tablist'>
                            <div className='itee item50'>
                                <p className='t1'>餐厅进深：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_ct_ctjs_min ===undefined ? null : this.state.cs_ct_ctjs_min[c]} onChange={this.inputOnchange1.bind(this, 'cs_ct_ctjs_min',c)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_ct_ctjs_max ===undefined ? null : this.state.cs_ct_ctjs_max[c]} onChange={this.inputOnchange1.bind(this, 'cs_ct_ctjs_max',c)} />
                                </p>
                            </div>
                            <div className='itee item50'>
                                <p className='t1'>餐厅面宽：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_ct_ctmk_min ===undefined ? null : this.state.cs_ct_ctmk_min[c]} onChange={this.inputOnchange1.bind(this, 'cs_ct_ctmk_min',c)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_ct_ctmk_max ===undefined ? null : this.state.cs_ct_ctmk_max[c]} onChange={this.inputOnchange1.bind(this, 'cs_ct_ctmk_max',c)} />
                                </p>
                            </div>
                            <div className='itee item100' id='dlzxct11'>
                                <p className='t1'>中西分餐：</p>
                                <Select
                                    value={this.state.cs_ct_dlzxct ===undefined ? null : this.state.cs_ct_dlzxct[c]}
                                    getPopupContainer={()=>document.getElementById('dlzxct11')}
                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_ct_dlzxct',c)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.dlzxct.map((item, index) => (
                                        <Option key={'dlzxct' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )

            }
            return data
        }

        //卫生间
        var list4 = (res) => {
            var data = []
            for (var d = 0; d < res; d++) {
                data.push(
                    <div className='row1' key={'' + d}>
                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>卫{this.getIndex(d + 1)}特征</p>
                        <div className='tablist'>
                            <div className='itee item50'>
                                <p className='t1'>卫生间面宽：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_gw_wsjmk_min ===undefined ? null : this.state.cs_gw_wsjmk_min[d]} onChange={this.inputOnchange1.bind(this, 'cs_gw_wsjmk_min',d)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_gw_wsjmk_max ===undefined ? null : this.state.cs_gw_wsjmk_max[d]} onChange={this.inputOnchange1.bind(this, 'cs_gw_wsjmk_max',d)} />
                                </p>
                            </div>

                            <div className='itee item50'>
                                <p className='t1'>卫生间进深：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_gw_wsjjs_min ===undefined ? null : this.state.cs_gw_wsjjs_min[d]} onChange={this.inputOnchange1.bind(this, 'cs_gw_wsjjs_min',d)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_gw_wsjjs_max ===undefined ? null : this.state.cs_gw_wsjjs_max[d]} onChange={this.inputOnchange1.bind(this, 'cs_gw_wsjjs_max',d)} />
                                </p>
                            </div>

                            <div className='itee item50 mar_bot10' id='wsjbj11'>
                                <p className='t1'>卫生间布局：</p>
                                <Select
                                        value={this.state.cs_gw_wsjbj ===undefined ? null : this.state.cs_gw_wsjbj[d]}
                                    getPopupContainer={()=>document.getElementById('wsjbj11')}
                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_gw_wsjbj',d)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.gwwsjbj.map((item, index) => (
                                        <Option key={'gwwsjbj' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>

                            <div className='itee item50 mar_bot10' id='wsjgsfl111'>
                                <p className='t1'>干湿分离：</p>
                                <Select
                                    value={this.state.cs_gw_wsjgsfl ===undefined ? null : this.state.cs_gw_wsjgsfl[d]}
                                    getPopupContainer={()=>document.getElementById('wsjgsfl111')}
                                    placeholder='全部' className='t2' suffixIcon={icon}
                                    onChange={this.change1.bind(this, 'cs_gw_wsjgsfl',d)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.gwwsjgsfl.map((item, index) => (
                                        <Option key={'gwwsjgsfl' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>

                            <div className='itee item100' id='cjgnrkhy'>
                                <p className='t1'>干区合用：</p>
                                <Select
                                    value={this.state.cs_gw_cjgnf ===undefined ? null : this.state.cs_gw_cjgnf[d]}
                                    getPopupContainer={()=>document.getElementById('cjgnrkhy')}
                                    placeholder='全部' className='t2' suffixIcon={icon}
                                    onChange={this.change1.bind(this, 'cs_gw_cjgnf',d)}>
                                    <Option value={'-1'}>全部</Option>
                                    {this.state.gwcjgnfrk.map((item, index) => (
                                        <Option key={'gwcjgnfrk' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )

            }
            return data
        }
        // var list1=(res)
        return (
            <div className='padd_top66'>
                {/* menu */}
                <div className='menubox' style={{ marginBottom: 36 }}>
                    <div className='w1400'>
                        <p>首页</p>
                        <p>产品</p>
                    </div>
                </div>
                {/* 搜索框 */}
                <div className='searchBox w1400' onClick={() => {
                    this.setState({
                        visible: true
                    })
                }}>
                    <span></span>
                    <p>搜索作品</p>
                </div>
                {/* 精选作品 */}
                <div className='pjList w1400'  style={{  display: this.state.show111?"":'none' }}>
                    <div className='titheader'>
                        <p className='tit'>精选作品</p>
                    </div>
                    <div className='box'>
                        {this.state.works.map((item1, index1) => (
                            <div className='item' key={'zz' + index1} onClick={() => {
								window.open('#/rout/ProductDetail/'+ item1.id)
                            }}>
                                <div className='picbox'>
                                    <img alt='' src={item1.thumb} className='pic' />
                                    <div className='massk'>
                                        <img alt='' src={search} />
                                    </div>
                                </div>
                                <div className='flexw'>
                                    {
                                        item1.label.map((item2, index2) => {
                                            return (
                                                <p key={'lable1' + index2}>{item2}</p>
                                            )
                                        })
                                    }
                                </div>
                                <div className='uinfo'>
                                    <div className='lbox'>
                                        <h1 className='title'>{item1.title}</h1>
                                        <p>By {item1.nickname}</p>
                                    </div>
                                    <img alt='' src={item1.headImg} className='avatar' />
                                </div>
                                <div className='llscbox'>
                                    <p>浏览：{item1.pvNum}</p>
                                    <p>收藏：{item1.collectionNum}</p>
                                    <p>下载：{item1.freeDownNum}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* 作品展示 */}
                <div className='pjList w1400'>
                <div className='titheader'>
                        <p className='tit'>{this.state.defaultName}</p>
                        <p className='tit1'>
                            <Button className={ this.state.orderBy===1 ? 'activeBtn' : 'searchBtn'} onClick={()=>{this.setState({orderBy: 1},()=>this.ok())}}>浏览量
                                {this.state.compreRank===1 ? 
                                <ArrowUpOutlined onClick={(e)=>{this.setState({compreRank: 0,}); } }/> : 
                                <ArrowDownOutlined onClick={(e)=>{this.setState({compreRank: 1});}}/>}
                            </Button>
                            <Button className={ this.state.orderBy===2 ? 'activeBtn' : 'searchBtn'}  onClick={()=>{this.setState({orderBy: 2},()=>this.ok())}}>收藏量
                                {this.state.numRank===1 ? 
                                <ArrowUpOutlined onClick={(e)=>{this.setState({numRank: 0,}); }}/> : 
                                <ArrowDownOutlined onClick={(e)=>{this.setState({numRank: 1})}}/>}
                            </Button>
                            <Button className={ this.state.orderBy===3 ? 'activeBtn' : 'searchBtn'}   onClick={(e)=>{this.setState({orderBy: 3},()=>this.ok()); }}>下载量
                                {this.state.commentRank===1 ? 
                                <ArrowUpOutlined onClick={(e)=>{this.setState({commentRank: 0,})}}/> : 
                                <ArrowDownOutlined onClick={(e)=>{this.setState({commentRank: 1})}}/>}
                            </Button>
                            <Button className={ this.state.orderBy===4 ? 'activeBtn' : 'searchBtn'}  onClick={(e)=>{this.setState({orderBy: 4},()=>this.ok());}}>评分
                                {this.state.profuctRank===1 ? 
                                <ArrowUpOutlined onClick={(e)=>{this.setState({profuctRank: 0,});}}/> : 
                                <ArrowDownOutlined onClick={(e)=>{this.setState({profuctRank: 1});}}/>}
                            </Button>
                            <Button className={ this.state.orderBy===5 ? 'activeBtn' : 'searchBtn'}   onClick={()=>{this.setState({orderBy: 5},()=>this.ok())}}>上传时间 
                                {this.state.priceRank ===1?
                                    <ArrowUpOutlined  onClick={(e)=>{this.setState({priceRank: 0});}}/>:
                                    <ArrowDownOutlined  onClick={(e)=>{this.setState({priceRank: 1});}}/>}
                            </Button>
                        {/* <Select className='select'
                                placeholder='排序方式' suffixIcon={icon} onChange={(e)=>{this.setState({orderBy: e},()=>this.ok())}}>
                                <Option value={1}>浏览量</Option>
                                <Option value={2}>收藏量</Option>
                                <Option value={3}>下载量 </Option>
                                <Option value={4}>评分</Option>
                                <Option value={5}>上传时间</Option>
                        </Select> */}
                        </p>
                        
                    </div>
                    <div className='box'>
                        {
                            this.state.data.map((item3, index3) => (
                                <div className='item' key={'za' + index3} onClick={() => {
                                    window.open('#/rout/ProductDetail/' + item3.id)
                                    // this.props.history.push('/rout/ProductDetail/' + item3.id)
                                }}>
                                    <div className='picbox'>
                                        <img alt='' src={item3.thumb} className='pic' />
                                        <div className='massk'>
                                            <img alt='' src={search} />
                                        </div>
                                    </div>
                                    <div className='flexw'>
                                        {
                                            item3.label.map((item4, index4) => {
                                                return (
                                                    <p key={'labels' + index4}>{item4}</p>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='uinfo'>
                                        <div className='lbox'>
                                            <h1 className='title'>{item3.title}</h1>
                                            <p>By {item3.nickname}</p>
                                        </div>
                                        <img alt='' src={item3.headImg} className='avatar' />
                                    </div>
                                    <div className='llscbox'>
                                        <p>浏览：{item3.pvNum}</p>
                                        <p>收藏：{item3.collectionNum}</p>
                                        <p>下载：{item3.downNum}</p>
                                    </div>
                                </div>
                            ))}
                    </div>
                        <div className='sdxcv'>

                            {
                                this.state.total>0&&<Pagination current={this.state.page} pageSize={this.state.size} total={this.state.total} onChange={(index, i) => {
                                    this.setState({
                                        page: index,
                                    }, () => {
                                        this.ok()
                                    })
                                }} />
                                
                            }

                        </div>
                </div>
                {/* 筛选弹窗 */}
                <Modal
                    title=''
                    footer={false}
                    width={1400}
                    visible={this.state.visible}
                    closable={false}
                    className='sxmaskbox'
                    destroyOnClose={true}
                >
                    <div className='tflex'>
                        <p className='tits' >筛选搜索</p>
                        <p className={this.state.active===1 && "active"} onClick={()=>{this.setState({ active:1})}}>精准搜索</p>
                        <p className={this.state.active===2 && "active"} onClick={()=>{this.setState({ active:2})}}>标题搜索</p>
                        <p className={this.state.active===3 && "active"} onClick={()=>{this.setState({ active:3})}}>编号搜索</p>
                        <p className={this.state.active===4 && "active"} onClick={()=>{this.setState({ active:4})}}>模糊搜索</p>
                        <img alt='' src={close} className='closebtn' onClick={() => {
                            this.setState({
                                visible: false
                            })
                        }} />
                    </div>
                    {this.state.active===1 && <div className='ovauto'>
                        <div className='flex1'>
                            <p className='h2'>关键参数</p>
                            <p className='h3'>关键词</p>
                            <div className='flexwalist'>
                                {

                                    this.state.keyword.map((item5, index5) => {
                                        if (item5.status == 0) {
                                            return (
                                                <p key={'labeld' + index5} onClick={
                                                    this.clickKeyWord.bind(this, index5, item5.name)
                                                }>{item5.name}</p>
                                            )
                                        } else {
                                            return (
                                                <p key={'labelf' + index5} className='active' onClick={
                                                    this.clickKeyWord.bind(this, index5, "")
                                                } >{item5.name}</p>
                                            )
                                        }
                                    })

                                }

                            </div>
                        </div>
                        <div className='tabble tabble1'>
                            <h2>综合参数</h2>
                            <div className='col2'>
                                <div className='row1'>
                                    <p className='titt'>地理特征</p>
                                    <div className='tablist'>
                                        <div className='itee item50' id='area'>
                                            <p className='t1'>目标省份：</p>
                                            <Select
                                            value={this.state.proviceId}
                                                getPopupContainer={()=>document.getElementById('area')}
                                                placeholder='全部' className='t2' suffixIcon={icon} onChange={
                                                this.change.bind(this, 'proviceId')
                                            }>
                                                <Option value={''}>全部</Option>
                                                {this.state.province.map((item6, index6) => (
                                                    <Option key={'provinceg' + index6} value={item6.id}>{item6.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='itee item50' id='city'>
                                            <p className='t1'>目标城市：</p>
                                            <Select
                                            value={this.state.cityId}
                                                getPopupContainer={()=>document.getElementById('city')}
                                                placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cityId')}>
                                                <Option value={''}>全部</Option>
                                                {this.state.city.map((item7, index7) => (
                                                    <Option key={'cityh' + index7} value={item7.id}>{item7.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='itee item50' id='unit'>
                                            <p className='t1'>对标企业：</p>
                                            <Select 
                                            value={this.state.cs_kfdw}
                                            getPopupContainer={()=>document.getElementById('unit')}
                                            placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_kfdw')}>
                                                <Option value={''}>全部</Option>
                                                {this.state.kfdw.map((item8, index8) => (
                                                    <Option key={'kfdwj' + index8} value={item8.id}>{item8.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='itee item50' id='Require'>
                                            <p className='t1'>日照要求：</p>
                                            <Select 
                                            value={this.state.cs_rzyq}
                                            getPopupContainer={()=>document.getElementById('Require')}
                                            placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_rzyq')}>
                                                <Option value={''}>全部</Option>
                                                {this.state.rzyq.map((item9, index9) => (
                                                    <Option key={'rzyq' + index9} value={item9.id}>{item9.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='itee item100' id='ds1'>
                                            <p className='t1'>适用地形：</p>
                                            <Select 
                                            value={this.state.cs_dx}
                                            getPopupContainer={()=>document.getElementById('ds1')}
                                            placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_dx')}>
                                                <Option value={''}>全部</Option>
                                                {this.state.dx.map((item10, index10) => (
                                                    <Option key={'dxj' + index10} value={item10.id}>{item10.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row1'>
                                    <p className='titt'>关键特征</p>
                                    <div className='tablist'>
                                        <div className='itee item50' id='cpyt1'>
                                            <p className='t1'>产品业态：</p>
                                            <Select 
                                            getPopupContainer={()=>document.getElementById('cpyt1')}
                                            value={this.state.cs_gj_cpyt}
                                            placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_gj_cpyt')}>
                                                <Option value={''}>全部</Option>
                                                {this.state.cpyt.map((item11, index11) => (
                                                    <Option key={'cpytj' + index11} value={item11.id}>{item11.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
										
                                        <div className='itee item50' id='cssx'>
                                            <p className='t1'>适用层数：</p>
                                            <Select 
                                            value={this.state.cs_gj_cssx}
                                            getPopupContainer={()=>document.getElementById('cssx')}
                                            placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_gj_cssx')}>
                                                <Option value={''}>全部</Option>
                                                {this.state.cssx.map((item12, index12) => (
                                                    <Option key={'cssxa' + index12} value={item12.id}>{item12.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
										
                                        <div className='itee item50' id='jfzc'>
                                            <p className='t1'>交付支持：</p>
                                            <Select 
                                            value={this.state.cs_gj_jfzc}
                                            getPopupContainer={()=>document.getElementById('jfzc')}
                                            placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_gj_jfzc')}>
                                                <Option value={''}>全部</Option>
                                                {this.state.jfzc.map((item13, index13) => (
                                                    <Option key={'jfzcq' + index13} value={item13.id}>{item13.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
										
                                        <div className='itee item50' id='cpdw'>
                                            <p className='t1'>产品定位：</p>
                                            <Select
                                            value={this.state.cs_gj_cpdw}
                                            getPopupContainer={()=>document.getElementById('cpdw')}
                                            placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_gj_cpdw')}>
                                                <Option value={''}>全部</Option>
                                                {this.state.cpdw.map((item131, index131) => (
                                                    <Option key={'cpdwq' + index131} value={item131.name}>{item131.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
										
                                        <div className='itee item100'>
                                            <p className='t1'>容积率值：</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_gj_cpjxrjl_min} onChange={this.inputOnchange.bind(this, 'cs_gj_cpjxrjl_min')} />
                                            </p>
                                            <p className='t1 mar_10'>至</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_gj_cpjxrjl_max} onChange={this.inputOnchange.bind(this, 'cs_gj_cpjxrjl_max')} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
						
						<div className='tabble tabble1'>
						    <h2 style={{ backgroundColor: "#4dbdb3" }}>限定参数</h2>
						    <div className='col2'>
						        <div className='row1'>
						            <p className='titt' style={{ backgroundColor: "#4dbdb3" }}>规范特征</p>
						            <div className='tablist'>
						                <div className='itee item100 mar_bot10'  id='xfsjgf'>
						                    <p className='t1'>消防设计规范：</p>
						                    <Select 
                                            value={this.state.cs_gf_xfsj}
						                     getPopupContainer={()=>document.getElementById('xfsjgf')}
						                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_gf_xfsj')}>
						                        <Option value={''}>全部</Option>
						                        {this.state.gfxfsj.map((item19, index19) => (
						                            <Option key={'gfxfsjf' + index19} value={item19.id}>{item19.name}</Option>
						                        ))}
						                    </Select>
						                </div>
						                <div className='itee item100 '  id='mjjsgf'>
						                    <p className='t1'>面积计算规范：</p>
						                    <Select 
                                            value={this.state.cs_gf_mjjs}
						                     getPopupContainer={()=>document.getElementById('mjjsgf')}
						                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_gf_mjjs')}>
						                        <Option value={''}>全部</Option>
						                        {this.state.gfmjjs.map((item20, index20) => (
						                            <Option key={'gfmjjgs' + index20} value={item20.id}>{item20.name}</Option>
						                        ))}
						                    </Select>
						                </div>
						            </div>
						        </div>
						        <div className='row1'>
						            <p className='titt' style={{ backgroundColor: "#4dbdb3" }}>市场特征</p>
						            <div className='tablist'>
						                <div className='itee item100 mar_bot10'  id='tzsd'>
						                    <p className='t1' >面世年度：</p>
						                    <Select 
                                            value={this.state.cs_tzsd}
						                     getPopupContainer={()=>document.getElementById('tzsd')}
						                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_tzsd')}>
						                        <Option value={''}>全部</Option>
						                        {this.state.tzsd.map((item21, index21) => (
						                            <Option key={'tzsdh' + index21} value={item21.id}>{item21.name}</Option>
						                        ))}
						                    </Select>
						                </div>
						                <div className='itee item100'  id='tzxx'>
						                    <p className='t1' >市场响应：</p>
						                    <Select 
                                            value={this.state.cs_tzxx}
						                    getPopupContainer={()=>document.getElementById('tzxx')}
						                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_tzxx')}>
						                        <Option value={''}>全部</Option>
						                        {this.state.tzxx.map((item22, index22) => (
						                            <Option key={'tzxxj' + index22} value={item22.id}>{item22.name}</Option>
						                        ))}
						                    </Select>
						                </div>
						            </div>
						        </div>
						    </div>
						</div>
						
                        <div className='tabble tabble1'>
                            <h2 style={{ backgroundColor: "#55CFCC" }}>楼型参数</h2>
                            <div className='colbox'>

                                <div className='col2'>
                                    <div className='row1'>
                                        <p className='titt' style={{ backgroundColor: "#84D3D1" }}>基本特征</p>
                                        <div className='tablist'>
                                            <div className='itee item50'>
                                                <p className='t1'>楼型面积：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_bzmj_min} onChange={this.inputOnchange.bind(this, 'cs_bzmj_min')} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_bzmj_max}  onChange={this.inputOnchange.bind(this, 'cs_bzmj_max')} />
                                                </p>
                                            </div>
                                            <div className='itee item50'>
                                                <p className='t1'>得房率值：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_dfl_min}  onChange={this.inputOnchange.bind(this, 'cs_dfl_min')} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_dfl_max}  onChange={this.inputOnchange.bind(this, 'cs_dfl_max')} />
                                                </p>
                                            </div>
                                            <div className='itee item50'>
                                                <p className='t1'>楼型面宽：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_lxmk_min}  onChange={this.inputOnchange.bind(this, 'cs_lxmk_min')} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_lxmk_max}  onChange={this.inputOnchange.bind(this, 'cs_lxmk_max')} />
                                                </p>
                                            </div>
                                            <div className='itee item50'>
                                                <p className='t1'>楼型进深：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_lxjs_min}  onChange={this.inputOnchange.bind(this, 'cs_lxjs_min')} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_lxjs_max}  onChange={this.inputOnchange.bind(this, 'cs_lxjs_max')} />
                                                </p>
                                            </div>
                                            <div className='itee item100'>
                                                <p className='t1'>楼型户数：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.lxhsNum} onChange={(e) => {
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            lxhsNum: e.target.value
                                                        })
                                                        this.inputOnchange.bind(this, 'cs_lxhs')
                                                    }} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row1'>
                                        <p className='titt' style={{ backgroundColor: "#84D3D1" }}>核心筒特征</p>
                                        <div className='tablist'>
                                            <div className='itee item50' id='hxtlx'>
                                                <p className='t1'>核心筒位置：</p>
                                                <Select 
												value={this.state.cs_hxtlx}
                                                getPopupContainer={()=>document.getElementById('hxtlx')}
                                                placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_hxtlx')}>
                                                    <Option value={''}>全部</Option>
                                                    {this.state.hxtlx.map((item14, index14) => (
                                                        <Option key={'hxtlxs' + index14} value={item14.id}>{item14.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item50' id='ldtgx'>
                                                <p className='t1'>楼电梯关系：</p>
                                                <Select 
												value={this.state.cs_ldtgx}
                                                getPopupContainer={()=>document.getElementById('ldtgx')}
                                                placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_ldtgx')}>
                                                    <Option value={''}>全部</Option>
                                                    {this.state.ldtgx.map((item15, index15) => (
                                                        <Option key={'ldtgxa' + index15} value={item15.id}>{item15.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item50' id='hxtmc'>
                                                <p className='t1'>核心筒明窗：</p>
                                                <Select 
												value={this.state.cs_hxtmc}
                                                getPopupContainer={()=>document.getElementById('hxtmc')}
                                                placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_hxtmc')}>
                                                    <Option value={''}>全部</Option>
                                                    {this.state.mc.map((item16, index16) => (
                                                        <Option key={'mca' + index16} value={item16.id}>{item16.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item50'>
                                                <p className='t1'>电梯数量：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' 	value={this.state.cs_dts} onChange={this.inputOnchange.bind(this, 'cs_dts')} />
                                                </p>
                                            </div>
                                            <div className='itee item100'>
                                                <p className='t1'>楼梯数量：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_lts} onChange={this.inputOnchange.bind(this, 'cs_lts')} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='col2'>
                                    <div className='row1'>
                                        <p className='titt' style={{ backgroundColor: "#84D3D1" }}>拓展特征</p>
                                        <div className='tablist'>
                                            <div className='itee item50' id='bzcxz'>
                                                <p className='t1'>楼型形状：</p>
                                                <Select 
                                                value={this.state.cs_bzcxz}
                                                getPopupContainer={()=>document.getElementById('bzcxz')}
                                                placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_bzcxz')}>
                                                    <Option value={''}>全部</Option>
                                                    {this.state.bzcxz.map((item23, index23) => (
                                                        <Option key={'bzcxzs' + index23} value={item23.id}>{item23.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item50' id='tbzh'>
                                                <p className='t1'>板塔关系：</p>
                                                <Select 
                                                value={this.state.cs_btzh}
                                                getPopupContainer={()=>document.getElementById('tbzh')}
                                                placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_btzh')}>
                                                    <Option value={''}>全部</Option>
                                                    {this.state.btzh.map((item17, index17) => (
                                                        <Option key={'btzhd' + index17} value={item17.id}>{item17.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item100' id='pjgx'>
                                                <p className='t1'>拼接支持：</p>
                                                <Select 
                                                value={this.state.cs_pjgx}
                                                getPopupContainer={()=>document.getElementById('pjgx')}
                                                placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_pjgx')}>
                                                    <Option value={''}>全部</Option>
                                                    {this.state.pjgx.map((item18, index18) => (
                                                        <Option key={'pjgxa' + index18} value={item18.id}>{item18.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row1'>
                                        <p className='titt' style={{ backgroundColor: "#84D3D1" }}>节能特征</p>
                                        <div className='tablist'>
                                            <div className='itee item50'>
                                                <p className='t1'>体形系数：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_txxs_min} onChange={this.inputOnchange.bind(this, 'cs_txxs_min')} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_txxs_max} onChange={this.inputOnchange.bind(this, 'cs_txxs_max')} />
                                                </p>
                                            </div>
                                            <div className='itee item50'>
                                                <p className='t1'>窗地比值：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_cdb_min} onChange={this.inputOnchange.bind(this, 'cs_cdb_min')} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_cdb_max} onChange={this.inputOnchange.bind(this, 'cs_cdb_max')} />
                                                </p>
                                            </div>
                                            <div className='itee item100'>
                                                <p className='t1'>窗墙比值：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_cqb_min} onChange={this.inputOnchange.bind(this, 'cs_cqb_min')} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_cqb_max} onChange={this.inputOnchange.bind(this, 'cs_cqb_max')} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                        <div className='tabble tabble2' ref={'list0'}>
                            <h2 style={{ backgroundColor: "#55CFBC" }}>户型{this.getIndex(this.props.index+1)}参数</h2>
                            <div className='colbox'>
                                <div className='col2'>
                                    <div className='row1'>
                                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>基本特征</p>

                                        <div className='tablist'>

                                            <div className='itee item100' style={{ marginBottom: 10, background: '#55CFBC' }}>
                                                <p className='t1 color_white'>房型配置：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_1g}  onChange={(e)=>{this.inputOnchange('cs_hx_1g',e)}} />
                                                </p>
                                                <p className='t1 mar_2'>改</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.ktNum} onChange={(e) => {
                                                        this.inputOnchange('cs_hx_2k',e)
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            ktNum: e.target.value
                                                        })
                                                    }} />
                                                </p>
                                                <p className='t1 mar_2'>客厅</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_3g} onChange={(e)=>{this.inputOnchange( 'cs_hx_3g',e)}} />
                                                </p>
                                                <p className='t1 mar_2'>改</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.ctNum} onChange={(e) => {
                                                        this.inputOnchange('cs_hx_4c',e)
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            ctNum: e.target.value
                                                        })
                                                    }} />


                                                </p>
                                                <p className='t1 mar_2'>餐厅</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_5g } onChange={(e)=>{this.inputOnchange('cs_hx_5g',e)}} />
                                                </p>
                                                <p className='t1 mar_2'>改</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.wsNum} onChange={(e) => {
                                                        this.inputOnchange('cs_hx_6w',e)
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            wsNum: e.target.value
                                                        })
                                                    }} />
                                                </p>
                                                <p className='t1 mar_2'>卧室</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_7g } onChange={(e)=>{this.inputOnchange('cs_hx_7g',e)}} />
                                                </p>
                                                <p className='t1 mar_2'>改</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.wsjNum} onChange={(e) => {
                                                        this.inputOnchange('cs_hx_8w',e)
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            wsjNum: e.target.value
                                                        })
                                                    }} />

                                                </p>
                                                <p className='t1 mar_2'>卫生间</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_9g }  onChange={(e)=>this.inputOnchange( 'cs_hx_9g',e)} />
                                                </p>
                                                <p className='t1 mar_2'>改</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cfNum} onChange={(e) => {
                                                        this.inputOnchange('cs_hx_10c',e)
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            cfNum: e.target.value
                                                        })
                                                    }} />


                                                </p>
                                                <p className='t1 mar_2'>厨房</p>
                                            </div>

                                            <div className='itee item45' style={{marginBottom:'0px'}}>
                                                <p className='t1'>户型建面：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_hxmj_min }  onChange={(e)=>this.inputOnchange('cs_hx_hxmj_min',e)} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_hxmj_max} onChange={(e)=>this.inputOnchange( 'cs_hx_hxmj_max',e)} />
                                                </p>
                                            </div>

                                            <div className='itee item55 mar_bot' style={{marginBottom:'0px'}}>
                                                <p className='t1'>相同户数：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_tlxhxs} onChange={(e)=>this.inputOnchange('cs_hx_tlxhxs',e)} />
                                                </p>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className='col3 mar_bot10'>
                                    {/* 厨房特征 */}
                                    {list1(this.state.cfNum > 0 ? this.state.cfNum : 1)}

                                    {/* 客厅特征 */}
                                    {list2(this.state.ktNum > 0 ? this.state.ktNum : 1)}


                                </div>
                                <div className='col3 mar_bot10'>

                                    {/* 卧室特征 */}
                                    {list(this.state.wsNum > 0 ? this.state.wsNum : 1)}

                                    {/* 公卫特征 */}
                                    {list4(this.state.wsjNum > 0 ? this.state.wsjNum : 1)}
                                </div>
                                <div className='col3'>

                                    {/* 餐厅特征 */}
                                    {list3(this.state.ctNum > 0 ? this.state.ctNum : 1)}

                                    <div className='row1'>
                                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>赠送特征</p>
                                        <div className='tablist'>
                                            <div className='itee item50' id='hxsx111'>
                                                <p className='t1'>户型竖向：</p>
                                                <Select
                                                    value={this.state.cs_zs_hxsx}
                                                    getPopupContainer={()=>document.getElementById('hxsx111')}
                                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_zs_hxsx',0)}>
                                                    <Option value={'-1'}>全部</Option>
                                                    {this.state.hxsx.map((item, index) => (
                                                        <Option key={'hxsx' + index} value={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item50'>
                                                <p className='t1'>阳台占比：</p>
                                                <p className='t2'>
                                                    <input placeholder='-'  value={this.state.cs_gw_ytzbl_min ===undefined ? null : this.state.cs_gw_ytzbl_min[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_ytzbl_min',0)} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-'  value={this.state.cs_gw_ytzbl_max ===undefined ? null : this.state.cs_gw_ytzbl_max[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_ytzbl_max',0)} />
                                                </p>
                                            </div>
                                            <div className='itee item100'>
                                                <p className='t1'>赠送比例：</p>
                                                <p className='t2'>
                                                    <input placeholder='-'  value={this.state.cs_gw_zsl_min ===undefined ? null : this.state.cs_gw_zsl_min[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_zsl_min',0)} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-'  value={this.state.cs_gw_zsl_max ===undefined ? null : this.state.cs_gw_zsl_max[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_zsl_max',0)} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row1'>
                                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>敏感特征</p>
                                        <div className='tablist'>
                                            <div className='itee item50'>
                                                <p className='t1'>面宽数量：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_mg_mks_min ===undefined ? null : this.state.cs_mg_mks_min[0]}  onChange={this.inputOnchange.bind(this, 'cs_mg_mks_min',0)} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_mg_mks_max ===undefined ? null : this.state.cs_mg_mks_max[0]}  onChange={this.inputOnchange.bind(this, 'cs_mg_mks_max',0)} />
                                                </p>
                                            </div>
                                            <div className='itee item50' id='ktjw1111'>
                                                <p className='t1'>空调机位：</p>
                                                <Select
                                                    value={this.state.cs_mg_ktjw}
                                                    getPopupContainer={()=>document.getElementById('ktjw1111')}
                                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_mg_ktjw',0)}>
                                                    <Option value={'-1'}>全部</Option>
                                                    {this.state.mgktjw.map((item, index) => (
                                                        <Option key={'mgktjw' + index} value={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item50 mar_bot' id='xg111'>
                                                <p className='t1'>玄关类型：</p>
                                                <Select
                                                    value={this.state.cs_mg_xg}
                                                    getPopupContainer={()=>document.getElementById('xg111')}
                                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_mg_xg',0)}>
                                                    <Option value={'-1'}>全部</Option>
                                                    {this.state.mgxg.map((item, index) => (
                                                        <Option key={'mgxg' + index} value={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item50 mar_bot' id='zwcx123'>
                                                <p className='t1'>主卧朝向：</p>
                                                <Select
                                                    value={this.state.cs_mg_zwcx}
                                                    getPopupContainer={()=>document.getElementById('zwcx123')}
                                                    placeholder='全部' className='t2' suffixIcon={icon} onChange={this.change1.bind(this, 'cs_mg_zwcx',0)}>
                                                    <Option value={'-1'}>全部</Option>
                                                    {this.state.mgzwcx.map((item, index) => (
                                                        <Option key={'mgzwcx' + index} value={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        {this.state.lxhsNum> 1 ?  listM(this.state.lxhsNum) : null}

                        

                    </div>}
                    {this.state.active===2 && <div className='ovauto'>
                    <div className='tabble tabble1' style={{paddingLeft: 0}}>
                            <div className='col2'>
                                <div className='row1'>
                                    <div className='tablist' >
                                        <div className='itee1 ' id='area'>
                                            <Select
                                                style={{width:65}}
                                            value={this.state.titleYT || "业态"}
                                                placeholder='业态' className='t2' suffixIcon={icon} onChange={
                                                (value)=>{console.log(value); this.setState({titleYT:value})}
                                                
                                            }>
                                                {this.state.yt.map((item6) => (
                                                    <Option key={item6.spelling} value={item6.spelling}>{item6.spelling}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='itee'>-</div>
                                        <div className='itee1 '>
                                            <p className='t2'>
                                            <input
                                             placeholder='电梯数'
                                            style={{width:40}}
                                            value={this.state.titleTS}
                                            onChange={e => (this.setState({titleTS: e.target.value}))}
                                        />
                                            </p>
                                            <p className='t1'>T</p>
                                            <p className='t2'>
                                            <input
                                            placeholder='总户数'
                                            style={{width:40}}
                                                value={this.state.titleHS}
                                                onChange={e => (this.setState({titleHS: e.target.value}))}
                                            />
                                            </p>
                                        </div>
                                        <div className='itee'>-</div>
                                        <div className='itee1'>
                                            <p className='t2'>
                                            <input
                                            placeholder='户数'
                                            style={{width:30}}
                                                value={this.state.xhhs_1}
                                                onChange={e => (this.setState({xhhs_1: e.target.value}))}
                                                onBlur={e=>{this.checkXhhs(e.target.value)}}
                                            /> </p>
                                            <p>&nbsp;</p>
                                            <Select
                                                value={this.state.hxmjd_1 || "面积"}
                                                placeholder='面积' className='t2' suffixIcon={icon} onChange={
                                                (value)=>{console.log(value); this.setState({hxmjd_1:value})}
                                                
                                            }>
                                                {this.state.hxmjArr.map((item6) => (
                                                    <Option key={item6} value={item6}>{item6}</Option>
                                                ))}
                                            </Select>
                                            <p>+</p>
                                            <p className='t2'>
                                            <input
                                            placeholder='户数'
                                            style={{width:30}}
                                                value={this.state.xhhs_2}
                                                onChange={e => (this.setState({xhhs_2: e.target.value}))}
                                                onBlur={e=>{this.checkXhhs(e.target.value)}}
                                            />
                                            </p>
                                            <p>&nbsp;</p>
                                            <Select
                                                value={this.state.hxmjd_2 || "面积"}
                                                placeholder='面积' className='t2' suffixIcon={icon} onChange={
                                                (value)=>{console.log(value); this.setState({hxmjd_2:value})}
                                                
                                            }>
                                                {this.state.hxmjArr.map((item6) => (
                                                    <Option key={item6} value={item6}>{item6}</Option>
                                                ))}
                                            </Select>
                                            <p>+</p>
                                            <p className='t2'>
                                            <input
                                            placeholder='户数'
                                            style={{width:30}}
                                                value={this.state.xhhs_3}
                                                onChange={e => (this.setState({xhhs_3: e.target.value}))}
                                                onBlur={e=>{this.checkXhhs(e.target.value)}}
                                            />
                                            </p>
                                            <p>&nbsp;</p>
                                            <Select
                                                value={this.state.hxmjd_3 || "面积"}
                                                placeholder='面积' className='t2' suffixIcon={icon} onChange={
                                                (value)=>{console.log(value); this.setState({hxmjd_3:value})}
                                                
                                            }>
                                                {this.state.hxmjArr.map((item6) => (
                                                    <Option key={item6} value={item6}>{item6}</Option>
                                                ))}
                                            </Select>
                                            <p>+</p>
                                            <p className='t2'>
                                            <input
                                            placeholder='户数'
                                            style={{width:30}}
                                                value={this.state.xhhs_4}
                                                onChange={e => (this.setState({xhhs_4: e.target.value}))}
                                                onBlur={e=>{this.checkXhhs(e.target.value)}}
                                            />
                                            </p>
                                            <p>&nbsp;</p>
                                            <Select
                                                value={this.state.hxmjd_4 || "面积"}
                                                placeholder='面积' className='t2' suffixIcon={icon} onChange={
                                                (value)=>{console.log(value); this.setState({hxmjd_4:value})}
                                                
                                            }>
                                                {this.state.hxmjArr.map((item6) => (
                                                    <Option key={item6} value={item6}>{item6}</Option>
                                                ))}
                                            </Select>
                                            <p>+</p>
                                            <p className='t2'>
                                            <input
                                            placeholder='户数'
                                            style={{width:30}}
                                                value={this.state.xhhs_5}
                                                onChange={e => (this.setState({xhhs_5: e.target.value}))}
                                                onBlur={e=>{this.checkXhhs(e.target.value)}}
                                            />
                                            </p>
                                            <p>&nbsp;</p>
                                            <Select
                                                value={this.state.hxmjd_5 || "面积"}
                                                placeholder='面积' className='t2' suffixIcon={icon} onChange={
                                                (value)=>{console.log(value); this.setState({hxmjd_5:value})}
                                                
                                            }>
                                                {this.state.hxmjArr.map((item6) => (
                                                    <Option key={item6} value={item6}>{item6}</Option>
                                                ))}
                                            </Select>
                                            <p>+</p>
                                            <p className='t2'>
                                            <input
                                            placeholder='户数'
                                            style={{width:30}}
                                                value={this.state.xhhs_6}
                                                onChange={e => (this.setState({xhhs_6: e.target.value}))}
                                                onBlur={e=>{this.checkXhhs(e.target.value)}}
                                            />
                                            </p>
                                            <p>&nbsp;</p>
                                            <Select
                                                value={this.state.hxmjd_6 || "面积"}
                                                placeholder='面积' className='t2' suffixIcon={icon} onChange={
                                                (value)=>{console.log(value); this.setState({hxmjd_6:value})}
                                                
                                            }>
                                                {this.state.hxmjArr.map((item6) => (
                                                    <Option key={item6} value={item6}>{item6}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='itee'>-</div>
                                        <div className='itee1'>
                                        <Select
                                            value={this.state.titleCS || '层数'}
                                                placeholder='层数' className='t2' suffixIcon={icon} onChange={
                                                (value)=>{console.log(value); this.setState({titleCS:value})}
                                                
                                            }>
                                                {this.state.cs.map((item6) => (
                                                    <Option key={item6.spelling} value={item6.spelling}>{item6.spelling}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='itee'>-</div>
                                        <div className='itee1'>
                                       <p className='t2'>
                                       <input
                                       placeholder='尾号'
                                            style={{width:40}}
                                                value={this.state.titleBH}
                                                onChange={e => (this.setState({titleBH: e.target.value}))}
                                            />
                                       </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex1'>
                            <p>热门检索：</p>
                            <div className='flexwalist3'>
                            <p style={{textAlign: 'center',width:'100%'}}>
                                {this.state.recommend && this.state.recommend.map((item)=>{
                                    return (<a style={{color: 'black',display: 'inline-block', height: '35px', backgroundColor: '#389589',lineHeight:'35px',margin: '8px',padding: '0 8px',borderRadius:'4px'}} href={`#/rout/ProductDetail/${item.id}`} target='_blank' rel="noreferrer">{item.title}</a>)
                                })}
                            </p>
                            </div>
                        </div>
                        <div className='flex1'>
                            <div className=''>
                             <p  dangerouslySetInnerHTML={{ __html: this.state.retrieval }}></p>
							 </div>
                        </div>
                    </div>}
                    {this.state.active===3 && <div className='ovauto'>
                        <div className='flex1'>
                            <div className='flexwalist3'>
                            <TextArea
                                style={{height:'100px',fontSize:'20px'}}
                                value={this.state.docNo}
                                onChange={e => (this.setState({docNo: e.target.value}))}
                                placeholder='输入文档编号查询，如"IBGE20220120174120" '
                                autoSize={{ minRows: 1, maxRows: 1 }}
                            />
                            </div>
                        </div>
                    </div>}
                    {/* searchKeyword */}
                    {this.state.active===4 && <div className='ovauto'>
                    <div className='flex1'>
                            <div className='flexwalist3'>
                            <TextArea
                                style={{height:'100px',fontSize:'20px'}}
                                value={this.state.searchKeyword}
                                onChange={e => (this.setState({searchKeyword: e.target.value}))}
                                placeholder="输入查询关键字"
                                autoSize={{ minRows: 1, maxRows: 1 }}
                            />
                            </div>
                        </div>
                    </div>}
                    {/* footer */}
                    <div className='footbtn'>
                        <p onClick={()=>{
                            this.props.history.push('/rout/About')
                        }}>筛选手册</p>
                        <p style={{ background: '#F4543C' }} onClick={() => {
                            window.location.reload()
                        }}>清空选项</p>
                        <p onClick={this.ok.bind(this)}>确定</p>
                    </div>
                </Modal>
            </div>
        )
    }

    ok(e) {
        var allArr = this.state.sreachAll
        var isSend=false
        if (this.state.active===1) {
            isSend=true
                if(this.refs.list0 && this.state.visible===true){
                    allArr = {
                        //户型1模块-卧室特征
                        hxmk_ws: {
                            cs_wsjs_min: this.state.cs_wsjs_min,//卧室进深最小值
                            cs_wsjs_max: this.state.cs_wsjs_max,//卧室进深最大值
                            cs_wsmk_min: this.state.cs_wsmk_min,//卧室面宽最小值
                            cs_wsmk_max: this.state.cs_wsmk_max,//卧室面宽最大值
                            cs_ws_dlymj: this.state.cs_ws_dlymj,//衣帽间类型
                            cs_ws_hwsj: this.state.cs_ws_hwsj,//独卫配置
                            cs_ws_wsjbj: this.state.cs_ws_wsjbj,//卫生间布局
                            cs_ws_wsjgsfl: this.state.cs_ws_wsjgsfl,//干湿分离
                            cs_ws_cjgnfjct: this.state.cs_ws_cjgnfjct,//房间串套
                            cs_ws_pc: this.state.cs_ws_pc,//飘窗赠送
                        },
                        //户型1模块-基本特征
                        hxmk_jb: {
                            cs_hx_1g: this.state.cs_hx_1g,//房型配置-1改
                            cs_hx_2k: this.state.cs_hx_2k,//房型配置-2客
                            cs_hx_3g: this.state.cs_hx_3g,//房型配置-3改	
                            cs_hx_4c: this.state.cs_hx_4c,//房型配置-4餐
                            cs_hx_5g: this.state.cs_hx_5g,//房型配置-5改
                            cs_hx_6w: this.state.cs_hx_6w,//房型配置-6卧
                            cs_hx_7g: this.state.cs_hx_7g,//房型配置-7改
                            cs_hx_8w: this.state.cs_hx_8w,//房型配置-8卫
                            cs_hx_9g: this.state.cs_hx_9g,//房型配置-9改
                            cs_hx_10c: this.state.cs_hx_10c,//房型配置-10厨
                            cs_hx_hxmj_min: this.state.cs_hx_hxmj_min,//户型建面最小值
                            cs_hx_hxmj_max: this.state.cs_hx_hxmj_max,//户型建面最大值
                            cs_hx_tlxhxs: this.state.cs_hx_tlxhxs,//相同户数
                        },
                        //户型1模块-厨房特征
                        hxmk_cf: {
                            cs_cf_cfbj: this.state.cs_cf_cfbj,//厨房布局
                            cs_cf_ytgl: this.state.cs_cf_ytgl,//阳台关联
                            cs_cf_cfmk_min: this.state.cs_cf_cfmk_min,//厨房面宽最小值
                            cs_cf_cfmk_max: this.state.cs_cf_cfmk_max,//厨房面宽最大值
                            cs_cf_cfjs_min: this.state.cs_cf_cfjs_min,//厨房进深最小值
                            cs_cf_cfjs_max: this.state.cs_cf_cfjs_max,//厨房进深最大值
                            cs_cf_dlzxcf: this.state.cs_cf_dlzxcf,//中西分厨
                        },
                        //户型1模块-客厅特征
                        hxmk_kt: {
                            cs_kt_ktjs_min: this.state.cs_kt_ktjs_min,//客厅进深最小值
                            cs_kt_ktjs_max: this.state.cs_kt_ktjs_max,//客厅进深最大值
                            cs_kt_ktmk_min: this.state.cs_kt_ktmk_min,//客厅面宽最小值
                            cs_kt_ktmk_max: this.state.cs_kt_ktmk_max,//客厅面宽最大值
                            cs_kt_ktzx: this.state.cs_kt_ktzx,//客厅轴向
                            cs_kt_dt: this.state.cs_kt_dt,//端厅配置
                        },
                        //户型1模块-餐厅特征
                        hxmk_ct: {
                            cs_ct_ctjs_min: this.state.cs_ct_ctjs_min,//餐厅进深最小值
                            cs_ct_ctjs_max: this.state.cs_ct_ctjs_max,//餐厅进深最大值
                            cs_ct_ctmk_min: this.state.cs_ct_ctmk_min,//餐厅面宽最小值
                            cs_ct_ctmk_max: this.state.cs_ct_ctmk_max,//餐厅面宽最大值
                            cs_ct_dlzxct: this.state.cs_ct_dlzxct,//中西分餐
                        },
                        //户型1模块-公卫特征
                        hxmk_gw: {
                            cs_gw_wsjmk_min: this.state.cs_gw_wsjmk_min,//卫生间面宽最小值
                            cs_gw_wsjmk_max: this.state.cs_gw_wsjmk_max,//卫生间面宽最大值
                            cs_gw_wsjjs_min: this.state.cs_gw_wsjjs_min,//卫生间进深最小值
                            cs_gw_wsjjs_max: this.state.cs_gw_wsjjs_max,//卫生间进深最大值
                            cs_gw_wsjbj: this.state.cs_gw_wsjbj,//卫生间布局
                            cs_gw_wsjgsfl: this.state.cs_gw_wsjgsfl,//干湿分离
                            cs_gw_cjgnf: this.state.cs_gw_cjgnf,//干区合用
                        },
                        //户型1模块-赠送特征
                        hxmk_zs: {
                            cs_zs_hxsx: this.state.cs_zs_hxsx,//户型竖向
                            cs_gw_ytzbl_min: this.state.cs_gw_ytzbl_min,//阳台占比最小值
                            cs_gw_ytzbl_max: this.state.cs_gw_ytzbl_max,//阳台占比最大值
                            cs_gw_zsl_min: this.state.cs_gw_zsl_min,//赠送率最小值
                            cs_gw_zsl_max: this.state.cs_gw_zsl_max,//赠送率最大值
                        },
                        //户型1模块-敏感特征
                        hxmk_mg: {
                            cs_mg_mks_min: this.state.cs_mg_mks_min,//面宽数量最小值
                            cs_mg_mks_max: this.state.cs_mg_mks_max,//面宽数量最大值
                            cs_mg_ktjw: this.state.cs_mg_ktjw,//空调机位
                            cs_mg_xg: this.state.cs_mg_xg,//玄关类型
                            cs_mg_zwcx: this.state.cs_mg_zwcx,//主卧朝向
                        },
                        //综合模块-地理特征
                        zhmk_dl: {
                            proviceId: this.state.proviceId,//目标省份id
                            cityId: this.state.cityId,//目标城市id
                            cs_kfdw: this.state.cs_kfdw,//开发单位
                            cs_rzyq: this.state.cs_rzyq,//日照要求
                            cs_dx: this.state.cs_dx,//适用地形
                        },
                        //综合模块-关键特征
                        zhmk_gj: {
                            cs_gj_cpyt: this.state.cs_gj_cpyt,//产品业态
                            cs_gj_cssx: this.state.cs_gj_cssx,//适用层数
                            cs_gj_jfzc: this.state.cs_gj_jfzc,//交付支持
							cs_gj_cpdw: this.state.cs_gj_cpdw,//产品定位
                            // cs_gj_cppf_min: this.state.cs_gj_cppf_min,//产品定位最小值
                            // cs_gj_cppf_max: this.state.cs_gj_cppf_max,//产品定位最大值
                            cs_gj_cpjxrjl_min: this.state.cs_gj_cpjxrjl_min,//容积率值最小值
                            cs_gj_cpjxrjl_max: this.state.cs_gj_cpjxrjl_max,//容积率值最大值
                        },
                        //楼型参数-地理特征
                        lxmk_dl: {
                            cs_bzmj_min: this.state.cs_bzmj_min,//楼型面积最小值
                            cs_bzmj_max: this.state.cs_bzmj_max,//楼型面积最大值
                            cs_dfl_min: this.state.cs_dfl_min,//得房率值最小值
                            cs_dfl_max: this.state.cs_dfl_max,//得房率值最大值
                            cs_lxmk_min: this.state.cs_lxmk_min,//楼型面宽最小值
                            cs_lxmk_max: this.state.cs_lxmk_max,//楼型面宽最大值
                            cs_lxjs_min: this.state.cs_lxjs_min,//楼型进深最小值
                            cs_lxjs_max: this.state.cs_lxjs_max,//楼型进深最大值
                            cs_lxhs: this.state.cs_lxhs,//楼型户数
                        },
                        //楼型参数-核心筒特征
                        lxmk_hxt: {
                            cs_hxtlx: this.state.cs_hxtlx,//核心筒位置
                            cs_ldtgx: this.state.cs_ldtgx,//楼电梯关系
                            cs_hxtmc: this.state.cs_hxtmc,//核心筒明窗
                            cs_dts: this.state.cs_dts,//电梯数量
                            cs_lts: this.state.cs_lts,//楼梯数量
                        },
                        //楼型参数-拓展特征
                        lxmk_tz: {
                            cs_bzcxz: this.state.cs_bzcxz,//楼型形状
                            cs_btzh: this.state.cs_btzh,//板塔关系
                            cs_pjgx: this.state.cs_pjgx,//拼接支持
                        },
                        //楼型参数-节能特征
                        lxmk_jn: {
                            cs_txxs_min: this.state.cs_txxs_min,//体形系数最小值
                            cs_txxs_max: this.state.cs_txxs_max,//体形系数最大值
                            cs_cdb_min: this.state.cs_cdb_min,//窗地比值最小值
                            cs_cdb_max: this.state.cs_cdb_max,//窗地比值最大值
                            cs_cqb_min: this.state.cs_cqb_min,//窗墙比值最小值
                            cs_cqb_max: this.state.cs_cqb_max,//窗墙比值最大值
                        },
                        //标准参数-规范特征
                        bzmk_gf: {
                            cs_gf_xfsj: this.state.cs_gf_xfsj,//消防设计规范
                            cs_gf_mjjs: this.state.cs_gf_mjjs,//面积计算规范
                            cs_tzsd: this.state.cs_tzsd,//标准参数-成果特征-面世年度
                            cs_tzxx: this.state.cs_tzxx,//标准参数-成果特征-市场响应
                        },
                        keywords: this.state.keywords,
                    }
                }
            var page = this.state.page;
            // allArr.page = page;
        }
        if (this.state.active===3 && this.state.docNo!=='') {
            allArr.docNo=this.state.docNo
            isSend=true
        }
        if (this.state.active===2) {
            
            let titleXTHS= this.state.xhhs_1+this.state.hxmjd_1+this.state.xhhs_2+this.state.hxmjd_2+this.state.xhhs_3+this.state.hxmjd_3+this.state.xhhs_4+this.state.hxmjd_4+this.state.xhhs_5+this.state.hxmjd_5+this.state.xhhs_6+this.state.hxmjd_6
            
            if (this.state.titleYT!=='' || this.state.titleBH!=='' || this.state.titleCS!=='' || this.state.titleHS!=='' || this.state.titleTS!=='') {
                allArr.title= `${this.state.titleYT}-${this.state.titleTS}T${this.state.titleHS}-${titleXTHS}-${this.state.titleCS}-${this.state.titleBH}`
                isSend=true
            }
            if (Number(this.state.xhhs_1)+Number(this.state.xhhs_2)+Number(this.state.xhhs_3)+Number(this.state.xhhs_4)+Number(this.state.xhhs_5)+Number(this.state.xhhs_6)!==Number(this.state.titleHS)) {
                isSend=true
                message.error('户数之和与总户数必须一致')
            }
        }
        if (this.state.active===4 && this.state.searchKeyword!=='') {
            allArr.keyword= this.state.searchKeyword
            isSend=true
        }
        if (!isSend) {
            message.error('当前查找条件不能为空或查找条件输入错误！')
        }
        
        // allArr.orderBy=this.state.orderBy
		let orderSort=''
        console.log(this.state.sreachAll,allArr,'All')
        switch (this.state.orderBy) {
            case 1:
                orderSort=this.state.compreRank !==1 ? 'desc': 'asc'
                break;
            case 2:
                orderSort=this.state.numRank !==1 ? 'desc': 'asc'
                break;
            case 3:
                orderSort=this.state.commentRank !==1 ? 'desc': 'asc'
                break;
            case 4:
                orderSort=this.state.profuctRank !==1 ? 'desc': 'asc'
                break;
            case 5:
                orderSort=this.state.priceRank !==1 ? 'desc': 'asc'
                break;
            default:
                break;
        }
        if(this.state.visible===true){
            this.setState({page:1})
        }
        this.setState({
            sreachAll: allArr
        })
            global.Ajax.hhtc_request('home/works', {data:allArr,orderBy:this.state.orderBy,page: this.state.visible===true? 1: this.state.page, orderSort:orderSort}).then(res => {
                if (res.code == 1) {
                    this.setState({
                        data: res.data.data,
                        total: res.data.total,
                        visible: false,
                        show111:false,
                        defaultName:"筛选结果",
                        docNo:'',
                        searchKeyword:'',
                    })
                } else {
                    message.error(res.msg)
                }
            })
    }
    clear(){
        this.setState({
            visible: false,
            show111:false,
            defaultName:"筛选结果",
            docNo:'',
            searchKeyword:'',
        })
    }
}