import React from 'react';
import { message, Modal } from 'antd';

import banner from '../../imgs/pic1.png'

export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            data:[],
            title:'',
            id:'',
            content:"",
            active:"",
            activeId: ''
        }
    }
	componentDidMount(){
        document.title='常见问题'

        var req={
            id:23,
        }
		global.Ajax.hhtc_request('agreement/about',req).then(res=>{
            if (res.code == 1) {
                this.setState({
                    data: res.data.list,
                    title: res.data.name,
                    id: res.data.id,
                    activeId: res.data.list[0].id
                }, () => {
                    this.info(this.state.activeId)
                })
            } else {
                message.error(res.msg, 2)
            }
        })
	}

    info(id){
        var req={
            id:id
        }
        global.Ajax.hhtc_request('agreement/info',req).then(res=>{
            if(res.code==1){
                this.setState({
                    content:res.data.content,
                    active:res.data.id,
                })
            }
        })
    }

      
    render(){
        return(
            <div className='padd_top66'>
                <img alt='' src={banner} className='banner1' />
                <div style={{background: '#fff'}}>
                    <div className='w1400 probox'>
                        <div className='lmenu'>
                            <div className='mm1'>
                                <p className='p1'>常见问题</p>

                                {this.state.data.map((item, index) => {//循环用.map
                                    return (
                                        <React.Fragment key={index + 'd'}>

                                            <div style={{ paddingLeft: 20 }}>
                                                <p onClick={() => {
                                                    this.setState({
                                                        activeId: item.id
                                                    })
                                                    this.info(item.id)
                                                }} className={this.state.activeId == item.id ? 'active' : ''} key={'s' + index}>{item.name}</p>
                                            </div>

                                        </React.Fragment>
                                    )
                                })}

                            </div>
                        </div>
                        <div className='rbox'>

                            {this.state.content==""?

                            <img alt=''
                             src={'https://migu.schhtc.cn/file/1/jpg/2021/05/16/Epg5CkkNxC23fZjh.jpg'} 
                             style={{display: 'block',width: '100%'}} />:<div dangerouslySetInnerHTML={{__html:this.state.content}}></div>}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}