import React from 'react';
import { message, Modal } from 'antd';

import eye from '../../imgs/eye.png'
import eyes from '../../imgs/eyes.png'
import gou from '../../imgs/gou1.png'
import logo from '../../imgs/logo.png'

export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            selected: false,
            pwdtype: true,
            pwdtype1: true,
            password:"",
            okPassword:"",
            code:"",
            mobile:"",
            smstext: '获取验证码',
        }
    }
	componentDidMount(){
        document.title='忘记密码'
	}
    forget(){
		if(!this.state.selected){
			message.error("请勾选协议",2);
			return false;
		}
        var req={
            mobile:this.state.mobile,
            password:this.state.password,
            okPassword:this.state.okPassword,
            code:this.state.code,
        }
        global.Ajax.hhtc_request('login/forgetPassword',req).then(res=>{
            if(res.code==1){
                message.success(res.msg,2);
				localStorage.setItem('isOpenLogin',true);
				this.props.history.push("/rout/home");
            }else{
                message.error(res.msg,2);
            }
        })
    }
    getCode(){
        var req={
            mobile: this.state.mobile,
            type:3
        }
        global.Ajax.hhtc_request('login/SendCode',req).then(res=>{
            if(res.code==1){
                message.success(res.msg,2)
                this.djs()
            }else{
                message.error(res.msg,2)
            }
        })
        
    }
    // 倒计时
    djs(){
        var time=60;
         this.timer= setInterval(() => {
            time--;
            if(time<=0){
                this.setState({
                    smstext:"重发",
                    smsstatus:true
                },()=>{
                    time=60;
                    clearInterval(this.timer);
                })
            }else{
                this.setState({
                    smstext:time+"s",
                    smsstatus:false
                })
            }
        }, 1000);
     }
    render(){
        return(
            <div style={{overflow: 'hidden'}}>
                <div className='telbox'>
                    <div className='flex'>
						{/*
                        <p style={{width: 185,lineHeight: '66px',backgroundColor: '#000',fontSize: 28,color: '#fff',textAlign: 'center',fontWeight: 'bold'}}>LOGO</p>
                         */}
						 <img alt='' src={logo} className='icon' onClick={()=>{
                            this.props.history.goBack()
                        }}/> 
                        <p className='p2'>已有账号，<span onClick={()=>{
                            localStorage.setItem('isOpenLogin',true)
                            this.props.history.goBack()
                        }}>前往登录</span></p>
                        <p className='p1' style={{marginLeft: 0}} onClick={()=>{
                            this.props.history.goBack()
                        }}>返回首页</p>
                    </div>
                    <h1 className='tit'>忘记密码</h1>
                    <div className='bgbai'>
                        <input placeholder='请输入手机号' className='inpt' onChange={(e)=>{
                                this.setState({
                                        mobile:e.target.value
                                })            
                            }}/>
                        <div className='flex1'>
                            <input placeholder='请输入验证码' onChange={(e)=>{
                                this.setState({
                                        code:e.target.value
                                })            
                            }}/>
                            <p className='btn' onClick={this.getCode.bind(this)}>{this.state.smstext}</p>
                        </div>
                        <div className='flex2' style={{marginBottom: 24}}>
                            <input placeholder='8-20位密码，至少数字+字母' type={this.state.pwdtype?'password':'text'} onChange={(e)=>{
                                this.setState({
                                        password:e.target.value
                                })            
                            }}/>
                            <p onClick={()=>{
                                this.setState({
                                    pwdtype: !this.state.pwdtype
                                })
                            }}>
                                {this.state.pwdtype?<img alt='' src={eyes} />:<img alt='' src={eye} />}
                            </p>
                        </div>
                        <div className='flex2'>
                            <input placeholder='确认密码' type={this.state.pwdtype1?'password':'text'} 
                            onChange={(e)=>{
                                this.setState({
                                        okPassword:e.target.value
                                })            
                            }}/>
                            <p onClick={()=>{
                                this.setState({
                                    pwdtype1: !this.state.pwdtype1
                                })
                            }}>
                                {this.state.pwdtype1?<img alt='' src={eyes} />:<img alt='' src={eye} />}
                            </p>
                        </div>
                        <div className='bcbox' onClick={()=>{
                            this.setState({
                                selected: !this.state.selected
                            })
                        }}>
                            {this.state.selected?
                                <img alt='' src={gou} />
                            :
                                <span></span>
                            }
                            <p>我已同意并接受<span onClick={()=>{
                                window.open("https://www.sjft.cc/xieyi.html")
                            }}>《用户协议》</span>和<span onClick={()=>{
                                window.open("https://www.sjft.cc/xieyi2.html")
                            }}>《隐私协议》</span></p>
                        </div>
                        <p className='qbtn' onClick={this.forget.bind(this)}>确定修改</p>
                    </div>
                </div>
            </div>
        )
    }
}