import React from 'react';
import { Modal,message,Spin } from 'antd';

// import top1 from '../../imgs/top1.png'
import proj from '../../imgs/proj.png'
import bi from '../../imgs/bi.png'
import down from '../../imgs/down.png'
import down1 from '../../imgs/down1.png'
import close from '../../imgs/close.png'

export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            work:[],
            visible: false,
            type: null,
            show: false,
        }
    }
    componentDidMount(){
        document.title=this.props.title+'-户型下载'
        this.getData();
    }
    getData(){
        var req={
            id:this.props.id,
        }
         global.Ajax.hhtc_request('home/workDownInfo',req).then(res=>{
             if(res.code==1){
                 this.setState({
                    work:res.data,
                 })
             }else{
                 message.error(res.msg,2)
             }
         })
    }

    down(type){
        if(type==1){
            this.setState({
                type: type
            },()=>{
                this.downStart()
            })
            return;
        }
        this.setState({
            visible: true,
            type: type,
        })
        
    }
    downStart(){
        this.setState({
            show: true,
        },()=>{
            var req = {
                id:this.props.id,
                type:this.state.type
            }
            global.Ajax.hhtc_request('download/down',req).then(res=>{
                this.setState({
                    show: false
                })
                if(res.code==1){
                    message.success(res.msg,2)
                    window.location.href=res.data
                    this.setState({
                        visible: false,
                    })
                }else{
                    message.error(res.msg,2)
                }
            })
        })
    }
    render(){
        return(
            <React.Fragment>
                <p className='tit1'>免费文件</p>
                <div className='projdbox'>
                    <img alt='' src={proj} className='projIcon' />
                    <h1 className='zpname'>{this.state.work.title}</h1>
                    <img alt='' src={bi} className='icon' />
                    <p className='p1' style={{width: 150}}>免费文件下载：免费</p>
                    <img alt='' src={down} className='icon' />
                    <p className='p1'>已下载次数：{this.state.work.freeDownNum}次</p>
                    <p className='btn1' onClick={this.down.bind(this,1)}>免费文件下载</p>
                </div>
                <p className='tit1'>付费文件</p>
                <div className='projdbox'>
                    <img alt='' src={proj} className='projIcon' />
                    <h1 className='zpname'>{this.state.work.title}</h1>
                    <img alt='' src={bi} className='icon' />
                    <p className='p1' style={{width: 150}}>付费文件下载：{this.state.work.integral*1} 积分</p>
                    <img alt='' src={down} className='icon' />
                    <p className='p1'>已下载次数：{this.state.work.chargeDownNum}次</p>
                    <p className='btn1' style={{background: '#389589'}} onClick={this.down.bind(this,2)}>付费文件下载</p>
                </div>
                <p className='tit1'>下载说明</p>
                <div className='downrule'>
                    
                    <div className='left1' style={{paddingLeft: 40}}>
                        <p className='tt1'>下载说明</p>
						
                        1.【免费文件】包括户型彩平图及户型强排模块cad(T3版本)。<br /><br />
                        2.【付费文件】包括户型彩平图、户型完整版cad(T8版本)、户型强排模块cad(T3版本)、户型指标表及户型解读书。<br /><br />
                        3.【免费文件】支持直接下载且不限次数，【付费文件】支付积分购买后可重复下载且不限次数。<br /><br />
                        4.以上内容来源于用户投稿经本站编辑后发布，若有侵权请联系管理员及时处理。<br /><br />
						
                    </div>
                    <div className='left1'>
                        <p className='tt1'>文件格式</p>
                        <div>
                            <p style={{marginLeft:'5px'}}>
							
							1.下载文件为压缩包zip、rar等格式，请使用对应的软件解压阅读。<br />
							2.【cad图纸文件】均按本站标准图层进行绘制，便于用户对图层进行管理及编辑。
							图纸文件包括T3及T8两个版本，便于不同场合使用需求。均为.dwg格式，请使用对应的软件进行阅读。<br />
							3.【户型指标表】均为.xlsx文档格式，请使用对应的软件进行阅读。<br />
							4.【户型解读书】均为.pdf文档格式，请使用对应的软件进行阅读。<br />
							5.【户型彩平图】均为.jpg图片格式，请使用对应的软件进行阅读。<br />
							
							</p>
                        </div>
                    </div>
                </div>
                <Modal 
                    title=''
                    visible={this.state.visible}
                    width={600}
                    footer={false}
                    closable={false}
                    centered={true}
                    className='paymask'
                >
                    <div className='modal-title'>
                        <p>下载提示</p>
                        <img alt='' src={close} onClick={()=>{
                            this.setState({
                                visible: false
                            })
                            clearInterval(this.timer)
                        }} />
                    </div>
                    <React.Fragment>
                        <p className='pri'>是否花费<span>{this.state.work.integral*1}</span>积分下载该作品</p>
                        <div className='flex'>
                            <p className='btn' onClick={this.downStart.bind(this)}>确定</p>
                            <p className='btn'  onClick={()=>{
                                this.setState({
                                    visible: false
                                })
                            }}>取消</p>
                        </div>
                    </React.Fragment>
                </Modal>
                {this.state.show&&<div style={{position: 'fixed',left: 0,top: 0,right: 0,bottom: 0,zIndex: 9999,display: 'flex',alignItems: 'center',justifyContent: 'center',background: 'rgba(0,0,0,.2)'}}>
                    <Spin />
                </div>}
            </React.Fragment>
        )
    }
}