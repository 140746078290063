import React from "react";
import {Input, message, Pagination} from 'antd';


export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            page: 1,
            size: 15,
            total: 0,
            keyword:'',
            search_v:[],
            album:[],
            news:[],
            category:[],//类别
            category_news:[],// 三个热门新闻
        }
    }
    componentDidMount() {
        document.title = '数聚房投-专注数据量化的地产咨询平台'
        console.log(this.props,'props')
        this.getArticles2()
        if (this.props.match.params.type==='1'){
            this.getArticles( {page:1,size:15})
        }else if (this.props.match.params.type==='2'){
            this.getArticles({category_id:this.props.match.params.keyOrId})
        }else {
            this.getArticles({album_id:this.props.match.params.keyOrId})
        }
    }
    getArticles(params){
        global.Ajax.hhtc_request('Articles/search',params).then(res => {
            console.log(res,'res')
            this.setState({
               search_v:res.data.data,
                total: res.data.total,
            })
        })

    }

    getArticles2(){
        global.Ajax.hhtc_request('Articles/index').then(res => {
            console.log(res,'res')
            this.setState({
                album:res.data.album,
                news: res.data.new,
                category:res.data.category,
                category_news:res.data.category_news,
            })
        })

    }

    render() {
        return (
            <React.Fragment>
                <div className='padd_top66 w1400'>
                    {/*导航*/}
                    <div className='menubox' style={{ marginBottom: 36 }}>
                        <div className='w1400'>
                            <p>首页</p>
                            <p>项目</p>
                            <p>搜索</p>
                        </div>
                    </div>
                     {/*搜索框*/}
                    <div className='searchBox w1400' onClick={() => {
                        this.setState({
                            visible: true
                        })
                    }}>
                        <Input value={this.state.keyword} style={{height: '80px',borderAadius: '4px',backgroundColor: '#EEF2F1'}}
                              onChange={(e)=>{
                                  this.setState({keyword: e.target.value})}}
                               onPressEnter={(e) => {
                                   if (e.target.value.trim()){
                                       this.setState({keyword: e.target.value})
                                       this.getArticles({keyword:e.target.value})
                                   }else{
                                       message.warn('请先输入查询条件')
                                   }
                               }}></Input>
                        <p onClick={()=>{
                            if (this.state.keyword){
                                this.getArticles({keyword:this.state.keyword})
                            }else{
                                message.warn('请先输入查询条件')
                            }
                        }}>搜索作品</p>
                    </div>
                    {/*搜索分类*/}
                    <div className='searchBoxClass'>
                        {this.state.category.map(item=>{
                            return <span onClick={() => {
                                //type 是搜索分类  1 是关键字， 2 是类别  3 是专辑ID
                                this.getArticles({category_id:item.category_id})
                            }}>{item.name}</span>
                        })}
                    </div>

                    <div className='articleBox'>
                        <div className='leftBoxSearch'>
                            {this.state.search_v && this.state.search_v.map(item=>{
                                return <div className='contentItem' onClick={()=>{
                                    window.open('#/rout/newsDetail/'+ item.id)
                                }}>
                                    <div style={{overflow:'hidden'}}>
                                        <img className='itemImg' src={item.main_image} alt=""/>
                                    </div>
                                    <div className='textF'>
                                        <h3 className='title'
                                        >{item.title}</h3>
                                        <div className='textBox' style={{fontSize:16}}>{item.new_describe}</div>
                                        <div className='otherInfo'>
                                            <span>{item.author}</span>
                                            <span>浏览量：{item.pv}</span>
                                            <span>{item.atime.substring(0,11)}</span>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className='sdxcv'>
                        {
                            this.state.news.length>0&&<Pagination current={this.state.page} pageSize={this.state.size} total={this.state.total} onChange={(index, i) => {
                                this.setState({
                                    page: index,
                                }, () => {
                                    if (this.props.match.params.type==='1'){
                                        this.getArticles( {keyword:this.state.keyword,page:index,size:this.state.size})
                                    }else if (this.props.match.params.type==='2'){
                                        this.getArticles({category_id:this.props.match.params.keyOrId,page:index,size:this.state.size})
                                    }else {
                                        this.getArticles({album_id:this.props.match.params.keyOrId,page:index,size:this.state.size})
                                    }
                                })
                            }} />

                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
