import React from 'react';
import { message, Modal } from 'antd';

import eye from '../../imgs/eye.png'
import eyes from '../../imgs/eyes.png'
import gou from '../../imgs/gou1.png'
import logo from '../../imgs/logo.png'

export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            selected: false,
            pwdtype: true,
            nickname:"",//昵称
            mobile:"",//手机号
            password:"",//密码
            code:"",
            smstext: '获取验证码',
            smsstatus: false,
        }
        this.timer=null;
    }
	componentDidMount(){
        document.title='注册账号'
    }
    componentWillUnmount(){
        if(this.timer){
            clearInterval(this.timer)
        }
    }
    register(){
        var req={
			mobile: this.state.mobile,
			password: this.state.password,
            nickname:this.state.nickname,
            code:this.state.code,
		}
        if(this.state.selected==false){
            message.error('请勾选我已同意并接受用户协议和隐私协议',2);
            return false;
        }
        global.Ajax.hhtc_request('login/register',req).then(res=>{
            if(res.code==1){
                var req = {
                    mobile: this.state.mobile,
			        password: this.state.password,
                }
                message.success(res.msg,2);
                //注册成功
                global.Ajax.hhtc_request('login/login',req).then(res=>{
                    if(res.code==1){
                        localStorage.setItem('ftToken',res.data.token)//用户登录凭证
                        localStorage.setItem('userId',res.data.userId)//用户id
                        localStorage.setItem('headImg',res.data.headImg)//用户头像
                        localStorage.setItem('isLogin',true)//登录状态
                        localStorage.setItem('level',res.data.level)//用户会员等级
                        localStorage.setItem('nickname',res.data.nickname)//用户昵称
                        this.props.history.push('/rout/home');//跳转首页
                    }else{
                        message.error(res.msg,2)
                    }
                })
				
				this.props.history.push("/rout/home")
				
            }else{
                message.error(res.msg,2)
            }
        })
    }
    getCode(){
        var req={
            mobile: this.state.mobile,
            type:1
        }
        global.Ajax.hhtc_request('login/SendCode',req).then(res=>{
            if(res.code==1){
                message.success(res.msg,2)
                this.djs()
            }else{
                message.error(res.msg,2)
            }
        })
    }
    // 倒计时
    djs(){
       var time=60;
        this.timer= setInterval(() => {
           time--;
           if(time<=0){
               this.setState({
                   smstext:"重发",
                   smsstatus:true
               },()=>{
                   time=60;
                   clearInterval(this.timer);
               })
           }else{
               this.setState({
                   smstext:time+"s",
                   smsstatus:false
               })
           }
       }, 1000);
    }
    render(){
        return(
            <div style={{overflow: 'hidden'}}>
                <div className='telbox'>
                    <div className='flex'>
                        {/* <p style={{width: 185,lineHeight: '66px',backgroundColor: '#000',fontSize: 28,color: '#fff',textAlign: 'center',fontWeight: 'bold'}}>LOGO</p> */}
                        <img alt='' src={logo} className='icon' onClick={()=>{
                            this.props.history.goBack()
                        }}/>
                        <p className='p2'>已有账号，<span onClick={()=>{
                            localStorage.setItem('isOpenLogin',true)
                            this.props.history.push('/rout/Home/')
                        }}>前往登录</span></p>
                        <p className='p1' style={{marginLeft: 0}} onClick={()=>{
                            this.props.history.goBack()
                        }}>返回首页</p>
                    </div>
                    <h1 className='tit'>注册账号</h1>
                    <div className='bgbai'>
                        <input placeholder='请输入用户昵称' className='inpt' 
                        onChange={(e)=>{
                                this.setState({
                                        nickname:e.target.value
                                })            
                        }}/>
                        <input maxLength={11} placeholder='请输入手机号' className='inpt' 
                        onChange={(e)=>{
                                this.setState({
                                        mobile:e.target.value
                                })            
                        }}/>
                        <div className='flex1'>
                            <input placeholder='请输入验证码' 
                            onChange={(e)=>{
                                this.setState({
                                        code:e.target.value
                                })            
                        }}/>
                            <p className='btn' onClick={this.getCode.bind(this)}>{this.state.smstext}</p>
                        </div>
                        <div className='flex2'>
                            <input placeholder='8-20位密码，至少数字+字母' type={this.state.pwdtype?'password':'text'} 
                            onChange={(e)=>{
                                this.setState({
                                        password:e.target.value
                                })            
                            }} />
                            <p onClick={()=>{
                                this.setState({
                                    pwdtype: !this.state.pwdtype
                                })
                            }}>
                                {this.state.pwdtype?<img alt='' src={eyes} />:<img alt='' src={eye} />}
                            </p>
                        </div>
                        <div className='bcbox' onClick={()=>{
                            this.setState({
                                selected: !this.state.selected
                            })
                        }}>
                            {this.state.selected?
                                <img alt='' src={gou} />
                            :
                                <span></span>
                            }
                            <p>我已同意并接受<span onClick={()=>{
                                window.open("https://www.sjft.cc/xieyi.html")
                            }}>《用户协议》</span>和<span onClick={()=>{
                                window.open("https://www.sjft.cc/xieyi2.html")
                            }}>《隐私协议》</span></p>
                        </div>
                        <p className='qbtn' onClick={this.register.bind(this)}>注册</p>
                    </div>
                </div>
            </div>
        )
    }
}