import React from 'react'
// import { List,Modal,Select,message } from 'antd/lib/form/Form'
import { Modal, Select, message } from 'antd';
const { Option } = Select;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cfNum: props.cfNum,
            ktNum: props.ktNum,
            ctNum: props.ctNum,
            wsNum: props.wsNum,
            wsjNum: props.wsjNum,
            lxhs:props.wsNum,
            data: [],
            keyword: [],//关键词参数
            names: [],//关键词
            keywords: "",//筛选时时关键词
            province: [],//目标省份
            city: [],//目标城市
            kfdw: [],//对标企业
            rzyq: [],//日照要求
            dx: [],//适用地形
            cpyt: [],//产品业态
            cssx: [],//层数上线
            jfzc: [],//交付支持
            hxtlx: [],//核心筒位置
            ldtgx: [],//楼电梯关系
            mc: [],//核心筒明窗

            bzcxz: [],//楼型形状
            btzh: [],//板塔关系
            pjgx: [],//拼接支持

            cfbj: [],//厨房布局
            ytgl: [],//阳台关联
            dlzxcf: [],//中西分厨

            //客厅特征
            ktzx: [],//客厅轴向
            dt: [],//端厅配置
            dlzxct: [],//中西分餐

            //卧室1特征
            dlymj: [],//衣帽间类型
            hwsj: [],//独卫配置
            wsjbj: [],//卫生间布局
            wsjgsfl: [],//干湿分离
            cjgnfjct: [],//房间串套
            pc: [],//飘窗赠送

            //公卫特征
            gwwsjbj: [],//公卫特征-卫生间布局
            gwwsjgsfl: [],//公卫特征-干湿分离
            gwcjgnfrk: [],//公卫特征-干区合用

            //赠送特征
            hxsx: [],//赠送特征-户型竖向

            //敏感特征
            mgktjw: [],//敏感特征-空调机位
            mgxg: [],//敏感特征-玄关类型
            mgzwcx: [],//敏感特征-主卧朝向

            //标准参数
            gfxfsj: [],//规范特征-消防设计规范
            gfmjjs: [],//规范特征-面积计算规范

            tzsd: [],//成果特征-面世年度
            tzxx: [],//成果特征-市场响应


        }
    }

    componentDidMount() {
        document.title = '产品'
        //筛选参数
        this.parameters();
        if (this.props.value.hxmk_jb!=undefined && this.props.index==0){
            if (this.props.value.hxmk_jb.cs_hx_1g){
                this.setState(this.cs_hx_1g,this.props.value.hxmk_jb.cs_hx_1g[0])
            }
            // this.setState(this.cs_hx_1g,(this.props.value.hxmk_jb.cs_hx_1g && this.props.value.hxmk_jb.cs_hx_1g[0]))
            // this.setState(this.ktNum,(this.props.value.hxmk_jb.cs_hx_2k && this.props.value.hxmk_jb.cs_hx_2k[0]))
            // this.setState(this.cs_hx_3g,(this.props.value.hxmk_jb.cs_hx_3g&& this.props.value.hxmk_jb.cs_hx_3g[0]))
            // this.setState(this.ctNum,(this.props.value.hxmk_jb.cs_hx_4c && this.props.value.hxmk_jb.cs_hx_4c[0]))
            // this.setState(this.cs_hx_5g,(this.props.value.hxmk_jb.cs_hx_5g &&this.props.value.hxmk_jb.cs_hx_5g[0]))
            // this.setState(this.wsNum,(this.props.value.hxmk_jb.cs_hx_6w && this.props.value.hxmk_jb.cs_hx_6w[0]))
            // this.setState(this.cs_hx_7g,(this.props.value.hxmk_jb.cs_hx_7g&&this.props.value.hxmk_jb.cs_hx_7g[0]))
            // this.setState(this.wsjNum,(this.props.value.hxmk_jb.cs_hx_8w && this.props.value.hxmk_jb.cs_hx_8w[0]))
            // this.setState(this.cs_hx_9g,(this.props.value.hxmk_jb.cs_hx_9g &&this.props.value.hxmk_jb.cs_hx_9g[0]))
            // this.setState(this.cfNum,(this.props.value.hxmk_jb.cs_hx_10c && this.props.value.hxmk_jb.cs_hx_10c[0]))
            // this.setState(this.cs_hx_hxmj_min,(this.props.value.hxmk_jb.cs_hx_hxmj_min&&this.props.value.hxmk_jb.cs_hx_hxmj_min[0]))
            // this.setState(this.cs_hx_hxmj_max,(this.props.value.hxmk_jb.cs_hx_hxmj_max&&this.props.value.hxmk_jb.cs_hx_hxmj_max[0]))
            // this.setState(this.cs_hx_tlxhxs,(this.props.value.hxmk_jb.cs_hx_tlxhxs&&this.props.value.hxmk_jb.cs_hx_tlxhxs[0]))
        }else if (this.props.value[0]!= undefined){
            this.setState(this.cs_hx_1g,this.props.value[0].hxmk_jb.cs_hx_1g &&this.props.value[0].hxmk_jb.cs_hx_1g[0])
            this.setState(this.ktNum,this.props.value[0].hxmk_jb.cs_hx_2k &&this.props.value[0].hxmk_jb.cs_hx_2k[0])
            this.setState(this.cs_hx_3g,this.props.value[0].hxmk_jb.cs_hx_3g&&this.props.value[0].hxmk_jb.cs_hx_3g[0])
            this.setState(this.ctNum,this.props.value[0].hxmk_jb.cs_hx_4c&&this.props.value[0].hxmk_jb.cs_hx_4c[0])
            this.setState(this.cs_hx_5g,this.props.value[0].hxmk_jb.cs_hx_5g&&this.props.value[0].hxmk_jb.cs_hx_5g[0])
            this.setState(this.wsNum,this.props.value[0].hxmk_jb.cs_hx_6w&&this.props.value[0].hxmk_jb.cs_hx_6w[0])
            this.setState(this.cs_hx_7g,this.props.value[0].hxmk_jb.cs_hx_7g&&this.props.value[0].hxmk_jb.cs_hx_7g[0])
            this.setState(this.wsjNum,this.props.value[0].hxmk_jb.cs_hx_8w&&this.props.value[0].hxmk_jb.cs_hx_8w[0])
            this.setState(this.cs_hx_9g,this.props.value[0].hxmk_jb.cs_hx_9g&&this.props.value[0].hxmk_jb.cs_hx_9g[0])
            this.setState(this.cfNum,this.props.value[0].hxmk_jb.cs_hx_10c&&this.props.value[0].hxmk_jb.cs_hx_10c[0])
            this.setState(this.cs_hx_hxmj_min,this.props.value[0].hxmk_jb.cs_hx_hxmj_min&&this.props.value[0].hxmk_jb.cs_hx_hxmj_min[0])
            this.setState(this.cs_hx_hxmj_max,this.props.value[0].hxmk_jb.cs_hx_hxmj_max&&this.props.value[0].hxmk_jb.cs_hx_hxmj_max[0])
            this.setState(this.cs_hx_tlxhxs,this.props.value[0].hxmk_jb.cs_hx_tlxhxs && this.props.value[0].hxmk_jb.cs_hx_tlxhxs[0])
        }
    }
    getIndex(i){
        const index={
            1:'①',
            2:'②',
            3:'③',
            4:'④',
            5:'⑤',
            6:'⑥',
            7:'⑦',
            8:'⑧',
            9:'⑨',
        }
        return index[i]
    }
    //筛选参数
    parameters() {
        global.Ajax.hhtc_request('home/screen').then(res => {
            if (res.code == 1) {
                this.setState({

                    province: res.data.comprehensive_dl.province,//目标省份
                    kfdw: res.data.comprehensive_dl.comprehensive_dl_kfdw,//目标城市
                    rzyq: res.data.comprehensive_dl.comprehensive_dl_rzyq,//日照要求
                    dx: res.data.comprehensive_dl.comprehensive_dl_dx,//适用地形
                    cpyt: res.data.comprehensive_gj.comprehensive_gj_cpyt,//产品业态
                    cssx: res.data.comprehensive_gj.comprehensive_gj_cssx,//适用层数
                    jfzc: res.data.comprehensive_gj.comprehensive_gj_cpyt,//交付支持

                    hxtlx: res.data.building_hxt.building_hxt_lx,//核心筒位置
                    ldtgx: res.data.building_hxt.building_hxt_ldt,//楼电梯关系
                    mc: res.data.building_hxt.building_hxt_mc,//核心筒明窗

                    // 拓展特性
                    bzcxz: res.data.building_tz.building_tz_bzc,//楼型形状
                    btzh: res.data.building_tz.building_tz_bt,//板塔关系
                    pjgx: res.data.building_tz.building_tz_pj,//拼接支持

                    //building_cf 厨房布局
                    cfbj: res.data.building_cf.building_cf_cf,//厨房布局  building_cf_cf
                    ytgl: res.data.building_cf.building_cf_yt,//阳台关联  building_cf_dl
                    dlzxcf: res.data.building_cf.building_cf_dl,//中西分厨  building_cf_yt

                    //building_kt
                    ktzx: res.data.building_kt.building_kt_kt,//ktzx
                    dt: res.data.building_kt.building_kt_dt,//端厅配置
                    dlzxct: res.data.building_kt.building_kt_dl,//

                    //building_ws
                    dlymj: res.data.building_ws.building_ws_dlj,//衣帽间类型
                    hwsj: res.data.building_ws.building_ws_hwsj,//独卫配置
                    wsjbj: res.data.building_ws.building_ws_bj,//卫生间布局
                    wsjgsfl: res.data.building_ws.building_ws_gsfl,//干湿分离
                    cjgnfjct: res.data.building_ws.building_ws_fjct,//房间串套
                    pc: res.data.building_ws.building_ws_pc,//飘窗赠送

                    //公卫特征
                    gwwsjbj: res.data.building_ws.building_ws_wsjbj,//卫生间布局
                    gwwsjgsfl: res.data.building_ws.building_ws_wsjfsfl,//干湿分离
                    gwcjgnfrk: res.data.building_ws.building_ws_rkhy,//干区合用

                    //赠送特征
                    hxsx: res.data.building_zs.building_zs_hxsx,//赠送特征-户型竖向

                    //敏感特征
                    mgktjw: res.data.building_zs.building_zs_ktjw,//敏感特征-空调机位
                    mgxg: res.data.building_zs.building_zs_xg,//敏感特征-玄关类型
                    mgzwcx: res.data.building_zs.building_zs_zwcx,//敏感特征-主卧朝向

                    //标准参数
                    gfxfsj: res.data.standard_gf.standard_gf_xfsj,//规范特征-消防设计规范
                    gfmjjs: res.data.standard_gf.standard_gf_mjjs,//规范特征-面积计算规范

                    tzsd: res.data.standard_gf.standard_gf_tzsd,//成果特征-面世年度
                    tzxx: res.data.standard_gf.standard_gf_tzxx,//成果特征-市场响应

                })
            } else {
                message.error(res.msg)
            }
        })
    }

    change(key,index, value){
        if(!value){
            this.setState({
                [key]:'',
            }, () => {
            })
        }else{
            if(!this.state[key]){
                this.state[key]=new Array()
            }
            this.state[key][index]=value
            this.setState({
                [key]: this.state[key]
            }, () => {
            })
        }

    }


    inputOnchange(key,index,e) {
        if(!this.state[key]){
            this.state[key]=new Array()
        }
        this.state[key][index]= e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]
        this.setState({
            [key]: this.state[key]
        })
    }
    render() {
        console.log(this.props.value)
        // (this.props.value?.hxmk_kt?.cs_kt_ktmk_max && this.props.value.hxmk_kt.cs_kt_ktmk_max[0] )||
        // (this.props.value[0]?.hxmk_kt?.cs_kt_ktmk_max && this.props.value[0].hxmk_kt.cs_kt_ktmk_max[0] )


        const icon = (
            <span className='xsj'></span>
        )
        //卧室特征
        var list = (res) => {
            var data = []
            for (var i = 0; i < res; i++) {
                data.push(
                    <div className='row1' key={'' + i}>
                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>卧室{this.getIndex(i + 1)}特征</p>
                        <div className='tablist'>
                            <div className='itee item50'>
                                <p className='t1'>卧室进深：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_wsjs_min ===undefined ? null : this.state.cs_wsjs_min[i]} onChange={this.inputOnchange.bind(this,'cs_wsjs_min',i)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_wsjs_max ===undefined ? null : this.state.cs_wsjs_max[i]} onChange={this.inputOnchange.bind(this,'cs_wsjs_max',i)} />
                                </p>
                            </div>
                            <div className='itee item50'>
                                <p className='t1'>卧室面宽：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_wsmk_min ===undefined ? null : this.state.cs_wsmk_min[i]} onChange={this.inputOnchange.bind(this, 'cs_wsmk_min',i)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_wsmk_max ===undefined ? null : this.state.cs_wsmk_max[i]} onChange={this.inputOnchange.bind(this, 'cs_wsmk_max',i)} />
                                </p>
                            </div>
                            <div className='itee item50' id='dlymj'>
                                <p className='t1'>衣帽间类型：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('dlymj')}
                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_ws_dlymj',i)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.dlymj.map((item, index) => (
                                        <Option key={'dlymj' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item50 mar_bot' id='cjgnfjct'>
                                <p className='t1'>房间串套：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('cjgnfjct')}
                                    placeholder='请选择' className='t2' suffixIcon={icon}
                                    onChange={this.change.bind(this, 'cs_ws_cjgnfjct',i)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.cjgnfjct.map((item, index) => (
                                        <Option key={'cjgnfjct' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item50' id='hwsj'>
                                <p className='t1'>独卫配置：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('hwsj')}
                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_ws_hwsj',i)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.hwsj.map((item, index) => (
                                        <Option key={'hwsj' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item50 mar_bot' id='pc'>
                                <p className='t1'>飘窗赠送：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('pc')}
                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_ws_pc',i)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.pc.map((item, index) => (
                                        <Option key={'pc' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>




                        </div>
                    </div>
                )
            }
            return data
        }
        //厨房特征
        var list1 = (res) => {
            var data = []
            for (var a = 0; a < res; a++) {
                data.push(
                    <div className='row1' key={'' + a}>
                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>厨房{this.getIndex(a + 1)}特征</p>
                        <div className='tablist'>
                            <div className='itee item50' id='cfbj'>
                                <p className='t1'>厨房布局：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('cfbj')}
                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_cf_cfbj',a)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.cfbj.map((item, index) => (
                                        <Option key={'cfbj' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item50' id='ytgl'>
                                <p className='t1'>阳台关联：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('ytgl')}
                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_cf_ytgl',a)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.ytgl.map((item, index) => (
                                        <Option key={'ytgl' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item50'>
                                <p className='t1'>厨房面宽：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_cf_cfmk_min ===undefined ? null : this.state.cs_cf_cfmk_min[a]}  onChange={this.inputOnchange.bind(this, 'cs_cf_cfmk_min',a)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-'  value={this.state.cs_cf_cfmk_max ===undefined ? null : this.state.cs_cf_cfmk_max[a]}  onChange={this.inputOnchange.bind(this, 'cs_cf_cfmk_max',a)} />
                                </p>
                            </div>
                            <div className='itee item50'>
                                <p className='t1'>厨房进深：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_cf_cfjs_min ===undefined ? null : this.state.cs_cf_cfjs_min[a]}  onChange={this.inputOnchange.bind(this, 'cs_cf_cfjs_min',a)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_cf_cfjs_max ===undefined ? null : this.state.cs_cf_cfjs_max[a]}  onChange={this.inputOnchange.bind(this, 'cs_cf_cfjs_max',a)} />
                                </p>
                            </div>
                            <div className='itee item100' id='dlzxcf'>
                                <p className='t1'>中西分厨：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('dlzxcf')}
                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_cf_dlzxcf',a)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.dlzxcf.map((item, index) => (
                                        <Option key={'dlzxcf' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )
            }
            return data
        }

        //客厅特征
        var list2 = (res) => {
            var data = []
            for (var b = 0; b < res; b++) {
                data.push(
                    <div className='row1' key={'' + b}>
                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>客厅{this.getIndex(b + 1)}特征</p>
                        <div className='tablist'>
                            <div className='itee item50'>
                                <p className='t1'>客厅进深：</p>
                                <p className='t2'>
                                    <input placeholder='-'  value={this.state.cs_kt_ktjs_min ===undefined ? null : this.state.cs_kt_ktjs_min[b]}  onChange={this.inputOnchange.bind(this, 'cs_kt_ktjs_min',b)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-'  value={this.state.cs_kt_ktjs_max ===undefined ? null : this.state.cs_kt_ktjs_max[b]}  onChange={this.inputOnchange.bind(this, 'cs_kt_ktjs_max',b)} />
                                </p>
                            </div>
                            <div className='itee item50'>
                                <p className='t1'>客厅面宽：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_kt_ktmk_min ===undefined ? null : this.state.cs_kt_ktmk_min[b]} onChange={this.inputOnchange.bind(this, 'cs_kt_ktmk_min',b)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_kt_ktmk_max ===undefined ? null : this.state.cs_kt_ktmk_max[b]} onChange={this.inputOnchange.bind(this, 'cs_kt_ktmk_max',b)} />
                                </p>
                            </div>
                            <div className='itee item100 mar_bot10' id='ktzx'>
                                <p className='t1'>客厅轴向：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('ktzx')}
                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_kt_ktzx',b)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.ktzx.map((item, index) => (
                                        <Option key={'ktzx' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='itee item100 mar_bot' id='dt11'>
                                <p className='t1'>端厅配置：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('dt11')}
                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_kt_dt',b)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.dt.map((item, index) => (
                                        <Option key={'dt' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )

            }
            return data
        }

        //餐厅特征
        var list3 = (res) => {
            var data = []
            for (var c = 0; c < res; c++) {
                data.push(
                    <div className='row1' key={'' + c}>
                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>餐厅{this.getIndex(c + 1)}特征</p>
                        <div className='tablist'>
                            <div className='itee item50'>
                                <p className='t1'>餐厅进深：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_ct_ctjs_min ===undefined ? null : this.state.cs_ct_ctjs_min[c]} onChange={this.inputOnchange.bind(this, 'cs_ct_ctjs_min',c)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_ct_ctjs_max ===undefined ? null : this.state.cs_ct_ctjs_max[c]} onChange={this.inputOnchange.bind(this, 'cs_ct_ctjs_max',c)} />
                                </p>
                            </div>
                            <div className='itee item50'>
                                <p className='t1'>餐厅面宽：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_ct_ctmk_min ===undefined ? null : this.state.cs_ct_ctmk_min[c]} onChange={this.inputOnchange.bind(this, 'cs_ct_ctmk_min',c)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_ct_ctmk_max ===undefined ? null : this.state.cs_ct_ctmk_max[c]} onChange={this.inputOnchange.bind(this, 'cs_ct_ctmk_max',c)} />
                                </p>
                            </div>
                            <div className='itee item100' id='dlzxct11'>
                                <p className='t1'>中西分餐：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('dlzxct11')}
                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_ct_dlzxct',c)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.dlzxct.map((item, index) => (
                                        <Option key={'dlzxct' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )

            }
            return data
        }

        //卫生间
        var list4 = (res) => {
            var data = []
            for (var d = 0; d < res; d++) {
                data.push(
                    <div className='row1' key={'' + d}>
                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>卫{this.getIndex(d + 1)}特征</p>
                        <div className='tablist'>
                            <div className='itee item50'>
                                <p className='t1'>卫生间面宽：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_gw_wsjmk_min ===undefined ? null : this.state.cs_gw_wsjmk_min[d]} onChange={this.inputOnchange.bind(this, 'cs_gw_wsjmk_min',d)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_gw_wsjmk_max ===undefined ? null : this.state.cs_gw_wsjmk_max[d]} onChange={this.inputOnchange.bind(this, 'cs_gw_wsjmk_max',d)} />
                                </p>
                            </div>

                            <div className='itee item50'>
                                <p className='t1'>卫生间进深：</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_gw_wsjjs_min ===undefined ? null : this.state.cs_gw_wsjjs_min[d]} onChange={this.inputOnchange.bind(this, 'cs_gw_wsjjs_min',d)} />
                                </p>
                                <p className='t1 mar_10'>至</p>
                                <p className='t2'>
                                    <input placeholder='-' value={this.state.cs_gw_wsjjs_max ===undefined ? null : this.state.cs_gw_wsjjs_max[d]} onChange={this.inputOnchange.bind(this, 'cs_gw_wsjjs_max',d)} />
                                </p>
                            </div>

                            <div className='itee item50 mar_bot10' id='wsjbj11'>
                                <p className='t1'>卫生间布局：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('wsjbj11')}
                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_gw_wsjbj',d)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.gwwsjbj.map((item, index) => (
                                        <Option key={'gwwsjbj' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>

                            <div className='itee item50 mar_bot10' id='wsjgsfl111'>
                                <p className='t1'>干湿分离：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('wsjgsfl111')}
                                    placeholder='请选择' className='t2' suffixIcon={icon}
                                    onChange={this.change.bind(this, 'cs_gw_wsjgsfl',d)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.gwwsjgsfl.map((item, index) => (
                                        <Option key={'gwwsjgsfl' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>

                            <div className='itee item100' id='cjgnrkhy'>
                                <p className='t1'>干区合用：</p>
                                <Select
                                    getPopupContainer={()=>document.getElementById('cjgnrkhy')}
                                    placeholder='请选择' className='t2' suffixIcon={icon}
                                    onChange={this.change.bind(this, 'cs_gw_cjgnf',d)}>
                                    <Option value={''}>全部</Option>
                                    {this.state.gwcjgnfrk.map((item, index) => (
                                        <Option key={'gwcjgnfrk' + index} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )

            }
            return data
        }
        return (
            <div>
                {this.props.index > 0 ? <>
                    <div style={{position: 'relative'}}>
                        <div  style={{position: 'absolute',top: 0,left: 0, right:0, bottom:0,zIndex: 1,
                            paddingTop: '200px',
                            backgroundColor: 'rgba(0,0,0,0.6)',fontSize:'28px', textAlign: 'center', color: '#fff'}}>
                            【多户型参数交叉筛选需高级权限，暂未开放】
                        </div>
                        <div className='tabble tabble2'>
                            <h2 style={{ backgroundColor: "#55CFBC" }}>户型{this.getIndex(this.props.index+1)}参数</h2>
                            <div className='colbox'>
                                <div className='col2'>
                                    <div className='row1'>
                                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>基本特征</p>

                                        <div className='tablist'>

                                            <div className='itee item100' style={{ marginBottom: 10, background: '#55CFBC' }}>
                                                <p className='t1 color_white'>房型配置：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_1g && this.state.cs_hx_1g[0]}  onChange={this.inputOnchange.bind(this,'cs_hx_1g',0)} />
                                                </p>
                                                <p className='t1 mar_2'>改</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.ktNum} onChange={(e) => {
                                                        this.inputOnchange('cs_hx_2k',0,e)
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            ktNum: e.target.value
                                                        })
                                                    }} />
                                                </p>
                                                <p className='t1 mar_2'>客厅</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_3g && this.state.cs_hx_3g[0]} onChange={this.inputOnchange.bind(this, 'cs_hx_3g',0)} />
                                                </p>
                                                <p className='t1 mar_2'>改</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.ctNum} onChange={(e) => {
                                                        this.inputOnchange('cs_hx_4c',0,e)
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            ctNum: e.target.value
                                                        })
                                                    }} />


                                                </p>
                                                <p className='t1 mar_2'>餐厅</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_5g && this.state.cs_hx_5g[0]} onChange={this.inputOnchange.bind(this, 'cs_hx_5g',0)} />
                                                </p>
                                                <p className='t1 mar_2'>改</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.wsNum} onChange={(e) => {
                                                        this.inputOnchange('cs_hx_6w',0,e)
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            wsNum: e.target.value
                                                        })
                                                    }} />
                                                </p>
                                                <p className='t1 mar_2'>卧室</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_7g && this.state.cs_hx_7g[0]} onChange={this.inputOnchange.bind(this, 'cs_hx_7g',0)} />
                                                </p>
                                                <p className='t1 mar_2'>改</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.wsjNum} onChange={(e) => {
                                                        this.inputOnchange('cs_hx_8w',0,e)
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            wsjNum: e.target.value
                                                        })
                                                    }} />

                                                </p>
                                                <p className='t1 mar_2'>卫生间</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_9g && this.state.cs_hx_9g[0]}  onChange={this.inputOnchange.bind(this, 'cs_hx_9g',0)} />
                                                </p>
                                                <p className='t1 mar_2'>改</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cfNum} onChange={(e) => {
                                                        this.inputOnchange('cs_hx_10c',0,e)
                                                        if (e.target.value > 10) {
                                                            return
                                                        }
                                                        this.setState({
                                                            cfNum: e.target.value
                                                        })
                                                    }} />


                                                </p>
                                                <p className='t1 mar_2'>厨房</p>
                                            </div>

                                            <div className='itee item45' style={{marginBottom:'0px'}}>
                                                <p className='t1'>户型建面：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_hxmj_min ===undefined ? null : this.state.cs_hx_hxmj_min[0]}  onChange={this.inputOnchange.bind(this, 'cs_hx_hxmj_min',0)} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_hxmj_max ===undefined ? null : this.state.cs_hx_hxmj_max[0]} onChange={this.inputOnchange.bind(this, 'cs_hx_hxmj_max',0)} />
                                                </p>
                                            </div>

                                            <div className='itee item55 mar_bot' style={{marginBottom:'0px'}}>
                                                <p className='t1'>相同户数：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_hx_tlxhxs ===undefined ? null : this.state.cs_hx_tlxhxs[0]} onChange={this.inputOnchange.bind(this, 'cs_hx_tlxhxs',0)} />
                                                </p>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className='col3 mar_bot10'>
                                    {/* 厨房特征 */}
                                    {list1(this.state.cfNum > 0 ? this.state.cfNum : 1)}

                                    {/* 客厅特征 */}
                                    {list2(this.state.ktNum > 0 ? this.state.ktNum : 1)}


                                </div>
                                <div className='col3 mar_bot10'>

                                    {/* 卧室特征 */}
                                    {list(this.state.wsNum > 0 ? this.state.wsNum : 1)}

                                    {/* 公卫特征 */}
                                    {list4(this.state.wsjNum > 0 ? this.state.wsjNum : 1)}
                                </div>
                                <div className='col3'>

                                    {/* 餐厅特征 */}
                                    {list3(this.state.ctNum > 0 ? this.state.ctNum : 1)}

                                    <div className='row1'>
                                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>赠送特征</p>
                                        <div className='tablist'>
                                            <div className='itee item50' id='hxsx111'>
                                                <p className='t1'>户型竖向：</p>
                                                <Select
                                                    getPopupContainer={()=>document.getElementById('hxsx111')}
                                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_zs_hxsx',0)}>
                                                    <Option value={''}>全部</Option>
                                                    {this.state.hxsx.map((item, index) => (
                                                        <Option key={'hxsx' + index} value={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item50'>
                                                <p className='t1'>阳台占比：</p>
                                                <p className='t2'>
                                                    <input placeholder='-'  value={this.state.cs_gw_ytzbl_min ===undefined ? null : this.state.cs_gw_ytzbl_min[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_ytzbl_min',0)} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-'  value={this.state.cs_gw_ytzbl_max ===undefined ? null : this.state.cs_gw_ytzbl_max[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_ytzbl_max',0)} />
                                                </p>
                                            </div>
                                            <div className='itee item100'>
                                                <p className='t1'>赠送比例：</p>
                                                <p className='t2'>
                                                    <input placeholder='-'  value={this.state.cs_gw_zsl_min ===undefined ? null : this.state.cs_gw_zsl_min[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_zsl_min',0)} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-'  value={this.state.cs_gw_zsl_max ===undefined ? null : this.state.cs_gw_zsl_max[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_zsl_max',0)} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row1'>
                                        <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>敏感特征</p>
                                        <div className='tablist'>
                                            <div className='itee item50'>
                                                <p className='t1'>面宽数量：</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_mg_mks_min ===undefined ? null : this.state.cs_mg_mks_min[0]}  onChange={this.inputOnchange.bind(this, 'cs_mg_mks_min',0)} />
                                                </p>
                                                <p className='t1 mar_10'>至</p>
                                                <p className='t2'>
                                                    <input placeholder='-' value={this.state.cs_mg_mks_max ===undefined ? null : this.state.cs_mg_mks_max[0]}  onChange={this.inputOnchange.bind(this, 'cs_mg_mks_max',0)} />
                                                </p>
                                            </div>
                                            <div className='itee item50' id='ktjw1111'>
                                                <p className='t1'>空调机位：</p>
                                                <Select
                                                    getPopupContainer={()=>document.getElementById('ktjw1111')}
                                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_mg_ktjw',0)}>
                                                    <Option value={''}>全部</Option>
                                                    {this.state.mgktjw.map((item, index) => (
                                                        <Option key={'mgktjw' + index} value={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item50 mar_bot' id='xg111'>
                                                <p className='t1'>玄关类型：</p>
                                                <Select
                                                    getPopupContainer={()=>document.getElementById('xg111')}
                                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_mg_xg',0)}>
                                                    <Option value={''}>全部</Option>
                                                    {this.state.mgxg.map((item, index) => (
                                                        <Option key={'mgxg' + index} value={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className='itee item50 mar_bot' id='zwcx123'>
                                                <p className='t1'>主卧朝向：</p>
                                                <Select
                                                    getPopupContainer={()=>document.getElementById('zwcx123')}
                                                    placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_mg_zwcx',0)}>
                                                    <Option value={''}>全部</Option>
                                                    {this.state.mgzwcx.map((item, index) => (
                                                        <Option key={'mgzwcx' + index} value={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </> : <>
                    <div className='tabble tabble2'>
                        <h2 style={{ backgroundColor: "#55CFBC" }}>户型{this.getIndex(this.props.index+1)}参数</h2>
                        <div className='colbox'>
                            <div className='col2'>
                                <div className='row1'>
                                    <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>基本特征</p>

                                    <div className='tablist'>

                                        <div className='itee item100' style={{ marginBottom: 10, background: '#55CFBC' }}>
                                            <p className='t1 color_white'>房型配置：</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_hx_1g && this.state.cs_hx_1g[0]}  onChange={this.inputOnchange.bind(this,'cs_hx_1g',0)} />
                                            </p>
                                            <p className='t1 mar_2'>改</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.ktNum} onChange={(e) => {
                                                    this.inputOnchange('cs_hx_2k',0,e)
                                                    if (e.target.value > 10) {
                                                        return
                                                    }
                                                    this.setState({
                                                        ktNum: e.target.value
                                                    })
                                                }} />
                                            </p>
                                            <p className='t1 mar_2'>客厅</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_hx_3g && this.state.cs_hx_3g[0]} onChange={this.inputOnchange.bind(this, 'cs_hx_3g',0)} />
                                            </p>
                                            <p className='t1 mar_2'>改</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.ctNum} onChange={(e) => {
                                                    this.inputOnchange('cs_hx_4c',0,e)
                                                    if (e.target.value > 10) {
                                                        return
                                                    }
                                                    this.setState({
                                                        ctNum: e.target.value
                                                    })
                                                }} />


                                            </p>
                                            <p className='t1 mar_2'>餐厅</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_hx_5g && this.state.cs_hx_5g[0]} onChange={this.inputOnchange.bind(this, 'cs_hx_5g',0)} />
                                            </p>
                                            <p className='t1 mar_2'>改</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.wsNum} onChange={(e) => {
                                                    this.inputOnchange('cs_hx_6w',0,e)
                                                    if (e.target.value > 10) {
                                                        return
                                                    }
                                                    this.setState({
                                                        wsNum: e.target.value
                                                    })
                                                }} />
                                            </p>
                                            <p className='t1 mar_2'>卧室</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_hx_7g && this.state.cs_hx_7g[0]} onChange={this.inputOnchange.bind(this, 'cs_hx_7g',0)} />
                                            </p>
                                            <p className='t1 mar_2'>改</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.wsjNum} onChange={(e) => {
                                                    this.inputOnchange('cs_hx_8w',0,e)
                                                    if (e.target.value > 10) {
                                                        return
                                                    }
                                                    this.setState({
                                                        wsjNum: e.target.value
                                                    })
                                                }} />

                                            </p>
                                            <p className='t1 mar_2'>卫生间</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_hx_9g && this.state.cs_hx_9g[0]}  onChange={this.inputOnchange.bind(this, 'cs_hx_9g',0)} />
                                            </p>
                                            <p className='t1 mar_2'>改</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cfNum} onChange={(e) => {
                                                    this.inputOnchange('cs_hx_10c',0,e)
                                                    if (e.target.value > 10) {
                                                        return
                                                    }
                                                    this.setState({
                                                        cfNum: e.target.value
                                                    })
                                                }} />


                                            </p>
                                            <p className='t1 mar_2'>厨房</p>
                                        </div>

                                        <div className='itee item45' style={{marginBottom:'0px'}}>
                                            <p className='t1'>户型建面：</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_hx_hxmj_min ===undefined ? null : this.state.cs_hx_hxmj_min[0]}  onChange={this.inputOnchange.bind(this, 'cs_hx_hxmj_min',0)} />
                                            </p>
                                            <p className='t1 mar_10'>至</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_hx_hxmj_max ===undefined ? null : this.state.cs_hx_hxmj_max[0]} onChange={this.inputOnchange.bind(this, 'cs_hx_hxmj_max',0)} />
                                            </p>
                                        </div>

                                        <div className='itee item55 mar_bot' style={{marginBottom:'0px'}}>
                                            <p className='t1'>相同户数：</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_hx_tlxhxs ===undefined ? null : this.state.cs_hx_tlxhxs[0]} onChange={this.inputOnchange.bind(this, 'cs_hx_tlxhxs',0)} />
                                            </p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className='col3 mar_bot10'>
                                {/* 厨房特征 */}
                                {list1(this.state.cfNum > 0 ? this.state.cfNum : 1)}

                                {/* 客厅特征 */}
                                {list2(this.state.ktNum > 0 ? this.state.ktNum : 1)}


                            </div>
                            <div className='col3 mar_bot10'>

                                {/* 卧室特征 */}
                                {list(this.state.wsNum > 0 ? this.state.wsNum : 1)}

                                {/* 公卫特征 */}
                                {list4(this.state.wsjNum > 0 ? this.state.wsjNum : 1)}
                            </div>
                            <div className='col3'>

                                {/* 餐厅特征 */}
                                {list3(this.state.ctNum > 0 ? this.state.ctNum : 1)}

                                <div className='row1'>
                                    <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>赠送特征</p>
                                    <div className='tablist'>
                                        <div className='itee item50' id='hxsx111'>
                                            <p className='t1'>户型竖向：</p>
                                            <Select
                                                getPopupContainer={()=>document.getElementById('hxsx111')}
                                                placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_zs_hxsx',0)}>
                                                <Option value={''}>全部</Option>
                                                {this.state.hxsx.map((item, index) => (
                                                    <Option key={'hxsx' + index} value={item.id}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='itee item50'>
                                            <p className='t1'>阳台占比：</p>
                                            <p className='t2'>
                                                <input placeholder='-'  value={this.state.cs_gw_ytzbl_min ===undefined ? null : this.state.cs_gw_ytzbl_min[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_ytzbl_min',0)} />
                                            </p>
                                            <p className='t1 mar_10'>至</p>
                                            <p className='t2'>
                                                <input placeholder='-'  value={this.state.cs_gw_ytzbl_max ===undefined ? null : this.state.cs_gw_ytzbl_max[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_ytzbl_max',0)} />
                                            </p>
                                        </div>
                                        <div className='itee item100'>
                                            <p className='t1'>赠送比例：</p>
                                            <p className='t2'>
                                                <input placeholder='-'  value={this.state.cs_gw_zsl_min ===undefined ? null : this.state.cs_gw_zsl_min[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_zsl_min',0)} />
                                            </p>
                                            <p className='t1 mar_10'>至</p>
                                            <p className='t2'>
                                                <input placeholder='-'  value={this.state.cs_gw_zsl_max ===undefined ? null : this.state.cs_gw_zsl_max[0]} onChange={this.inputOnchange.bind(this, 'cs_gw_zsl_max',0)} />
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='row1'>
                                    <p className='titt' style={{ backgroundColor: "#7DD3C6" }}>敏感特征</p>
                                    <div className='tablist'>
                                        <div className='itee item50'>
                                            <p className='t1'>面宽数量：</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_mg_mks_min ===undefined ? null : this.state.cs_mg_mks_min[0]}  onChange={this.inputOnchange.bind(this, 'cs_mg_mks_min',0)} />
                                            </p>
                                            <p className='t1 mar_10'>至</p>
                                            <p className='t2'>
                                                <input placeholder='-' value={this.state.cs_mg_mks_max ===undefined ? null : this.state.cs_mg_mks_max[0]}  onChange={this.inputOnchange.bind(this, 'cs_mg_mks_max',0)} />
                                            </p>
                                        </div>
                                        <div className='itee item50' id='ktjw1111'>
                                            <p className='t1'>空调机位：</p>
                                            <Select
                                                getPopupContainer={()=>document.getElementById('ktjw1111')}
                                                placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_mg_ktjw',0)}>
                                                <Option value={''}>全部</Option>
                                                {this.state.mgktjw.map((item, index) => (
                                                    <Option key={'mgktjw' + index} value={item.id}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='itee item50 mar_bot' id='xg111'>
                                            <p className='t1'>玄关类型：</p>
                                            <Select
                                                getPopupContainer={()=>document.getElementById('xg111')}
                                                placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_mg_xg',0)}>
                                                <Option value={''}>全部</Option>
                                                {this.state.mgxg.map((item, index) => (
                                                    <Option key={'mgxg' + index} value={item.id}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='itee item50 mar_bot' id='zwcx123'>
                                            <p className='t1'>主卧朝向：</p>
                                            <Select
                                                getPopupContainer={()=>document.getElementById('zwcx123')}
                                                placeholder='请选择' className='t2' suffixIcon={icon} onChange={this.change.bind(this, 'cs_mg_zwcx',0)}>
                                                <Option value={''}>全部</Option>
                                                {this.state.mgzwcx.map((item, index) => (
                                                    <Option key={'mgzwcx' + index} value={item.id}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </>
                }
            </div>


        )
    }
}
