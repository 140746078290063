import React from "react";
import {Affix, Button, Carousel, Col, Pagination, Row,} from 'antd';

export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            page: 1,
            size: 10,
            total: 0,
            album:[],
            news:[],
            category:[],//类别
            category_news:[],// 三个热门新闻
            banner:[],
            articlesDetail:[],
            nowIndexTab:[
                'recommend','recommend','recommend'
            ],
        }
    }
    componentDidMount() {
        document.title = '数聚房投-专注数据量化的地产咨询平台'
        this.getArticles()
        this.getMore(1,10)
        this.getArticlesDetail()
    }
    getArticles(){
        global.Ajax.hhtc_request('Articles/index').then(res => {
            console.log(res,'res')
            this.setState({
                album:res.data.album,
                category:res.data.category,
                category_news:res.data.category_news,
                banner:res.data.banners
            })
        })

    }
    getMore(page,size){
        // Articles/loadMore
        global.Ajax.hhtc_request('Articles/loadMore',{page,size}).then(res => {
            console.log(res.data.news.total,'res')
            this.setState({
                news: res.data.news.data,
                total: res.data.news.total,
            })
        })
    }
    //推荐文章
    getArticlesDetail(){
        global.Ajax.hhtc_request('Articles/recommend').then(res => {
            console.log(res,'recommend')
            this.setState({
                articlesDetail:res.data,
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className='padd_top66 w1400'>
                    {
                        this.state.category_news.map((item,index)=>{
                            return(
                                <div className='contentBox'>
                                <h4 className='titleFlex'>
                                    <span className='itemTitle'>{item.name}</span>
                                    <span className="moreTitle" onClick={() => {
                                        //type 是搜索分类  1 是关键字， 2 是类别  3 是专辑ID
                                        this.props.history.push(`/rout/newsSearch/2/${item.category_id}` )
                                    }}>更多</span>
                                </h4>
                                    <Row gutter={16}>
                                        <Col span={14}>
                                            <div className='autoBox' onClick={()=>{
                                                window.open('#/rout/newsDetail/'+ item.news.id)
                                            }}>
                                                <img className='autoW' src={item.news.main_image} alt=""/>
                                                <h4 className='auto'>
                                                    <a>{item.news.title}</a>
                                                </h4>
                                            </div>
                                        </Col>
                                        <Col span={10}>
                                            <div>
                                                <div className='itemBtnBox'>
                                                    <Button  className={this.state.nowIndexTab[index]==='recommend' ? 'itemBtnActive':'itemBtn'} onClick={()=>{
                                                        const indexs=this.state.nowIndexTab
                                                        indexs[index]='recommend'
                                                        this.setState({
                                                        nowIndexTab:indexs
                                                    })}}> {item.recommend.name}</Button>
                                                    <Button  className={this.state.nowIndexTab[index]==='hot' ? 'itemBtnActive':'itemBtn'}
                                                            onClick={()=>{
                                                        const indexs=this.state.nowIndexTab
                                                        indexs[index]='hot'
                                                        this.setState({
                                                            nowIndexTab:indexs
                                                        })}}>{item.hot.name}</Button>
                                                    <Button  className={this.state.nowIndexTab[index]==='floor' ? 'itemBtnActive':'itemBtn'}
                                                            onClick={()=>{
                                                        const indexs=this.state.nowIndexTab
                                                        indexs[index]='floor'
                                                        this.setState({
                                                            nowIndexTab: indexs
                                                        })}}>{item.floor.name}</Button>
                                                </div>
                                                {this.state.nowIndexTab[index]==='recommend' ?
                                                    item.recommend.news&& item.recommend.news.map(reItem=>{
                                                        return  <div className='itemContentBox' onClick={()=>{
                                                            window.open('#/rout/newsDetail/'+ reItem.id)
                                                        }}>
                                                            <div className='contentItem'>
                                                                <img className='itemImg' src={reItem.main_image} alt=""/>
                                                                <div className='textF'>
                                                                    <h3 className='textBox'>{reItem.title}</h3>
                                                                    <div className='describe'>{reItem.new_describe}</div>
                                                                    <div className='otherInfo'>
                                                                        <span>{reItem.author}</span>
                                                                        <span>{reItem.atime.substring(0,11)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }): this.state.nowIndexTab[index]==='hot' ? item.hot.news.map(reItem=>{
                                                        return  <div className='itemContentBox'
                                                                     onClick={()=>{
                                                                         window.open('#/rout/newsDetail/'+ reItem.id)
                                                                     }}>
                                                            <div className='contentItem'>
                                                                <img className='itemImg' src={reItem.main_image} alt=""/>
                                                                <div className='textF'>
                                                                    <h3 className='textBox'>{reItem.title}</h3>
                                                                    <div className='describe'>{reItem.new_describe}</div>
                                                                    <div className='otherInfo'>
                                                                        <span>{reItem.author}</span>
                                                                        <span>{reItem.atime.substring(0,11)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }) : item.floor.news.map(reItem=>{
                                                        return  <div className='itemContentBox' onClick={()=>{
                                                            window.open('#/rout/newsDetail/'+ reItem.id)
                                                        }}>
                                                            <div className='contentItem'>
                                                                <img className='itemImg' src={reItem.main_image} alt=""/>
                                                                <div className='textF'>
                                                                    <h3 className='textBox'>{reItem.title}</h3>
                                                                    <div className='describe'>{reItem.new_describe}</div>
                                                                    <div className='otherInfo'>
                                                                        <span>{reItem.author}</span>
                                                                        <span>{reItem.atime.substring(0,11)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }
                    <Carousel style={{marginTop:'20px'}} autoplay>
                        {this.state.banner.map((bannerItem,bannerIndex) => (
                            <a target='_blank' href='javascript:;' rel="noreferrer" ><img alt='' src={bannerItem.banner_img} className='adImg' /></a>
                        ))}
                    </Carousel>
                    <div className='searchBox w1400'  onClick={() => {
                        //type 是搜索分类  1 是关键字， 2 是类别  3 是专辑ID
                        this.props.history.push('/rout/newsSearch/1/1' )
                    }}>
                        <span></span>
                        <p>搜索</p>
                    </div>
                    {/*搜索分类*/}
                    <div className='searchBoxClass'>
                        {this.state.category.map(item=>{
                            return <span onClick={() => {
                                //type 是搜索分类  1 是关键字， 2 是类别  3 是专辑ID
                                this.props.history.push(`/rout/newsSearch/2/${item.category_id}` )
                            }}>{item.name}</span>
                        })}
                    </div>
                    <div className='articleBox'>
                        <div offsetTop={100} className='rightBox'>
                            <div className='articleTitle'>专辑推荐</div>
                            <div className='albumBox'>
                                {this.state.album.map(item=>{
                                    return <div className='albumItem' onClick={() => {
                                        //type 是搜索分类  1 是关键字， 2 是类别  3 是专辑ID
                                        this.props.history.push(`/rout/newsSearch/3/${item.album_id}` )
                                    }}>
                                        <img className='img' src={item.album_icon} alt=""/>
                                        <p className='name'>{item.name}</p>
                                    </div>
                                })}
                                {/*<div className='articleTitle'>文章推荐</div>*/}
                                {/*{this.state.articlesDetail.map((item, index) => (*/}
                                {/*    <div key={'l' + index} className='recommended'  onClick={()=>{*/}
                                {/*        window.open('#/rout/newsDetail/'+ item.category_id)*/}
                                {/*    }}>*/}
                                {/*        <div className='prbox'>*/}
                                {/*            <div className='flex'>*/}
                                {/*                <h2>在这个新综合体的规划、设计和在这个新综合体的规划、设计和</h2>*/}
                                {/*                /!*<h2>{item.title}</h2>*!/*/}
                                {/*                <p>---{item.author}</p>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*))}*/}
                            </div>
                        </div>
                        <div className='leftBox'>
                            <div className='leftItemBox'>
                                {this.state.news && this.state.news.map(item=>{
                                    return  <div className='contentItem'  onClick={()=>{
                                        window.open('#/rout/newsDetail/'+ item.id)
                                    }}>
                                        <div style={{overflow:'hidden'}}>
                                            <img className='itemImg' src={item.main_image} alt=""/>
                                        </div>
                                        {/*<img className='itemImg' src={item.main_image} alt=""/>*/}
                                        <div className='textF'>
                                            <h3 className='title'
                                               >{item.title}</h3>
                                            <div className='textBox'>{item.new_describe}</div>
                                            <div className='otherInfo'>
                                                <span>{item.author}</span>
                                                <span>浏览量：{item.pv}</span>
                                                <span>{item.atime.substring(0,11)}</span>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='sdxcv'>
                        {
                            this.state.news.length>0&&<Pagination current={this.state.page} pageSize={this.state.size} total={this.state.total} onChange={(index, i) => {
                                this.setState({
                                    page: index,
                                }, () => {
                                    this.getMore(index,this.state.size)
                                })
                            }} />

                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
