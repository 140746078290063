import React from 'react';
import { Modal, Progress, message,Carousel } from 'antd';

import top1 from '../../imgs/top1.png'
import search from '../../imgs/search1.png'

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],//往期获奖作品
			advertisement:[],//广告
        }
    }
    componentDidMount() {
        document.title = '获奖作品'
        this.getData()
    }
    getData() {
        global.Ajax.hhtc_request('home/award').then(res => {
            if (res.code == 1) {
                this.setState({
                    data: res.data.data,
					advertisement:res.data.advertisement,
                })
            } else {
                message.error(res.msg, 2)
            }
        })
    }
    render() {
        return (
            <div className='padd_top66' style={{ paddingBottom: 120 }}>
                {/* menu */}
                <div className='menubox'>
                    <div className='w1400'>
                        <p onClick={()=>{
                            this.props.history.push("/rout/home")
                        }}>首页</p>
						<p onClick={()=>{
							this.props.history.push("/rout/product/1")
						}}>产品</p>
                        <p>往期获奖作品</p>
                    </div>
                </div>
				
				<div className='w1400'>
				<Carousel autoplay>
					{this.state.advertisement.map((ad,adIndex) => (
						<img alt={ad.name} src={ad.image}  style={{ display: 'block',width:1400.48,height:'100%' }} onClick={() => {
							window.open(ad.url)
						}}/>
						
					))}
				</Carousel>
				</div>
				
                <div className='w1400'>
                    {this.state.data.map((item, index) => (
                        <div className='hqzpItem' key={'i' + index}>

                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 0 }}>
                                {/* {item.Number} */}
                                <p className='tit' style={{ "width": "177.52px",marginRight:20,height:52,fontSize:19 }}>获奖作品展示</p>
                                {/* <p className='tit' style={{marginTop: 0,marginRight: 20}}>获奖作品展示</p> */}
                                <img alt='' src={item.banner}
                                    className='pic' style={{ display: 'block', width: 1222.48,height:52 }} onClick={() => {
									// this.props.history.push('/rout/Product2/' + item.id)
									window.open('#/rout/Product2/'+ item.id)
								}}/>
                            </div>

                            {item.works.map((item1, index1) => (
                                <div className='itemms' key={'' + index1} >
                                    <div className='picbox'>
                                        <img alt='' src={item1.thumb} className='pic' />
                                        <div className='massk'>
                                            <img alt='' src={search} />
                                        </div>
                                    </div>
                                    <div className='cen'>
                                        <p className='zpname' onClick={() => {
											window.open('#/rout/ProductDetail/'+ item1.id)
                                        }}>{item1.title}</p>
                                        <div className='bqlist'>
                                            {
                                                item1.label.map((item2, index2) => {
                                                    return (
													 <p style={{ borderRadius: 5 }} key={'' + index2}>{item2}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='flex'>
                                            <div className='row'>
                                                <Progress width='76px' type="circle" percent={item1.designConcept * 1 / 10 * 100} trailColor='rgba(255,100,64,.2)' strokeColor='#ff6440' format={() => {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{ fontSize: 24, fontWeight: 'bold' }}>{item1.designConcept.split('.')[0]}</span>
                                                            <span style={{ fontSize: 16 }}>.{item1.designConcept.split('.')[1]}</span>
                                                        </React.Fragment>
                                                    )
                                                }} />
                                                <p>功能级配</p>
                                            </div>
                                            <div className='row'>
                                                <Progress width='76px' type="circle" percent={item1.layout * 1 / 10 * 100} trailColor='rgba(250,220,117,.2)' strokeColor='#fadc75' format={() => {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{ fontSize: 24, fontWeight: 'bold' }}>{item1.layout.split('.')[0]}</span>
                                                            <span style={{ fontSize: 16 }}>.{item1.layout.split('.')[1]}</span>
                                                        </React.Fragment>
                                                    )
                                                }} />
                                                <p>动静流线</p>
                                            </div>
                                            <div className='row'>
                                                <Progress width='76px' type="circle" percent={item1.practicalExpression * 1 / 10 * 100} trailColor='rgba(52,129,122,.2)' strokeColor='#409c93' format={() => {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{ fontSize: 24, fontWeight: 'bold' }}>{item1.practicalExpression.split('.')[0]}</span>
                                                            <span style={{ fontSize: 16 }}>.{item1.practicalExpression.split('.')[1]}</span>
                                                        </React.Fragment>
                                                    )
                                                }} />
                                                <p>节能环保</p>
                                            </div>
                                            <div className='row'>
                                                <Progress width='76px' type="circle" percent={item1.energySaving * 1 / 10 * 100} trailColor='rgba(3,164,244,.2)' strokeColor='#03a4f4' format={() => {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{ fontSize: 24, fontWeight: 'bold' }}>{item1.energySaving.split('.')[0]}</span>
                                                            <span style={{ fontSize: 16 }}>.{item1.energySaving.split('.')[1]}</span>
                                                        </React.Fragment>
                                                    )
                                                }} />
                                                <p>节能环保</p>
                                            </div>
                                            <div className='row'>
                                                <Progress width='76px' type="circle" percent={item1.score * 1 / 10 * 100} trailColor='rgba(1,245,160,.2)' strokeColor='#00F5A0' format={() => {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{ fontSize: 24, fontWeight: 'bold' }}>{item1.score.split('.')[0]}</span>
                                                            <span style={{ fontSize: 16 }}>.{item1.score.split('.')[1]}</span>
                                                        </React.Fragment>
                                                    )
                                                }} />
                                                <p>综合评分</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='paibox'>
                                        <img alt='' src={item1.rankImg} />
                                        <p>“第{item1.ranking}名”</p>
                                    </div>
                                </div>
                            ))}

                        </div>
                    ))}
                </div>
            </div>
        )
    }
}