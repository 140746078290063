import React from 'react';
import {
  Route,
  Switch,
  HashRouter as Router,
  Redirect,
} from 'react-router-dom';
import './App.css'
import './reset.css'

import Rout from './component/Router';  //路由
import TelEdit from './component/Auth/TelEdit';  //修改手机号
import BindEmail from './component/Auth/BindEmail';  //邮箱认证
import Company from './component/Auth/Company';  //公司认证
import BindUser from './component/Auth/BindUser';//实名认证
import Reg from './component/Auth/Reg';  //登录
import Forget from './component/Auth/Forget';  //忘记密码

import Ajax from './class/Ajax';
global.Ajax=new Ajax();

export default class App extends React.Component {
	render(){
		return(
			<React.Fragment>
				<Router>
					<Switch>
						<Route  path="/rout" component={Rout} />
						<Route  path="/pages/rout/teledit" component={TelEdit} />
						<Route  path="/pages/rout/bindemail" component={BindEmail} />
						<Route  path="/pages/rout/binduser" component={BindUser} />
						<Route  path="/pages/rout/company" component={Company} />
						<Route  path="/pages/rout/reg" component={Reg} />
						<Route  path="/pages/rout/forget" component={Forget} />
						{/* 默认访问 */}
						<Redirect to='/rout/home'/>
					</Switch>
				</Router>
			</React.Fragment>
		)
	}
}
