import React from 'react';
import { Modal, Progress, message } from 'antd';

import pk from '../../imgs/pk.png'
import close from '../../imgs/close1.png'

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            works: [],//作品1
            hxCs: 0,//户型参数
            ktCount: 0,//客厅
            wsCount: 0,//卧室
            cfCount: 0,//厨房
            ctCount: 0,//餐厅
            gwCount: 0,//公卫
        }
    }
    componentDidMount() {
        document.title = '作品对比'
        //获取对比作品
        this.getData()
    }
    //获取对比作品
    getData() {
        global.Ajax.hhtc_request('user/contrastInfo').then(res => {
            if (res.code == 1) {
                var data = res.data
                var length = Object.keys(data).length;
                //当对比作品有3个时
                if (length > 0) {
                    this.setState({
                        works: data.contrasts,
                        hxCs: data.hxCount,
                        ktCount: data.ktCount,
                        wsCount: data.wsCount,
                        cfCount: data.cfCount,
                        ctCount: data.ctCount,
                        gwCount: data.gwCount,
                    })
                } else {
                    this.props.history.replace('/root/home')
                }
            } else {
                message.error(res.msg)
            }
        })
    }
    delContrast(id) {
        var req = {
            id: id
        }
        global.Ajax.hhtc_request('home/delContrast', req).then(res => {
            if (res.code == 1) {
                message.success(res.msg, 2, () => {
                    // window.location.reload();
                    this.getData()
                })
            } else {
                message.error(res.msg)
            }
        })
    }
    maxOf3(c, d, e) {
        return (((c > d) ? c : d) > e ? ((c > d) ? c : d) : e);
    }
    render() {
        //特征列表内容
        const tzListContent = (data, type) => {
            var res = [];
            for (var i = 0; i < data; i++) {
                if (type == 1) {
                    //客厅特征
                    var object = (
                        <React.Fragment>
                            <p>客厅进深：</p>
                            <p>客厅面宽：</p>
                            <p>客厅轴向：</p>
                            <p>端厅配置：</p>
                        </React.Fragment>
                    )
                } else if (type == 2) {
                    //卧室特征
                    var object = (
                        <React.Fragment>
                            <p>卧室进深：</p>
                            <p>卧室面宽：</p>
                            <p>衣帽间类型：</p>
                            <p>独卫配置：</p>
                            <p>房间串套：</p>
                            <p>飘窗赠送：</p>
                        </React.Fragment>
                    )
                } else if (type == 3) {
                    //厨房特征
                    var object = (
                        <React.Fragment>
                            <p>厨房布局：</p>
                            <p>阳台关联：</p>
                            <p>厨房面宽：</p>
                            <p>厨房进深：</p>
                            <p>中西分厨：</p>
                        </React.Fragment>
                    )
                } else if (type == 4) {
                    //餐厅特征
                    var object = (
                        <React.Fragment>
                            <p>餐厅进深：</p>
                            <p>餐厅面宽：</p>
                            <p>中西分餐：</p>
                        </React.Fragment>
                    )
                } else if (type == 5) {
                    //公卫特征
                    var object = (
                        <React.Fragment>
                            <p>卫生间面宽：</p>
                            <p>卫生间进深：</p>
                            <p>卫生间布局：</p>
                            <p>干湿分离：</p>
                            <p>干区合用：</p>
                        </React.Fragment>
                    )
                }

                res.push(object)
            }
            return res;
        }

        //特征列表
        const tzList = (data, type) => {
            var re = [];
            for (var j = 0; j < data; j++) {
                if (type == 1) {
                    //客厅特征
                    var object = (
                        <p style={{ background: '#84D3D1',height:"174px" }}>客厅{j + 1}特征</p>
                    )
                } else if (type == 2) {
                    //卧室特征
                    var object = (
                        <p style={{ background: '#84D3D1',height:"266px" }}>卧室{j + 1}特征</p>
                    )
                } else if (type == 3) {
                    //厨房特征
                    var object = (
                        <p style={{ background: '#84D3D1',height:"220px" }}>厨房{j + 1}特征</p>
                    )
                } else if (type == 4) {
                    //餐厅特征
                    var object = (
                        <p style={{ background: '#84D3D1',height:"128px" }}>餐厅{j + 1}特征</p>
                    )
                } else if (type == 5) {
                    //公卫特征
                    var object = (
                        <p style={{ background: '#84D3D1',height:"220px" }}>公卫{j + 1}特征</p>
                    )
                }

                re.push(object)
            }
            return re;
        }
        //户型参数
        const list = (data) => {
            var res = [];
            for (var i = 0; i < data; i++) {
                var obj = (
                    <div className='box2'>
                        <div className='zhcs pai2' key={'' + i}>
                            <p className='h1' style={{ background: '#55CFCC' }}>户型模块{i + 1}参数</p>
                            <div className='flex1'>
                                <p style={{ background: '#84D3D1',height:"128px" }}>基本特征</p>
                                {/* 厨房特征 */}
                                {tzList(this.state.cfCount, 3)}
                                {/* 客厅特征 */}
                                {tzList(this.state.ktCount, 1)}
                                {/* 卧室特征 */}
                                {tzList(this.state.wsCount, 2)}
                                {/* 餐厅特征 */}
                                {tzList(this.state.ctCount, 4)}
                                {/* 公卫特征 */}
                                {tzList(this.state.gwCount, 5)}
                                <p style={{ background: '#84D3D1',height:"128px" }}>赠送特征</p>
                                <p style={{ background: '#84D3D1',height:"174px" }}>敏感特征</p>
                            </div>
                            <div className='list1'>
                                <p>房型配置：</p>
                                <p>户型建面：</p>
                                <p>相同户数：</p>

                                {/* 厨房特征 */}
                                {tzListContent(this.state.cfCount, 3)}
                                {/* 客厅特征 */}
                                {tzListContent(this.state.ktCount, 1)}
                                {/* 卧室特征 */}
                                {tzListContent(this.state.wsCount, 2)}
                                {/* 餐厅特征 */}
                                {tzListContent(this.state.ctCount, 4)}
                                {/* 公卫特征 */}
                                {tzListContent(this.state.gwCount, 5)}


                                <p>户型竖向：</p>
                                <p>阳台占比：</p>
                                <p>赠送率：</p>

                                <p>面宽数量：</p>
                                <p>空调机位：</p>
                                <p>玄关类型：</p>
                                <p>主卧朝向：</p>
                            </div>
                        </div>
                    </div>
                )
                res.push(obj)
            }
            return res;
        }
        return (
            <div className='padd_top66' style={{ paddingBottom: 120 }}>
                {/* menu */}
                <div className='menubox' style={{ marginBottom: 36 }}>
                    <div className='w1400'>
                        <p>首页</p>
                        <p>作品对比</p>
                    </div>
                </div>
                <div className='w1400'>
                    <div className='contbox'>
                        {/* 第一列 */}
                        <div className='box1'>
                            {/* 标题栏 */}
                            <div className='pai1'>
                                <div className='tbox'>
                                    <img alt='' src={pk} />
                                </div>
                            </div>
                            {/* 综合参数 */}
                            <div className='zhcs pai2'>
                                <p className='h1'>综合参数</p>
                                <div className='flex1'>
                                    <p style={{height:"220px"}}>地理特征</p>
                                    <p style={{height:"220px"}}>关键特征</p>
                                </div>
                                <div className='list1'>
                                    <p>目标省份：</p>
                                    <p>目标城市：</p>
                                    <p>对标企业：</p>
                                    <p>日照要求：</p>
                                    <p>适用地形：</p>
                                    <p>产品业态：</p>
                                    <p>适用层数：</p>
                                    <p>交付支持：</p>
                                    <p>产品定位：</p>
                                    <p>容积率值：</p>
                                </div>
                            </div>
							
							{/* 标准参数 */}
							<div className='zhcs pai2'>
							    <p className='h1' style={{ background: '#4DBDB3' }}>限定参数</p>
							    <div className='flex1'>
							        <p style={{ background: '#4DBDB3',height:"82px" }}>规范特征</p>
							        <p style={{ background: '#4DBDB3',height:"82px" }}>市场特征</p>
							    </div>
							    <div className='list1'>
							        <p>消防设计规范：</p>
							        <p>面积计算规范：</p>
							
							        <p>面世年度：</p>
							        <p>市场响应：</p>
							    </div>
							</div>
							
							
                            {/* 楼型参数参数 */}
                            <div className='zhcs pai2'>
                                <p className='h1' style={{ background: '#55CFCC' }}>楼型参数</p>
                                <div className='flex1'>
                                    <p style={{ background: '#84D3D1',height:"220px" }}>基本特征</p>
                                    <p style={{ background: '#84D3D1',height:"220px" }}>核心筒特征</p>
                                    <p style={{ background: '#84D3D1',height:"128px" }}>拓展特征</p>
                                    <p style={{ background: '#84D3D1',height:"128px" }}>节能特征</p>
                                </div>
                                <div className='list1'>
                                    <p>楼型面积：</p>
                                    <p>得房率值：</p>
                                    <p>楼型面宽：</p>
                                    <p>楼型进深：</p>
                                    <p>楼型户数：</p>

                                    <p>核心筒位置：</p>
                                    <p>楼电梯关系：</p>
                                    <p>核心筒明窗：</p>
                                    <p>电梯数量：</p>
                                    <p>楼梯数量：</p>

                                    <p>核楼型形状：</p>
                                    <p>板塔关系：</p>
                                    <p>核拼接支持：</p>

                                    <p>体形系数：</p>
                                    <p>窗地比值：</p>
                                    <p>窗墙比值：</p>
                                </div>
                            </div>
                            {/* 户型模块 */}
                            {list(this.state.hxCs)}

                            

                            
							
                        </div>

                        {
                            this.state.works.map((item, index) => {
                                return (
                                    <div className='box2' key={'' + index}>
                                        {/* 标题栏 */}
                                        <div className='pai1'>
                                            <div className='zpitem'>
                                                <img alt='' src={close} className='close' onClick={
                                                    this.delContrast.bind(this, item.id)
                                                } />
                                                <img alt='' src={item.thumb} className='pic' onClick={() => {
                                                    // this.props.history.push('/rout/ProductDetail/' + item.workId)
													window.open('#/rout/ProductDetail/' + item.workId)
                                                }} />
                                                <p >{item.title}</p>
                                            </div>
                                        </div>
                                        {/* 综合参数 */}
                                        <div className='list1 list2 pai2'>
                                            <p>{item.comprehensive_dl.province}</p>
                                            <p>{item.comprehensive_dl.city}</p>
                                            <p>{item.comprehensive_dl.developmentName}</p>
                                            <p>{item.comprehensive_dl.sunshine}</p>
                                            <p>{item.comprehensive_dl.terrain}</p>

                                            <p>{item.comprehensive_gj.format}</p>
                                            <p>{item.comprehensive_gj.layers}</p>
                                            <p>{item.comprehensive_gj.support}</p>
                                            <p >{item.comprehensive_gj.plotRatio}</p>
                                            <p className={item.comprehensive_gj.is_max_rating == 1 ? 'active' : ''}>{item.comprehensive_gj.rating}</p>
                                        </div>
                                        
										{/* 标准参数参数 */}
										<div className='list1 list2 pai2'>
										    <p>{item.standard_gf.codeFireProtectionDesign}</p>
										    <p>{item.standard_gf.codeAreaCalculation}</p>
										    <p>{item.standard_cg.drawingDepth}</p>
										    <p>{item.standard_cg.drawingOptions}</p>
										</div>
										
										{/* 楼型参数 */}
                                        <div className='list1 list2 pai2'>
                                            <p className={item.building_jb.is_max_standardArea == 1 ? "active" : ""}>{item.building_jb.standardArea}</p>
                                            <p className={item.building_jb.is_max_houseRate == 1 ? "active" : ""}>{item.building_jb.houseRate}</p>
                                            <p className={item.building_jb.is_max_floorWidth == 1 ? "active" : ""}>{item.building_jb.floorWidth}</p>
                                            <p className={item.building_jb.is_max_buildingDepth == 1 ? "active" : ""}>{item.building_jb.buildingDepth}</p>
                                            <p className={item.building_jb.is_max_buildingNum == 1 ? "active" : ""}>{item.building_jb.buildingNum}</p>

                                            <p>{item.building_hxt.type}</p>
                                            <p>{item.building_hxt.relation}</p>
                                            <p>{item.building_hxt.window}</p>
                                            <p className={item.building_hxt.is_max_elevatorsNum == 1 ? "active" : ""}>{item.building_hxt.elevatorsNum}</p>
                                            <p className={item.building_hxt.is_max_stairsNum == 1 ? 'active' : ''}>{item.building_hxt.stairsNum}</p>

                                            <p>{item.building_tz.shape}</p>
                                            <p>{item.building_tz.combination}</p>
                                            <p>{item.building_tz.splicing}</p>

                                            <p className={item.building_jn.is_max_shapeCoefficient == 1 ? "active" : ""}>{item.building_jn.shapeCoefficient}</p>
                                            <p className={item.building_jn.is_max_windowFloorRatio == 1 ? "active" : ""}>{item.building_jn.windowFloorRatio}</p>
                                            <p className={item.building_jn.is_max_windowWallRatio == 1 ? "active" : ""}>{item.building_jn.windowWallRatio}</p>
                                        </div>

                                        {/* 户型模块参数 */}
                                        {
                                            item.hxCs.map((item1, index1) => {
                                                return (
                                                    <div className='list1 list2 pai2' key={'item111' + index1}>
                                                        <p >{item1.hxJbtz.jbtz_hxjp}</p>
                                                        <p className={item1.hxJbtz.is_jbtz_hxmj == 1 ? "active" : ""}>{item1.hxJbtz.jbtz_hxmj}</p>
                                                        <p className={item1.hxJbtz.is_jbtz_tlxhxs == 1 ? "active" : ""}>{item1.hxJbtz.jbtz_tlxhxs}</p>
                                                        
                                                        {
                                                            item1.hxCftz.map((item4, index4) => {
                                                                return (
                                                                    <React.Fragment key={'item4' + index4}>
                                                                        <p >{item4.cftz_cfbj}</p>
                                                                        <p >{item4.cftz_ytgl}</p>
                                                                        <p className={item4.is_cftz_cfmk == 1 ? "active" : ""}>{item4.cftz_cfmk}</p>
                                                                        <p className={item4.is_cftz_cfjs == 1 ? "active" : ""}>{item4.cftz_cfjs}</p>
                                                                        <p >{item4.cftz_dlzxcf}</p>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            item1.hxKttz.map((item2, index2) => {
                                                                return (
                                                                    <React.Fragment key={'item2' + index2}>
                                                                        <p className={item2.is_kttz_ktjs == 1 ? "active" : ""}>{item2.kttz_ktjs}</p>
                                                                        <p className={item2.is_kttz_ktmk == 1 ? "active" : ""}>{item2.kttz_ktmk}</p>
                                                                        <p >{item2.kttz_ktzx}</p>
                                                                        <p >{item2.kttz_dt}</p>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            item1.hxWstz.map((item3, index3) => {
                                                                return (
                                                                    <React.Fragment key={'item3' + index3}>
                                                                        <p className={item3.is_wstz_wsmk == 1 ? "active" : ""}>{item3.wstz_wsjs}</p>
                                                                        <p className={item3.is_wstz_wsmk == 1 ? "active" : ""}>{item3.wstz_wsmk}</p>
                                                                        <p >{item3.wstz_dlymj}</p>
                                                                        <p >{item3.wstz_hwsj}</p>
                                                                        <p >{item3.wstz_gnfjct}</p>
                                                                        <p >{item3.wstz_pc}</p>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            item1.hxCttz.map((item5, index5) => {
                                                                return (
                                                                    <React.Fragment key={'item5' + index5}>
                                                                        <p className={item5.is_cttz_ctjs == 1 ? "active" : ""}>{item5.cttz_ctjs}</p>
                                                                        <p className={item5.is_cttz_ctmk == 1 ? "active" : ""}>{item5.cttz_ctmk}</p>
                                                                        <p >{item5.cttz_dlzxct}</p>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            item1.hxWsjtz.map((item6, index6) => {
                                                                return (
                                                                    <React.Fragment key={'item6' + index6}>
                                                                        <p className={item6.is_gwtz_wsjmk == 1 ? "active" : ""}>{item6.gwtz_wsjmk}</p>
                                                                        <p className={item6.is_gwtz_wsjjs == 1 ? "active" : ""}>{item6.gwtz_wsjjs}</p>
                                                                        <p >{item6.gwtz_wsjbj}</p>
                                                                        <p >{item6.gwtz_wsjgsfl}</p>
                                                                        <p >{item6.gwtz_cjgnfrkhy}</p>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                        <p>{item1.hxZstz.zstz_hxsx}</p>
                                                        <p>{item1.hxZstz.zstz_ytzbl}</p>
                                                        <p>{item1.hxZstz.zstz_zsl}</p>

                                                        <p className={item1.hxMgtz.is_mgtz_mks == 1 ? "active" : ""}>{item1.hxMgtz.mgtz_mks}</p>
                                                        <p>{item1.hxMgtz.mgtz_ktjw}</p>
                                                        <p>{item1.hxMgtz.mgtz_xg}</p>
                                                        <p>{item1.hxMgtz.mgtz_zwcx}</p>
                                                    </div>
                                                )

                                            })

                                        }

                                        
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        )
    }
}