import React from 'react';
import {
	Route,
	Switch,
	HashRouter as Router,
	NavLink
} from 'react-router-dom';
import ScrollToTop from './ScrollToTop'
import { message, Modal, Upload, Select } from 'antd';

import Home from './Home/Index';  //首页
import Land from './Land/Land';  //土地系统
import Product from './Product/Product';  //产品
import Product2 from './Product/Product2';  //产品2
import Investment from './Investment/Investment';  //投资系统
import ProductDetail from './Product/Detail';  //作品详情
import Award from './Home/Award';  //获奖作品
import Contrast from './Home/Contrast';  //作品对比
import Userinfo from './Home/Userinfo';  //个人主页
import Personal from './Home/Personal';  //个人资料

import ProManual from './Others/ProManual';//常见问题
import Prize from './Others/Prize';  //奖项认证
import Honor from './Others/Honor';  //荣誉系统
import Review from './Others/Review';  //评审机制
import Jury from './Others/Jury';  //评审团
import About from './Others/About';  //关于我们
import News from './News/index'; //新闻系统
import NewsDetail from './News/detail'; //新闻系统
import NewsSearch from './News/search'; //新闻系统

import Integral from './Home/Integral';  //我的积分

import Parames2 from './Product/Parames2';//预览作品

import icon1 from '../imgs/icon1.png'
import icon2 from '../imgs/icon2.png'
import icon3 from '../imgs/icon3.png'
import icon4 from '../imgs/icon4.png'

import search from '../imgs/search.png'
import vs from '../imgs/vs.png'
import fk from '../imgs/fk.png'
import kf from '../imgs/kf.png'
import top from '../imgs/top.png'
import close from '../imgs/close.png'
import add from '../imgs/add1.png'
import close1 from '../imgs/close2.png'
import gou from '../imgs/gou1.png'
import close3 from '../imgs/close3.png'

import logo from '../imgs/logo.png'

const { Option } = Select;
export default class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			routes: [
				{
					path: '/rout/home',
					name: '首页',
					component: 'Home',
				},
				{
					path: '/rout/land',
					name: '土地',
					component: 'Land',
				},
				{
					path: '/rout/product/1',
					name: '产品',
					component: 'Product',
				},
				{
					path: '/rout/investment',
					name: '投资',
					component: 'Investment',
				},
				{
					path: '/rout/news_system',
					name: '项目',
					component: 'Project',
				}
			],
			logo: '',
			positionTxt: 'absolute',
			top: '440px',
			maskShow: false,
			activeIndex: null,
			urls: ['/rout/userinfo', '/rout/personal', '/rout/integral'],
			// 
			kfVisible: false,
			// 
			wtVisible: false,
			// 
			loginVisible: false,
			selected: false,
			loginAccountType: 0,  //0--账号登录  1--验证码登录
			mobile: "",
			password: "",
			code: "",
			category: [],
			kfImg: "",
			kfEmail: "",
			kfPhone: "",
			content: "",//反馈内容
			imgs: "",
			// 七牛配置
			upload_token: '',
			domain: '',
			font_img: "",
			workList: [],  //作品对比列表
			loading: false,
			smstext:"获取验证码",
		}
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll.bind(this))
		//联系客服
		global.Ajax.hhtc_request('agreement/weChatService').then(res => {
			if (res.code == 1) {
				this.setState({
					kfImg: res.data.img,
					kfEmail: res.data.email,
					kfPhone: res.data.phone,
				})
			} else {
				console.log("客服信息获取失败");
			}
		})
		console.log(localStorage.getItem('isOpenLogin')==='true');
		if (localStorage.getItem('isOpenLogin')==="true") {
			this.setState({
				loginVisible: true
			})
			localStorage.setItem('isOpenLogin',false)
		}
		
		// 获取七牛配置
		global.Ajax.hhtc_request("agreement/get_config", {}).then(data => {
			if (data.code === 1) {
				this.setState({
					upload_token: data.data.token,
					domain: data.data.domain,
				})
			}
		})
		//获取后台设置的登录logo
		global.Ajax.hhtc_request('home/loginLogo').then(res => {
			if (res.code == 1) {
				this.setState({
					logo: res.data.img,
				})
			} else {
				console.log("客服信息获取失败");
			}
		})
		// 获取作品对比列表
		this.contrastWork()
	}

	contrastWork() {
		global.Ajax.hhtc_request('home/contrastWork').then(res => {
			if (res.code == 1) {
				this.setState({
					workList: res.data
				})
			} else {
				console.log("客服信息获取失败");
			}
		})
	}

	// componentWillUnmount(){
	// 	window.removeEventListener('scroll')
	// }
	handleScroll(e) {
		var currentOffset = document.documentElement.scrollTop
		var positionTxt, top;
		if (currentOffset > 400) {
			positionTxt = 'fixed'
			top = '260px'
		} else {
			positionTxt = 'absolute'
			top = '440px'
		}
		this.setState({
			positionTxt,
			top,
		})
	}
	// 获取子组件
	onRef(name, ref) {
		switch (name) {
			case 'Home':
				this.Home = ref
				break
			default:
				break
		}
	}
	login() {
		var req = {
			mobile: this.state.mobile,
			password: this.state.password,
		}
		global.Ajax.hhtc_request('login/login', req).then(res => {
			if (res.code == 1) {
				localStorage.setItem('ftToken', res.data.token)//用户登录凭证
				localStorage.setItem('userId', res.data.userId)//用户id
				localStorage.setItem('isLogin', true)//登录状态
				localStorage.setItem('headImg', res.data.headImg)//用户头像
				localStorage.setItem('level', res.data.level)//用户会员等级
				localStorage.setItem('nickname', res.data.nickname)//用户昵称
				if (this.state.selected) {
					localStorage.setItem('selected', 'selected')
					localStorage.setItem('mobile', this.state.mobile)
					localStorage.setItem('password', this.state.password)
				} else {
					localStorage.removeItem('selected')
				}
				this.setState({
					// maskShow:true,
					loginVisible: false,
					mobile: '',
					password: ''
				})
				message.success(res.msg, 2)
			} else {
				message.error(res.msg, 2)
			}
		})
	}
	//获取验证码
	getCode() {
		if(!this.state.mobile){
			message.error("请输入手机号", 2)
			return false;
		}
		var req = {
			mobile: this.state.mobile,
			type: 2
		}
		if(this.state.smsstatus==false){
			return false;
		}
		global.Ajax.hhtc_request('login/SendCode', req).then(res => {
			if (res.code == 1) {
				message.success(res.msg, 2)
				this.djs();
			} else {
				message.error(res.msg, 2)
			}
		})

	}

	//验证码登录
	codeLogin() {
		var req = {
			mobile: this.state.mobile,
			code: this.state.code,
		}
		global.Ajax.hhtc_request('login/codeLogin', req).then(res => {
			if (res.code == 1) {
				localStorage.setItem('ftToken', res.data.token)//用户登录凭证
				localStorage.setItem('userId', res.data.userId)//用户id
				localStorage.setItem('isLogin', true)//登录状态
				localStorage.setItem('headImg', res.data.headImg)//用户头像
				localStorage.setItem('level', res.data.level)//用户会员等级
				localStorage.setItem('nickname', res.data.nickname)//用户昵称
				if (this.state.selected) {
					localStorage.setItem('selected', 'selected')
					localStorage.setItem('mobile', this.state.mobile)
					localStorage.setItem('password', this.state.password)
				} else {
					localStorage.removeItem('selected')
				}
				this.setState({
					// maskShow:true,
					loginVisible: false,
					mobile: '',
					password: ''
				})
				message.success(res.msg, 2)
			} else {
				message.error(res.msg, 2)
			}
		})
	}

	//退出登录
	logout() {
		localStorage.setItem('ftToken', '')//用户登录凭证
		localStorage.setItem('userId', '')//用户id
		localStorage.setItem('headImg', '')//用户头像
		localStorage.setItem('isLogin', '')//登录状态
		this.setState({
			maskShow: false,
			// loginVisible:true,
		})
		this.props.history.push('/rout/home')
	}
	// 倒计时------------我弄不来
	djs(){
	        var time=60;
	         this.timer= setInterval(() => {
	            time--;
	            if(time<=0){
	                this.setState({
	                    smstext:"重发",
	                    smsstatus:true
	                },()=>{
	                    time=60;
	                    clearInterval(this.timer);
	                })
	            }else{
	                this.setState({
	                    smstext:time+"s",
	                    smsstatus:false
	                })
	            }
	        }, 1000);
	}
		 
	//用户反馈
	feedback() {
		var req = {
			content: this.state.content,
			imgs: this.state.font_img,
		}
		global.Ajax.hhtc_request('user/feedback', req).then(res => {
			if (res.code == 1) {
				message.success(res.msg, 2)
				this.setState({
					wtVisible: false
				})
				return false;
				window.location.reload();
			} else {
				message.error(res.msg)
			}
		})


	}
	//删除图片
	delImg() {
		console.log(1)
		this.setState({
			font_img: ""
		})

	}
	// 去加入对比
	goWork() {
		if (this.state.workList.length <= 0) {
			Modal.confirm({
				title: '当前无对比项，请加入对比作品?',
				icon: "",
				content: '',
				okText: '是',
				okType: 'danger',
				cancelText: '否',
				onOk: () => {
					this.props.history.push('/rout/product/1')
				}
				,
				onCancel() {
					console.log('Cancel');
				},
			});
		} else {
			this.props.history.push('/rout/contrast')
		}
	}
	render() {
		var that = this;
		const props = {
			name: 'file',
			action: 'https://up-z0.qiniup.com',
			showUploadList: false,
			headers: {
				authorization: 'authorization-text',
			},
			data: (file) => {
				console.log(file)
				var houzui = '.' + file.name.split('.')[1];
				return {
					token: that.state.upload_token,
					key: Date.now() + Math.floor(Math.random() * (999999 - 100000) + 100000) + '1' + houzui,
				}
			},
		};


		return (
			<div>
				<div className='tabbar'>
					<div className='w1400'>
						{/* <p style={{width: 185,lineHeight: '66px',backgroundColor: '#000',fontSize: 28,color: '#fff',textAlign: 'center',fontWeight: 'bold'}}>LOGO</p> */}

						<img alt='' src={logo} className='icon' onClick={() => {
							this.props.history.push('/rout/Home')
						}} />
						<div className='menuBox'>
							{this.state.routes.map((route, index) => {
								return (
									<React.Fragment key={index}>
										<NavLink
											to={route.path}
											activeClassName="active"
											className="tabbar_item"
											key={index}
											name={route.name}
										>
											<p className={route.component}>{route.name}</p>
											<span>{route.component}</span>
										</NavLink>
									</React.Fragment>
								);
							}, this)}
						</div>
						{localStorage.getItem('isLogin') ? <div className='uinfo'
							onClick={() => {
								this.setState({
									maskShow: true
								})
							}}
						>
							{
								localStorage.getItem('level') == 1 && <img alt='' src={icon1} className='icon' />
							}

							{
								localStorage.getItem('level') == 2 && <img alt='' src={icon2} className='icon' />
							}

							{
								localStorage.getItem('level') == 3 && <img alt='' src={icon3} className='icon' />
							}

							{
								localStorage.getItem('level') == 4 && <img alt='' src={icon4} className='icon' />
							}

							<img alt='' src={localStorage.getItem('headImg')} className='avatar' />
						</div> : <p className="goreg" onClick={() => {
							if (localStorage.getItem('selected') == 'selected') {
								this.setState({
									mobile: localStorage.getItem('mobile'),
									password: localStorage.getItem('password'),
									selected: true
								})
							}
							this.setState({
								loginVisible: true
							})
						}}>注册/登录</p>}

					</div>
				</div>
				<Router>
					<ScrollToTop>
						<Switch>
							{/* <Route path='/rout/home' render={(props)=>{
								return <Home {...props}  onRef={this.onRef.bind(this)} changeOpacity={(opacity)=>{
									console.log('router---',opacity)
									this.setState({opacity})
								}} />
							}} /> */}
							<Route path='/rout/home' component={Home} />
							<Route path='/rout/land' component={Land} />
							<Route path='/rout/product/:id' component={Product} />
							<Route path='/rout/product2/:id' component={Product2} />
							<Route path='/rout/investment' component={Investment} />
							<Route path='/rout/ProductDetail/:id' render={(props) => {
								return <ProductDetail {...props} ref={dom => this.detail = dom} refresh={() => {
									this.contrastWork()
								}} />
							}}
							// component={ProductDetail}
							/>
							<Route path='/rout/award' component={Award} />
							<Route path='/rout/news_system' component={News} />
							<Route path='/rout/newsDetail/:id' component={NewsDetail} />
							<Route path='/rout/newsSearch/:type/:keyOrId' component={NewsSearch} />
							<Route path='/rout/contrast' component={Contrast} />
							<Route path='/rout/userinfo' component={Userinfo} />
							<Route path='/rout/personal' component={Personal} />
							<Route path='/rout/proManual' component={ProManual} />
							<Route path='/rout/Prize' component={Prize} />
							<Route path='/rout/Honor' component={Honor} />
							<Route path='/rout/Review' component={Review} />
							<Route path='/rout/Jury' component={Jury} />
							<Route path='/rout/About' component={About} />
							<Route path='/rout/integral' component={Integral} />
							<Route path='/rout/Parames2/:id' component={Parames2} />
						</Switch>
					</ScrollToTop>
				</Router>
				<div className='bottom_bar'>
					<div className='w1400'>
						<div className='top'>
							<div className='list'>
								<div className='flex'>
									<p onClick={() => {
										this.props.history.push('/rout/About')
									}}>关于我们</p>
									<p onClick={() => {
										this.props.history.push('/rout/Jury')
									}}>评审团</p>
									<p onClick={() => {
										this.props.history.push('/rout/Review')
									}}>评审机制</p>
									<p onClick={() => {
										this.props.history.push('/rout/Honor')
									}}>荣誉系统</p>
									<p onClick={() => {
										this.props.history.push('/rout/Prize')
									}}>奖项认证</p>
									<p onClick={() => {
										this.props.history.push('/rout/proManual')
									}}>常见问题</p>
								</div>
							</div>
							{/* <p style={{width: 185,lineHeight: '66px',backgroundColor: '#000',fontSize: 28,color: '#fff',textAlign: 'center',fontWeight: 'bold'}}>LOGO</p> */}
							<img alt='' src={logo} className='icon' />
						</div>
						<div className='flex'>
							<p className='p1'>版权所有 瓴思（重庆）建筑设计咨询有限公司</p>
							<p className='p2'>渝ICP备2022007297号</p>
							<p className='p2'>渝ICP备2022007297号</p>
						</div>
					</div>
				</div>
				{/* 固定栏 */}
				<div className='fixedbox' style={{ position: this.state.positionTxt, top: this.state.top }}>
					<div className='item' onClick={() => {
						this.props.history.push('/rout/product/' + 2)
					}}>
						<img alt='' src={search} className='icon' />
						<p>搜索</p>
					</div>
					<div className='item' onClick={this.goWork.bind(this)}>
						<img alt='' src={vs} className='icon' />
						<p>加入对比</p>
					</div>
					<div className='item' onClick={() => {
						this.setState({
							wtVisible: true
						})
					}}>
						<img alt='' src={fk} className='icon' />
						<p>用户反馈</p>
					</div>
					<div className='item' onClick={() => {
						this.setState({
							kfVisible: true
						})
					}}>
						<img alt='' src={kf} className='icon' />
						<p>联系客服</p>
					</div>
					<div className='item' onClick={() => {
						window.scrollTo({
							top: 0,
							behavior: 'smooth'
						})
					}}>
						<img alt='' src={top} className='icon' />
						<p>返回顶部</p>
					</div>
				</div>
				{/* 弹窗 */}
				{this.state.maskShow && <div className='Mask' onClick={() => {
					this.setState({
						maskShow: false
					})
				}}>
					<div className='w1400' style={{ position: 'relative' }}>
						<div className='Mcont'>
							<p className='name'>{localStorage.getItem('nickname')}</p>
							{['我的主页', '个人资料', '我的积分'].map((item, index) => (
								<p className={`p1 ${this.state.activeIndex === index ? 'active' : ''}`} onClick={() => {
									this.setState({
										activeIndex: index
									})
									this.props.history.push(this.state.urls[index])
								}}>{item}</p>
							))}
							<p className='name'
								style={{ borderTopWidth: 1, borderTopStyle: 'solid', borderTopColor: '#DBDEDD', borderBottomWidth: 0, padding: '20px 0 20px 24px' }}
								onClick={this.logout.bind(this)} >退出</p>
						</div>
					</div>
				</div>}
				{/* 联系客服 */}
				<Modal
					title=''
					visible={this.state.kfVisible}
					width={600}
					footer={false}
					closable={false}
					centered={true}
					className='paymask'
				>
					<div className='modal-title'>
						<p>联系客服</p>
						<img alt='' src={close} onClick={() => {
							this.setState({
								kfVisible: false
							})
						}} />
					</div>
					<div className='kflex'>
						<div className='ittem'>
							<h3 className='h3C'>客服微信</h3>
							<p className='ewm'>
								<img alt='' src={this.state.kfImg} />
							</p>
						</div>
						<div className='ittem'>
							<h3 className='h3C'>QQ</h3>
							<p className='ewm'>
								<img alt='' src={this.state.kfPhone} />
							</p>
							{/* <p>{this.state.kfPhone}</p> */}
						</div>
						<div className='ittem'>
							<h3 className='h3C'>联系邮箱</h3>
							<p className='ewm'>
								<img alt='' src={this.state.kfEmail} />
							</p>
						</div>
						{/* 联系微信号/联系电话/联系传真/联系邮箱/联系地址/ */}
					</div>
					<p className='btn' onClick={() => {
						this.setState({
							kfVisible: false
						})
					}}>关闭</p>
				</Modal>
				{/* 问题反馈 */}
				<Modal
					title=''
					visible={this.state.wtVisible}
					width={600}
					footer={false}
					closable={false}
					centered={true}
					destroyOnClose={true}
					className='paymask'
				>
					<div className='modal-title'>
						<p>问题反馈</p>
						<img alt='' src={close} onClick={() => {
							this.setState({
								wtVisible: false,
								font_img: ''
							})
						}} />
					</div>
					<div className='telbox'>
						<textarea placeholder='请输入你遇到的问题' className='txtt'
							onChange={(e) => {
								this.setState({
									content: e.target.value
								})
							}}></textarea>
						<div className='imgbox'>
							<div>
								{/* <img alt='' src={add} className='pic' /> */}
								<Upload {...props} onChange={(info) => {
									if (info.file.status === 'done') {
										var src = this.state.domain + '/' + info.file.response.key
										this.setState({
											font_img: src
										})
										console.log(src)
									} else if (info.file.status === 'error') {
										message.error(info.file.response.error)
									}
								}}>
									{
										this.state.font_img != ""
											? <img className='pic' alt='' src={this.state.font_img} /> : <img alt='' src={add} className='pic' />

									}
								</Upload>
								{this.state.font_img != '' && <img alt='' src={close1} className='top1' onClick={() => {
									this.setState({
										font_img: ""
									})
								}} />}

							</div>
						</div>
					</div>
					<p className='btn' onClick={this.feedback.bind(this)}>提交</p>
				</Modal>
				{/* 登录 */}
				<Modal
					title=''
					visible={this.state.loginVisible}
					width={680}
					footer={false}
					closable={false}
					centered={true}
					destroyOnClose={true}
					onCancel={() => {
						this.setState({
							loginVisible: false
						})
					}}
					zIndex='999999'
					className='loginbox'
				>
					<div className='flex'>
						<img alt='' src={this.state.logo} className='bg' />
						<div className='rbox'>
							<div className='flex1'>
								{['账号登录', '验证码登录'].map((item, index) => (
									<p className={this.state.loginAccountType == index ? 'active' : ''} onClick={() => {
										this.setState({
											loginAccountType: index,
											mobile:"",
										})
									}}>{item}</p>
								))}
							</div>
							{this.state.loginAccountType == 0 && <React.Fragment>
								<div className='inptbox'>
									<input placeholder='请输入账号/手机号' maxLength={11} onChange={(e) => {
										this.setState({
											mobile: e.target.value
										})
									}} value={this.state.mobile} />
								</div>
								<div className='inptbox'>
									<input placeholder='请输入密码' type='password' onChange={(e) => {
										this.setState({
											password: e.target.value
										})
									}} value={this.state.password} />
								</div>
								<div className='bcbox' onClick={() => {
									this.setState({
										selected: !this.state.selected
									})
								}}>
									{this.state.selected ?
										<img alt='' src={gou} />
										:
										<span></span>
									}
									<p>保持登录状态</p>
								</div>
								<p className='loginbtn' onClick={this.login.bind(this)}>登录</p>
							</React.Fragment>}
							{this.state.loginAccountType == 1 && <React.Fragment>
								<div className='inptbox'>
									<input placeholder='请输入账号/手机号' onChange={(e) => {
										this.setState({
											mobile: e.target.value
										})
									}} />
								</div>
								<div className='inptbox'>
									<input placeholder='请输入验证码' style={{ width: 100 }} onChange={(e) => {
										this.setState({
											code: e.target.value
										})
									}} />
									<p className='code' onClick={this.getCode.bind(this)}>{this.state.smstext}</p>
								</div>
								<p className='loginbtn' onClick={this.codeLogin.bind(this)}>登录</p>
							</React.Fragment>}
							<div className='flex3'>
								<p onClick={() => {
									this.props.history.push('/pages/rout/reg')
								}}>立即注册</p>
								<p onClick={() => {
									this.props.history.push('/pages/rout/forget')
								}}>忘记密码?</p>
							</div>
						</div>
					</div>
				</Modal>
				{/* 作品对比 */}
				{this.state.workList.length > 0 && <div className='zpdbBox' style={{ position: this.state.positionTxt, top: this.state.top }}>
					<h2>作品对比</h2>
					{this.state.workList.map((item, index) => (
						<div key={'' + index}>
							<p className='zpname'>{item.title}</p>
							<img alt='' src={close3} className='del' onClick={this.delWork.bind(this, item.id)} />
						</div>
					))}
					<p className='zpbtn' onClick={() => {
						window.open('/#/rout/contrast')
					}}>开始对比</p>
				</div>}
			</div>
		)
	}
	delWork(id) {
		if (this.state.loading) {
			return
		}
		this.setState({
			loading: true,
		}, () => {
			var req = {
				id: id,
			}
			global.Ajax.hhtc_request('home/delContrast', req).then(res => {
				if (res.code == 1) {
					message.success(res.msg, 1.2)
					this.contrastWork()
					if (this.detail) {
						this.detail.getData()
					}
				} else {
					message.error(res.msg, 1.2)
				}
				this.setState({
					loading: false,
				})
			})
		})
	}
}
