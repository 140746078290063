import React from 'react';
import { message, Modal, Pagination, Empty } from 'antd';

import banner from '../../imgs/banner.png'
import add from '../../imgs/add.png'
import close from '../../imgs/close.png'

import icon1 from '../../imgs/icon1.png'
import icon2 from '../../imgs/icon2.png'
import icon3 from '../../imgs/icon3.png'
import icon4 from '../../imgs/icon4.png'

import dy from '../../imgs/dy.png'
import dys from '../../imgs/dys.png'
import search from '../../imgs/search1.png'

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [1, 1, 1, 1, 1],
            visible: false,
            email: "",
            nickname: "",
            userId: "",
            integral: "",
            sign: "",
            mobile: "",
            level: "",
            recommend: [],//推荐作品
            is_subscribe: 0,
            works: [],//我的订阅/我的下载
            page: 1,
            size: 10,
            type: 0,
            label: [],
        }
    }
    componentDidMount() {
        document.title = '我的主页'
        //用户信息
        global.Ajax.hhtc_request('user/home').then(res => {
            if (res.code == 1) {
                this.setState({
                    email: res.data.email,
                    nickname: res.data.nickname,
                    userId: res.data.userId,
                    integral: res.data.integral,
                    sign: res.data.sign,
                    mobile: res.data.mobile,
                    level: res.data.level,
                })
            } else {
                message.error(res.msg)
            }
        })
        //个人主页推荐作品接口
        global.Ajax.hhtc_request('user/recommendWork').then(res => {
            if (res.code == 1) {
                this.setState({
                    recommend: res.data,
                    is_subscribe: res.data.is_subscribe,
                    label: res.data.label
                })
            } else {
                message.error(res.msg)
            }
        })
        // 
        this.getData()
    }
    changeWorks() {
        global.Ajax.hhtc_request('user/recommendWork').then(res => {
            if (res.code == 1) {
                this.setState({
                    recommend: res.data,
                    is_subscribe: res.data.is_subscribe,
                    label: res.data.label
                })
                console.log(this.state.is_subscribe)
            } else {
                message.error(res.msg)
            }
        })
    }
    Subscribe(id) {
        var req = {
            id: id,
        }
        global.Ajax.hhtc_request('home/subscribe', req).then(res => {
            if (res.code == 1) {
                this.setState({
                    is_subscribe: res.data
                })
            } else {
                message.error(res.msg)
            }
        })
    }
    //获取订阅/下载
    getData() {
        var req = {
            page: this.state.page,
            size: 10,
            type: this.state.type + 1
        }
        //我的订阅
        global.Ajax.hhtc_request('user/works', req).then(res => {
            if (res.code == 1) {
                this.setState({
                    works: res.data.data,
                    total: res.data.total
                })
            } else {
                message.error(res.msg)
            }
        })
    }
    render() {
        return (
            <div className='padd_top66'>
                {/* menu */}
                <div className='menubox' style={{ marginBottom: 36 }}>
                    <div className='w1400'>
                        <p onClick={()=>{
                            this.props.history.push("/rout/home")
                        }}>首页</p>
                        <p>我的主页</p>
                    </div>
                </div>
                <div className='wdinfobox w1400'>
                    <div className='lbox'>
                        <div className='flex1'>
                            <img alt='' src={localStorage.getItem('headImg')} className='avatar' />
                            <div className='rrbox'>
                                <div className='flex2'>
                                    <h1>{this.state.nickname}</h1>
                                    {
                                        localStorage.getItem('level') == 1 && <img alt='' src={icon1} />
                                    }
                                    {
                                        localStorage.getItem('level') == 2 && <img alt='' src={icon2} />
                                    }
                                    {
                                        localStorage.getItem('level') == 3 && <img alt='' src={icon3} />
                                    }
                                    {
                                        localStorage.getItem('level') == 4 && <img alt='' src={icon4} />
                                    }
                                </div>
                                <p className='p2'>积分：{this.state.integral}</p>
                            </div>
                        </div>
                        <p className='pp1'>
                            个人签名：{this.state.sign ? this.state.sign : "这家伙很懒，什么都没留下"}
                        </p>
                        <p className='pp1'>邮箱：{this.state.email ? this.state.email : "未认证"}</p>
                        <p className='pp1'>手机：{this.state.mobile ? this.state.mobile : "暂无"}</p>
                        <div className='flex3'>
                            <p onClick={() => {
                                this.props.history.push('/rout/personal')
                            }}>个人资料</p>
                            <p style={{ background: '#F4543C' }} onClick={() => {
                                this.props.history.push('/rout/integral')
                            }}>积分充值</p>
                        </div>
                    </div>
                    <div className='rbox'>
                        <img alt='' src={this.state.recommend.thumb} className='pic' onClick={() => {
                            this.props.history.push('/rout/ProductDetail/' + this.state.recommend.id)
                        }} />
                        <div className='flex1'>
                            <img alt='' src={this.state.recommend.headImg} className='avatar' />
                            <div className='cen' >
                                <h1>{this.state.recommend.title}</h1>
                                <div className='infoP'>
                                <p style={{ paddingTop:'10px' }}>{this.state.recommend.nickname}</p>
                                <div className='infoLabel' >
                                
                                    {this.state.label.map((row, k) => (
                                        <p key={'' + k}>{row}</p>
                                    ))}
                                </div>
                                </div>
                                
                            </div>

                            <div className='btnbox head'>
                                <p onClick={this.changeWorks.bind(this)}>换一换</p>

                                {this.state.is_subscribe == 1 ?
                                    <p className='dybtn' onClick={this.Subscribe.bind(this, this.state.recommend.id)}>
                                        <img alt='' src={dys} />已订阅</p> :
                                    <p className='dybtn head' onClick={this.Subscribe.bind(this, this.state.recommend.id)}>
                                        <img alt='' src={dy} />订阅</p>}

                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                <div className='pjList w1400'>
                    <div className='upflexx'>
                        {['我的订阅', '我的下载'].map((item, index) => (
                            <p key={'' + index} className={this.state.type == index ? 'tit' : 'tit2'} onClick={() => {
                                this.setState({
                                    type: index,
                                    page: 1
                                }, () => {
                                    this.getData()
                                })
                            }}>{item}</p>
                        ))}
                    </div>
                    <div className='box'>
                        {this.state.works.map((item, index) => (
                            <div className='item' key={'z' + index} onClick={() => {
                                this.props.history.push('/rout/ProductDetail/' + item.id)
                            }}>
                                <div className='picbox'>
                                    <img alt='' src={item.thumb} className='pic' />
                                    <div className='massk'>
                                        <img alt='' src={search} />
                                    </div>
                                </div>
                                <div className='flexw'>
                                    {item.label.map((item1, index1) => (
                                        <p key={'' + index1}>{item1}</p>
                                    ))}

                                </div>
                                <div className='uinfo'>
                                    <div className='lbox'>
                                        <h1>{item.title}</h1>
                                        <p>By {item.nickname}</p>
                                    </div>
                                    <img alt='' src={item.headImg} className='avatar' />
                                </div>
                                <div className='llscbox'>
                                    <p>浏览：{item.pvNum}</p>
                                    <p>收藏：{item.collectionNum}</p>
                                    <p>下载：{item.downNum}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* <Pagination hideOnSinglePage={true} current={this.state.page} pageSize={10} total={this.state.total} />
                     */}
                    {this.state.works.length == 0 && <Empty description={false} />}
                    {this.state.works.length > 0 && <Pagination className='top' current={this.state.page} pageSize={this.state.size} total={this.state.total} onChange={(index, i) => {
                        console.log(index, i)
                        this.setState({
                            page: index
                        }, () => {
                            this.home()
                        })
                    }} />}

                </div>
            </div>
        )
    }
}