import React from 'react';
import { Modal, Select, message } from 'antd';

import banner from '../../imgs/banner.png'
import add from '../../imgs/add.png'
import close from '../../imgs/close.png'
import sx from '../../imgs/sx.png'
import search from '../../imgs/search1.png'

import List from './List';

const { Option } = Select;

var timeArr = []
export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            data: [],//作品展示
            works: [],//精选作品
            timeArr: [],
			details_banner:"",
            award_name_text:"",//活动名称
        }
        this.timer = null
    }
    componentDidMount() {
        document.title = '产品-评奖活动'
        this.getData();
    }
    //组件卸载取消倒计时
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    countFun() {
        this.timer = setInterval(() => {
            for (var i = 0; i < timeArr.length; i++) {
                var sys_second = timeArr[i];
                //防止倒计时出现负数
                if (sys_second > 0) {
                    sys_second--;
                    timeArr[i] = sys_second
                    let day = Math.floor((sys_second / 3600) / 24);
                    let hour = Math.floor((sys_second / 3600) % 24);
                    let minute = Math.floor((sys_second / 60) % 60);
                    let sec = Math.floor((sys_second) % 60);
                    this.state.timeArr[i] = <h1> {day}<span>DAYS</span>{hour}<span>HOURS</span>{minute}<span>MIN</span></h1>
                    this.setState({
                        timeArr: this.state.timeArr
                    })
                } else {
                    for (var j = 0; j < timeArr.length; j++) {
                        if (timeArr[j] > 0) {
                            return
                        }
                        clearInterval(this.timer);
                    }
                }
            }
        }, 1000);
    };

    getData() {
        var req = {
            id: this.state.id
        }
        global.Ajax.hhtc_request('home/getAwardWorks', req).then(res => {
            if (res.code == 1) {
                timeArr = []
                for (var i = 0; i < res.data.list.length; i++) {
                    timeArr.push(res.data.list[i].otime)
                }
                clearInterval(this.timer);
                this.countFun()
                this.setState({
                    works: res.data.list,
					details_banner:res.data.details_banner,
                    award_name_text:res.data.award_name_text,
                })
            } else {
                message.error(res.msg)
            }
        })
    }
    render() {
        return (
            <div className='padd_top66'>
                {/* menu */}
                <div className='menubox' >
                    <div className='w1400'>
                       <p onClick={()=>{
                           this.props.history.push("/rout/home")
                       }}>首页</p>
                        <p onClick={()=>{
							this.props.history.push("/rout/product/1")
						}}>产品</p>
                        <p>{this.state.award_name_text}</p>
                        {/* <p>评奖活动</p> */}
                    </div>
                </div>
				
				{/* 作品详情图 */}
				<div className='pjList w1400'>
				{
						this.state.details_banner!=""?
						<div className='hqzpItem'>
								<img alt='作品详情图' style={{ width:1400 }} src={this.state.details_banner} />
						</div>:""
					
				}
				</div>
				
				
				
                {/* 参加活动的全部作品 */}
                <div className='pjList w1400'>
                    <p className='tit'>参加活动作品</p>
                    <div className='box'>
                        {this.state.works.map((item1, index1) => (
                            <div className='item' key={'zz' + index1} onClick={() => {
                                window.open('#/rout/ProductDetail/' + item1.id)
                                // this.props.history.push('/rout/ProductDetail/' + item1.id)
                            }}>
                                <div className='picbox'>
                                    <img alt='' src={item1.thumb} className='pic' />
                                    <div className='massk'>
                                        <img alt='' src={search} />
                                    </div>
                                </div>
                                <div className='flexw'>
                                    {
                                        item1.label.map((item2, index2) => {
                                            return (
                                                <p key={'lable1' + index2}>{item2}</p>
                                            )
                                        })
                                    }
                                </div>

                                <div className='uinfo'>
                                    <div className='lbox'>
                                        <h1 className='title'>{item1.title}</h1>
                                        <p>By {item1.nickname}</p>
                                    </div>
                                    <img alt='' src={item1.headImg} className='avatar' />
                                </div>

                                <div className='flex'>
                                    <div className='time' style={{ flex: 1 }}>
                                        {
                                            item1.otime > 0 ? <p className='p1'>剩余时间</p> : <p className='p1'><h1>0<span>DAYS</span>0<span>HOURS</span>0<span>MIN</span></h1></p>
                                        }
                                        {this.state.timeArr[index1]}
                                    </div>
                                    <div className='time'>
                                        <p className='p1'>当前评分</p>
                                        <h1>{item1.score}</h1>
                                    </div>
                                </div>

                                <div className='flex'>
                                    <div className='imgbox'>
                                        {item1.userImgs.map((pic, i) => (
                                            <img key={'' + i} alt='' src={pic.headImg} className='avatar' />
                                        ))}
                                        <img alt='' src={add} className='avatar' />
                                    </div>
                                    <p className='pfbtn'>评分</p>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>

            </div>
        )
    }

}