import { message } from 'antd'

export default class Ajax {
    hhtc_request(url,data){
        var requrl="https://www.realtydt.com/ft202106/"+url;
        var init={
            method:'POST',
            headers:{
                'Content-Type': 'application/json;',
                "Accept": "application/json",
                'token':localStorage.getItem('ftToken')
            },
            body:JSON.stringify(data)
        }
        return  fetch(requrl,init).then(
             (response) => {
                return  response.json()
            }
        ).then(
            (data)=>{
                if(data.code===999){
                    localStorage.removeItem('headImg')
                    localStorage.removeItem("ftToken")
                    localStorage.removeItem("userId")
                    message.error('登录失效!',1,()=>{
                        window.location.href=''
                    })
                    return data;
                }
                return data;
            }
        ).catch(
            (err)=>{
                console.log("执行一次网络请求:请求地址=》",requrl,"返回结果=>网络异常",err)
                var data={
                    code:0,
                    msg:'网络异常!'
                }
                return data;
            }
        )
    }   
}