import React from 'react';
import { message } from 'antd';
export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
        }
    }
	componentDidMount(){
        document.title='投资系统'
        message.error("暂未开放,敬请期待",2);
	}
    render(){
        return(
            <div className=''>
                
            </div>
        )
    }
}