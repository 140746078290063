import React from 'react';
import { Progress,Modal,message } from 'antd';

// import top1 from '../../imgs/top1.png'
import close from '../../imgs/close.png'

export default class Index extends React.Component{
    constructor(props){
        super(props)
        this.state={
            visible: false,
            numlist: [{
                num: 1,
                selected: false
            },{
                num: 2,
                selected: false
            },{
                num: 3,
                selected: false
            },{
                num: 4,
                selected: false
            },{
                num: 5,
                selected: false
            },{
                num: 6,
                selected: false
            },{
                num: 7,
                selected: false
            },{
                num: 8,
                selected: false
            },{
                num: 9,
                selected: false
            },{
                num: 10,
                selected: false
            }],
            txtlist: ['功能级配','动静流线','节能环保','价值创新'],
            index: 0,
            score1: 0,
            score2: 0,
            score3: 0,
            score4: 0,
            show: true,
            loading: false,
            allnum: 0,
            is_score:0,
            socre:[],
            my:{designConcept: '',energySaving: '',layout: '',practicalExpression: '',score: ''},
            content:"",
            keyword:[],
            names:[],//关键词
            keywords:"",//评分时关键词
            type:0,
            level:1,//评论等级
            average:[],//平均分
            all:{designConcept: '',layout: '',energySaving: '',practicalExpression: '',score: ''},//总平均分
            average:{designConcept: '',layout: '',energySaving: '',practicalExpression: '',score: ''},
            ckVisible: false,  //查看点评弹窗
            label: [],
            ckContent:'',
            group:[],
        }
    }
    componentDidMount(){
        document.title=this.props.title+'-户型评分'
        this.getData()
        this.keyword()
        this.group()
    }
    checkNum(k){
        if(this.state.loading){
            return
        }
        this.setState({
            loading: true
        },()=>{
            if(this.state.index==0){
                this.setState({
                    score1: (k+1).toFixed(2)
                })
            }else if(this.state.index==1){
                this.setState({
                    score2: (k+1).toFixed(2)
                })
            }else if(this.state.index==2){
                this.setState({
                    score3: (k+1).toFixed(2)
                })
            }else if(this.state.index==3){
                this.setState({
                    score4: (k+1).toFixed(2)
                })
            }
            var numlist=this.state.numlist;
            for(var i=0;i<numlist.length;i++){
                if(numlist[i].num<=(k+1)){
                    numlist[i].selected=true
                }else{
                    numlist[i].selected=false
                }
            }
            this.setState({
                numlist,
            },()=>{
                setTimeout(() => {
                    this.reset()
                }, 500);
            })
        })
    }
    //获取评分
    getData(){
        var req={
            id:this.props.id,
            type:this.state.type + 1,//1-A级评分 2-B级评分 3-C组评分 4-D组评分 根据用户会员等级来
            page:1,
            size:10
        }
         global.Ajax.hhtc_request('home/score',req).then(res=>{
             if(res.code==1){
                 //判断自己是否评分
                 if(Object.keys(res.data.my).length>0){
                    this.setState({
                        is_score:1,
                     })
                 }
                 this.setState({
                    level:this.state.type + 1,
                    socre:res.data.scores.data,//评分数据
                    my:res.data.my,//我的评分
                    average:res.data.average, //平均分
                    all:res.data.all,//总
                 })
             }else{
                 message.error(res.msg,2)
             }
         })
    }
    group(){
         global.Ajax.hhtc_request('home/group').then(res=>{
             if(res.code==1){
                 this.setState({
                    group:res.data,//总
                 })
             }else{
                 message.error(res.msg,2)
             }
         })
    }
    //用户评分接口
    wantScore(){
        var req={
            id:this.props.id,
            label:this.state.keywords,
            content:this.state.content,
            designConcept:this.state.score1,//功能级配
            layout:this.state.score2,//动静流线
            practicalExpression:this.state.score3,//节能环保
            energySaving:this.state.score4,//价值创新
            score:this.state.allnum,//综合评分
        }
        global.Ajax.hhtc_request('home/wantScore',req).then(res=>{
            if(res.code==1){
                message.success(res.msg,2,()=>{
                    window.location.reload();
                })
            }else{
                message.error(res.msg,2)
            }
        })
    }
    //关键词
    keyword(){
        global.Ajax.hhtc_request('home/commentKeyword').then(res=>{
            if(res.code==1){
                this.setState({
                    keyword:res.data,
                })
            }else{
                message.error(res.msg,2)
            }
        })
    }

    //评论关键字处理
    clickKeyWord(index,name){
        var names = this.state.names;
        var keyWord = this.state.keyword
        if(keyWord[index].status==0){
            keyWord[index].status = 1;
            names.push(keyWord[index].name);
        }else{
            keyWord[index].status = 0;
            var indexs = names.findIndex(key=>key==keyWord[index].name)
            names.splice(indexs,1)
        }
        this.setState({
            keyWord:keyWord,
            names:names,
            keywords:names.join(','),
        })
    }

    reset(){
        var index=this.state.index
        index++
        if(index>3){
            var allnum=((this.state.score1*1+this.state.score2*1+this.state.score3*1+this.state.score4*1)/4).toFixed(2)
            setTimeout(() => {
                this.setState({
                    show: false,
                    allnum
                })
            }, 100);
            return
        }
        var numlist=this.state.numlist;
        for(var i=0;i<numlist.length;i++){
            numlist[i].selected=false
        }
        this.setState({
            numlist,
            index,
            loading: false,
        })
    }
    
    render(){
        return(
            <React.Fragment>
                <p className='tit1'>总评分</p>
                <div className='zpfbox'>
                    <div className='flex'>
                        <div className='row'>
                            <Progress width='110px' type="circle" percent={this.state.all.designConcept*1/10*100} trailColor='rgba(255,100,64,.2)' strokeColor='#ff6440' 
                                format={()=>(
                                    <React.Fragment>
                                        <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.all.designConcept.split('.')[0]}</span>
                                        <span style={{fontSize: 16}}>.{this.state.all.designConcept.split('.')[1]}</span>
                                    </React.Fragment>
                                    // <React.Fragment>{this.state.all.designConcept}</React.Fragment>
                                )}
                            />
                            <p>功能级配</p>
                        </div>
                        <div className='row'>
                            <Progress width='110px' type="circle" percent={this.state.all.layout*1/10*100} trailColor='rgba(250,220,117,.2)' strokeColor='#fadc75'
                                format={()=>(
                                    <React.Fragment>
                                        <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.all.layout.split('.')[0]}</span>
                                        <span style={{fontSize: 16}}>.{this.state.all.layout.split('.')[1]}</span>
                                    </React.Fragment>
                                    // <React.Fragment>{this.state.all.layout}</React.Fragment>
                                )}
                            />
                            <p>动静流线</p>
                        </div>
                        <div className='row'>
                            <Progress width='110px' type="circle" percent={this.state.all.practicalExpression*1/10*100} trailColor='rgba(52,129,122,.2)' strokeColor='#409c93'
                                format={()=>(
                                    <React.Fragment>
                                        <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.all.practicalExpression.split('.')[0]}</span>
                                        <span style={{fontSize: 16}}>.{this.state.all.practicalExpression.split('.')[1]}</span>
                                    </React.Fragment>
                                    // <React.Fragment>{this.state.all.practicalExpression}</React.Fragment>
                                )}
                            />
                            <p>节能环保</p>
                        </div>
                        <div className='row'>
                            <Progress width='110px' type="circle" percent={this.state.all.energySaving*1/10*100} trailColor='rgba(3,164,244,.2)' strokeColor='#03a4f4'
                                format={()=>(
                                    <React.Fragment>
                                        <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.all.energySaving.split('.')[0]}</span>
                                        <span style={{fontSize: 16}}>.{this.state.all.energySaving.split('.')[1]}</span>
                                    </React.Fragment>
                                    // <React.Fragment>{this.state.all.energySaving}</React.Fragment>
                                )}
                            />
                            <p>价值创新</p>
                        </div>
                        <div className='row'>
                            <Progress width='110px' type="circle" percent={this.state.all.score*1/10*100} trailColor='rgba(1,245,160,.2)' strokeColor='#00F5A0'
                                format={()=>(
                                    <React.Fragment>
                                        <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.all.score.split('.')[0]}</span>
                                        <span style={{fontSize: 16}}>.{this.state.all.score.split('.')[1]}</span>
                                    </React.Fragment>
                                    // <React.Fragment>{this.state.all.score}</React.Fragment>
                                )}
                            />
                            <p>综合评分</p>
                        </div>
                    </div>
                </div>
                <p className='tit1'>我的评分</p>

                {
                    this.state.is_score==0?<p className='cypfbtn' onClick={()=>{
                        if(!localStorage.getItem('userId')){
                            return message.error('请先登录',1.2)
                        }else{
                            this.setState({
                                visible: true
                            })
                        }
                    }}>参与评分</p>:<div className='pwpfxqbox' style={{borderTop: 0}}>
                    <div className='rows'>
                        <div className='pbox1'>
                            <img className='avatar' alt='' src={this.state.my.headImg} />
                            <div className='puinfo'>
                                <p className='name'>{this.state.my.nickname}</p>
                                <span>{this.state.my.atime}</span>
                            </div>
                        </div>
                        <div className='pbox2'>
                            <div className='row'>
                                <Progress width='40px' type="circle" percent={this.state.my.designConcept*1/10*100} trailColor='#fff' strokeColor='#ff6440' format={()=>{
                                    return(
                                        // <React.Fragment>
                                        //     <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.my.designConcept.split('.')[0]}</span>
                                        //     <span style={{fontSize: 16}}>.{this.state.my.designConcept.split('.')[1]}</span>
                                        // </React.Fragment>
                                        <React.Fragment>{this.state.my.designConcept*1}</React.Fragment>
                                    )
                                }} />

                                <div className='tips'>

                                    <p>功能级配</p>
                                </div>
                            </div>
                            <div className='row'>
                                <Progress width='40px' type="circle" percent={this.state.my.layout*1/10*100} trailColor='#fff' strokeColor='#fadc75' format={()=>{
                                    return(
                                        // <React.Fragment>
                                        //     <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.my.layout.split('.')[0]}</span>
                                        //     <span style={{fontSize: 16}}>.{this.state.my.layout.split('.')[1]}</span>
                                        // </React.Fragment>
                                        <React.Fragment>{this.state.my.layout*1}</React.Fragment>
                                    )
                                }} />
                                <div className='tips'>
                                    <p>动静流线</p>
                                </div>
                            </div>
                            <div className='row'>
                                <Progress width='40px' type="circle" percent={this.state.my.practicalExpression*1/10*100} trailColor='#fff' strokeColor='#409c93' format={()=>{
                                    return(
                                        // <React.Fragment>
                                        //     <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.my.practicalExpression.split('.')[0]}</span>
                                        //     <span style={{fontSize: 16}}>.{this.state.my.practicalExpression.split('.')[1]}</span>
                                        // </React.Fragment>
                                        <React.Fragment>{this.state.my.practicalExpression*1}</React.Fragment>
                                    )
                                }} />
                                <div className='tips'>
                                    <p>节能环保</p>
                                </div>
                            </div>
                            <div className='row'>
                                <Progress width='40px' type="circle" percent={this.state.my.energySaving*1/10*100} trailColor='#fff' strokeColor='#03a4f4' format={()=>{
                                    return(
                                        // <React.Fragment>
                                        //     <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.my.energySaving.split('.')[0]}</span>
                                        //     <span style={{fontSize: 16}}>.{this.state.my.energySaving.split('.')[1]}</span>
                                        // </React.Fragment>
                                        <React.Fragment>{this.state.my.energySaving*1}</React.Fragment>
                                    )
                                }} />
                                <div className='tips'>
                                    <p>价值创新</p>
                                </div>
                            </div>
                        </div>
                        <p className='pbox3 zhscore'>{this.state.my.score}</p>
                        <p className='pbox4 ckbtn' onClick={()=>{
                            this.setState({
                                ckVisible: true,
                                label: this.state.my.label,
                                ckContent: this.state.my.content
                            })
                        }}>查看点评</p>
                    </div>
                </div>
                }

                
                <div className='abcdpf'>
                {/* ['A组评分','B组评分','C组评分','D组评分'] */}
                    {this.state.group.map((item,index)=>(
                            <p key={'index'+index} className={this.state.type==index?'active':''} onClick={()=>{
                                this.setState({
                                    type:index,
                                    page:1
                                },()=>{
                                    this.getData()
                                })
                            }}>{item}</p>
                        ))}

                </div>
                <div className='zpfbox fzpfbox'>
                    <div className='flex'>
                        <div className='row'>
                            <Progress width='110px' type="circle" percent={this.state.average.designConcept*1/10*100} trailColor='rgba(255,100,64,.2)' strokeColor='#ff6440'
                                format={()=>(
                                    <React.Fragment>
                                        <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.average.designConcept.split('.')[0]}</span>
                                        <span style={{fontSize: 16}}>.{this.state.average.designConcept.split('.')[1]}</span>
                                    </React.Fragment>
                                )}
                            />
                            <p>功能级配</p>
                        </div>
                        <div className='row'>
                            <Progress width='110px' type="circle" percent={this.state.average.layout*1/10*100} trailColor='rgba(250,220,117,.2)' strokeColor='#fadc75'
                                format={()=>(
                                    <React.Fragment>
                                        <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.average.layout.split('.')[0]}</span>
                                        <span style={{fontSize: 16}}>.{this.state.average.layout.split('.')[1]}</span>
                                    </React.Fragment>
                                    // <React.Fragment>{this.state.average.layout}</React.Fragment>
                                )}
                            />
                            <p>动静流线</p>
                        </div>
                        <div className='row'>
                            <Progress width='110px' type="circle" percent={this.state.average.practicalExpression*1/10*100} trailColor='rgba(52,129,122,.2)' strokeColor='#409c93'
                                format={()=>(
                                    <React.Fragment>
                                        <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.average.practicalExpression.split('.')[0]}</span>
                                        <span style={{fontSize: 16}}>.{this.state.average.practicalExpression.split('.')[1]}</span>
                                    </React.Fragment>
                                )}
                            />
                            <p>节能环保</p>
                        </div>
                        <div className='row'>
                            <Progress width='110px' type="circle" percent={this.state.average.energySaving*1/10*100} trailColor='rgba(3,164,244,.2)' strokeColor='#03a4f4'
                                format={()=>(
                                    <React.Fragment>
                                        <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.average.energySaving.split('.')[0]}</span>
                                        <span style={{fontSize: 16}}>.{this.state.average.energySaving.split('.')[1]}</span>
                                    </React.Fragment>
                                    // <React.Fragment>{this.state.average.practicalExpression}</React.Fragment>
                                )}
                            />
                            <p>价值创新</p>
                        </div>
                        <div className='row'>
                            <Progress width='110px' type="circle" percent={this.state.average.score*1/10*100} trailColor='rgba(1,245,160,.2)' strokeColor='#00F5A0'
                                format={()=>(
                                    <React.Fragment>
                                        <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.average.score.split('.')[0]}</span>
                                        <span style={{fontSize: 16}}>.{this.state.average.score.split('.')[1]}</span>
                                    </React.Fragment>
                                    // <React.Fragment>{this.state.average.score}</React.Fragment>
                                )}
                            />
                            <p>综合评分</p>
                        </div>
                    </div>
                </div>

                <div className='pwpfxqbox'>
                    <div className='rows titbox'>
                        {this.state.level==1?<p className='pbox1'>专家组</p>:""}
                        {this.state.level==2?<p className='pbox1'>资深组</p>:""}
                        {this.state.level==3?<p className='pbox1'>精英组</p>:""}
                        {this.state.level==4?<p className='pbox1'>用户组</p>:""}
                        <p className='pbox2'>评审分数</p>
                        <p className='pbox3'>综合得分</p>
                        <p className='pbox4'>点评</p>
                    </div>
                    {this.state.socre.map((item,index)=>(
                        <div className='rows' key={'h'+index}>
                            <div className='pbox1'>
                                <img className='avatar' alt='' src={item.headImg} />
                                <div className='puinfo'>
                                    <p className='name'>{item.nickname} </p>
                                    <span>{item.atime} </span>
                                </div>
                            </div>
                            <div className='pbox2'>
                                <div className='row'>
                                    <Progress width='40px' type="circle" percent={item.designConcept*1/10*100} trailColor='#fff' strokeColor='#ff6440'
                                        format={()=>(
                                            // <React.Fragment>
                                            //     <span style={{fontSize: 24,fontWeight: 'bold'}}>{item.designConcept.split('.')[0]}</span>
                                            //     <span style={{fontSize: 16}}>.{item.designConcept.split('.')[1]}</span>
                                            // </React.Fragment>
                                            <React.Fragment>{item.designConcept*1}</React.Fragment>
                                        )}
                                    />
                                    <div className='tips'>
                                        <p>功能级配</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <Progress width='40px' type="circle" percent={item.layout*1/10*100} trailColor='#fff' strokeColor='#fadc75'
                                        format={()=>(
                                            // <React.Fragment>
                                            //     <span style={{fontSize: 24,fontWeight: 'bold'}}>{item.layout.split('.')[0]}</span>
                                            //     <span style={{fontSize: 16}}>.{item.layout.split('.')[1]}</span>
                                            // </React.Fragment>
                                            <React.Fragment>{item.layout*1}</React.Fragment>
                                        )}
                                    />
                                    <div className='tips'>
                                        <p>动静流线</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <Progress width='40px' type="circle" percent={item.practicalExpression*1/10*100} trailColor='#fff' strokeColor='#409c93'
                                        format={()=>(
                                            // <React.Fragment>
                                            //     <span style={{fontSize: 24,fontWeight: 'bold'}}>{item.practicalExpression.split('.')[0]}</span>
                                            //     <span style={{fontSize: 16}}>.{item.practicalExpression.split('.')[1]}</span>
                                            // </React.Fragment>
                                            <React.Fragment>{item.practicalExpression*1}</React.Fragment>
                                        )}
                                    />
                                    <div className='tips'>
                                        <p>节能环保</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <Progress width='40px' type="circle" percent={item.energySaving*1/10*100} trailColor='#fff' strokeColor='#03a4f4'
                                        format={()=>(
                                            // <React.Fragment>
                                            //     <span style={{fontSize: 24,fontWeight: 'bold'}}>{item.energySaving.split('.')[0]}</span>
                                            //     <span style={{fontSize: 16}}>.{item.energySaving.split('.')[1]}</span>
                                            // </React.Fragment>
                                            <React.Fragment>{item.energySaving*1}</React.Fragment>
                                        )}
                                    />
                                    <div className='tips'>
                                        <p>价值创新</p>
                                    </div>
                                </div>
                            </div>
                            <p className='pbox3 zhscore'>{item.score}</p>
                            <p className='pbox4 ckbtn' onClick={()=>{
                                this.setState({
                                    ckVisible: true,
                                    label: item.label,
                                    ckContent: item.content,
                                })
                            }}>查看点评</p>
                        </div>
                    ))}
                </div>
                {/* 参与评分 */}
                <Modal 
                    title=''
                    visible={this.state.visible}
                    width={900}
                    footer={false}
                    closable={false}
                >
                    <div className='modal-title'>
                        <p>评分详情</p>
                        <img alt='' src={close} onClick={()=>{
                            this.setState({
                                visible: false
                            })
                        }} />
                    </div>
                    {this.state.show&&<div className={`pfxx pfxx${this.state.index+1}`}>
                        <div className='flex'>
                            <p className='tit'>{this.state.txtlist[this.state.index]}</p>
                            <p className='jdtxt'>{this.state.index+1}/4</p>
                        </div>
                        <div className='numlist'>
                            {this.state.numlist.map((row,k)=>(
                                <p className={`${row.selected?'active':''}`} key={'h'+k} onClick={this.checkNum.bind(this,k)}>{row.num}</p>
                            ))}
                        </div>
                    </div>}
                    {!this.state.show&&<div className='wzdpbox'>
                        <div className='zpfbox fzpfbox' style={{paddingTop: 18}}>
                            <div className='flex'>
                                <div className='row'>
                                    <Progress width='110px' type="circle" percent={this.state.score1/10*100} trailColor='rgba(255,100,64,.2)' strokeColor='#ff6440'
                                        format={()=>(
                                            <React.Fragment>
                                                <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.score1.split('.')[0]}</span>
                                                <span style={{fontSize: 16}}>.{this.state.score1.split('.')[1]}</span>
                                            </React.Fragment>
                                            // <React.Fragment>{this.state.score1}</React.Fragment>
                                        )}
                                    />
                                    <p>功能级配</p>
                                </div>
                                <div className='row'>
                                    <Progress width='110px' type="circle" percent={this.state.score2/10*100} trailColor='rgba(250,220,117,.2)' strokeColor='#fadc75'
                                        format={()=>(
                                            <React.Fragment>
                                                <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.score2.split('.')[0]}</span>
                                                <span style={{fontSize: 16}}>.{this.state.score2.split('.')[1]}</span>
                                            </React.Fragment>
                                            // <React.Fragment>{this.state.score2}</React.Fragment>
                                        )}
                                    />
                                    <p>动静流线</p>
                                </div>
                                <div className='row'>
                                    <Progress width='110px' type="circle" percent={this.state.score3/10*100} trailColor='rgba(52,129,122,.2)' strokeColor='#409c93'
                                        format={()=>(
                                            <React.Fragment>
                                                <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.score3.split('.')[0]}</span>
                                                <span style={{fontSize: 16}}>.{this.state.score3.split('.')[1]}</span>
                                            </React.Fragment>
                                            // <React.Fragment>{this.state.score3}</React.Fragment>
                                        )}
                                    />
                                    <p>节能环保</p>
                                </div>
                                <div className='row'>
                                    <Progress width='110px' type="circle" percent={this.state.score4/10*100} trailColor='rgba(3,164,244,.2)' strokeColor='#03a4f4'
                                        format={()=>(
                                            <React.Fragment>
                                                <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.score4.split('.')[0]}</span>
                                                <span style={{fontSize: 16}}>.{this.state.score4.split('.')[1]}</span>
                                            </React.Fragment>
                                            // <React.Fragment>{this.state.score4}</React.Fragment>
                                        )}
                                    />
                                    <p>价值创新</p>
                                </div>
                                <div className='row'>
                                    <Progress width='110px' type="circle" percent={this.state.allnum/10*100} trailColor='rgba(1,245,160,.2)' strokeColor='#00F5A0'
                                        format={()=>(
                                            <React.Fragment>
                                                <span style={{fontSize: 24,fontWeight: 'bold'}}>{this.state.allnum.split('.')[0]}</span>
                                                <span style={{fontSize: 16}}>.{this.state.allnum.split('.')[1]}</span>
                                            </React.Fragment>
                                            // <React.Fragment>{this.state.allnum}</React.Fragment>
                                        )}
                                    />
                                    <p>综合评分</p>
                                </div>
                            </div>
                        </div>
                        <p className='resetbtn' onClick={()=>{
                            this.setState({
                                index: -1,
                                show: true,
                                score1: 0,
                                score2: 0,
                                score3: 0,
                                score4: 0
                            },()=>{
                                this.reset()
                            })
                        }}>更改评分</p>
                        <h1>关键词选择</h1>
                        <div className='bqlist'>
                            {
                                this.state.keyword.map((item,index)=>{
                                    if(item.status==0){
                                        return (
                                            <p key={'keyword'+index} onClick={
                                                this.clickKeyWord.bind(this,index,item.name)
                                            }>{item.name}</p>
                                        )
                                    }else{
                                        return (
                                            <p key={'keyword'+index} className='active' onClick={
                                                this.clickKeyWord.bind(this,index,"")
                                            }>{item.name}</p>
                                        ) 
                                    }
                               
                                })
                            }

                        </div>
                        <h1>文字点评</h1>
                        <textarea placeholder='发表你的看法~' className='texta' 
                        onChange={(e)=>{
                                this.setState({
                                        content:e.target.value
                                })            
                        }}
                        ></textarea>
                        <p className='fbtn' onClick={this.wantScore.bind(this)}>发表</p>
                    </div>}
                </Modal>
                {/* 查看点评 */}
                <Modal
                    visible={this.state.ckVisible}
                    title=''
                    width={600}
                    footer={false}
                    closable={false}
                    className='ckMask'
                >
                    <div className='modal-title'>
                        <p>点评详情</p>
                    </div>
                    <div className='numlist'>
                        {this.state.label.map((row,k)=>(
                            <p className='' key={'h'+k}>{row}</p>
                        ))}
                    </div>
                    {/* <p className='pp11'>
                        {this.state.ckContent}
                    </p> */}
                    <p className='pp11' dangerouslySetInnerHTML={{__html:this.state.ckContent}}></p>
                    <p className='fbtn' onClick={()=>{
                        this.setState({
                            ckVisible: false
                        })
                    }}>关闭</p>
                </Modal>
            </React.Fragment>
        )
    }
}